import {
  entry_csssa_death_penalty_values,
  entry_csssa_legal_values,
  entry_csssa_max_prison_values,
  entry_csssa_other_punishment_values,
  entry_csssa_penalties,
  entry_ct_banned_types,
  entry_ct_values,
  entry_foa_barrier_values,
  entry_foe_barrier_education_values,
  entry_foe_barrier_general_values,
  entry_foe_barrier_media_values,
  entry_foe_barrier_summary_values,
  entry_protection_types,
  entry_ssu_marriage_types,
  entry_ssu_summary_types,
  legal_framework_decrim_mechanisms,
  entry_ado_adoption_types,
  entry_ado_map_types,
  entry_pnc_types,
  entry_lgr_established_procedures,
  entry_lgr_gm_avail_values,
  entry_lgr_gm_values,
  entry_lgr_selfid_values,
  entry_lgr_types,
  entry_ssu_civil_types,
} from "./area1Values";

export const initialFormState = (area) => {
  switch (area) {
    case "A1-1":
      return {
        legal: "",
        last_amendment_1: "",
        last_amendment_2: "",
        decrim_date_1: "",
        decrim_date_2: "",
        explan_en: "",
        explan_es: "",
        entry_csssa_penalty_id: "",
        legal_framework_decrim_mechanism_id: "",
        entry_csssa_death_penalty_value_id: "",
        dp_en: "",
        dp_es: "",
        entry_csssa_max_prison_value_id: "",
        max_prison_years: "",
        prison_en: "",
        prison_es: "",
        has_fine: false,
        fine_en: "",
        fine_es: "",
        entry_csssa_other_punishment_value_id: "",
        other_en: "",
        other_es: "",
        enforcement_en: "",
        enforcement_es: "",
      };
    case "A1-3":
      return {
        entry_foe_barrier_summary_value_id: "",
        entry_foe_barrier_general_value_id: "",
        general_critical_date_1: "",
        general_critical_date_2: "",
        general_mechanism_id: "",
        general_explan_en: "",
        general_explan_es: "",
        entry_foe_barrier_education_value_id: "",
        education_critical_date_1: "",
        education_critical_date_2: "",
        education_mechanism_id: "",
        education_explan_en: "",
        education_explan_es: "",
        entry_foe_barrier_media_value_id: "",
        media_critical_date_1: "",
        media_critical_date_2: "",
        media_mechanism_id: "",
        media_explan_en: "",
        media_explan_es: "",
      };
    case "A1-4":
      return {
        barrier_value_id: "",
        critical_date_1: "",
        critical_date_2: "",
        mechanism_id: "",
        explan_en: "",
        explan_es: "",
      };
    case "A1-5":
    case "A1-6":
    case "A1-7":
    case "A1-8":
    case "A1-9":
    case "A1-10":
    case "A1-11":
    case "A1-12":
    case "A1-13":
      return {
        so_protection_type_id: "",
        so_critical_date_1: "",
        so_critical_date_2: "",
        so_legal_framework_decrim_mechanism_id: "",
        so_explan_en: "",
        so_explan_es: "",
        gi_protection_type_id: "",
        gi_critical_date_1: "",
        gi_critical_date_2: "",
        gi_legal_framework_decrim_mechanism_id: "",
        gi_explan_en: "",
        gi_explan_es: "",
        ge_protection_type_id: "",
        ge_critical_date_1: "",
        ge_critical_date_2: "",
        ge_legal_framework_decrim_mechanism_id: "",
        ge_explan_en: "",
        ge_explan_es: "",
        sc_protection_type_id: "",
        sc_critical_date_1: "",
        sc_critical_date_2: "",
        sc_legal_framework_decrim_mechanism_id: "",
        sc_explan_en: "",
        sc_explan_es: "",
      };
    case "A1-14":
      return {
        general_ban_type_id: "",
        mechanism_id: "",
        critical_date_1: "",
        critical_date_2: "",
        explan_en: "",
        explan_es: "",
        banned_practice_en: "",
        banned_practice_es: "",
        banned_practitioners_en: "",
        banned_practitioners_es: "",
        ads_ban_type_id: "",
        explan_ads_en: "",
        explan_ads_es: "",
        ref_ban_type_id: "",
        explan_ref_en: "",
        explan_ref_es: "",
        so_value_id: "",
        gi_value_id: "",
        minors_value_id: "",
        vulnerable_adults_value_id: "",
        all_adults_value_id: "",
      };
    case "A1-15":
      return {
        summary_type_id: "",
        marriage_type_id: "",
        marriage_critical_date_1: "",
        marriage_critical_date_2: "",
        marriage_mechanism_id: "",
        marriage_explan_en: "",
        marriage_explan_es: "",
        civil_type_id: "",
        civil_critical_date_1: "",
        civil_critical_date_2: "",
        civil_repeal_date_1: "",
        civil_repeal_date_2: "",
        civil_mechanism_id: "",
        civil_explan_en: "",
        civil_explan_es: "",
      };
    case "A1-16":
      return {
        map_type_id: "",
        joint_adoption_type_id: "",
        joint_critical_date_1: "",
        joint_critical_date_2: "",
        joint_mechanism_id: "",
        joint_explan_en: "",
        joint_explan_es: "",
        second_parent_adoption_type_id: "",
        second_parent_critical_date_1: "",
        second_parent_critical_date_2: "",
        second_parent_mechanism_id: "",
        second_parent_explan_en: "",
        second_parent_explan_es: "",
      };
    case "A1-17":
      return {
        pnc_type_id: "",
        critical_date_1: "",
        critical_date_2: "",
        mechanism_id: "",
        explan_en: "",
        explan_es: "",
      };
    case "A1-18":
      return {
        name_change_lgr_type_id: "",
        name_change_critical_date_1: "",
        name_change_critical_date_2: "",
        name_change_mechanism_id: "",
        name_change_explan_en: "",
        name_change_explan_es: "",
        gender_marker_lgr_type_id: "",
        gender_marker_critical_date_1: "",
        established_procedure_id: "",
        gender_marker_critical_date_2: "",
        gender_marker_mechanism_id: "",
        gender_marker_explan_en: "",
        gender_marker_explan_es: "",
        gender_marker_enactment_date: "",
        gender_marker_amended_documents_en: "",
        gender_marker_amended_documents_es: "",
        gm_selfid_value_id: "",
        gm_selfid_since: "",
        gm_selfid_explan_en: "",
        gm_selfid_explan_es: "",
        gm_diagnosis_gm_value_id: "",
        gm_diagnosis_explan_en: "",
        gm_diagnosis_explan_es: "",
        gm_hormone_treatment_gm_value_id: "",
        gm_hormone_treatment_explan_en: "",
        gm_hormone_treatment_explan_es: "",
        gm_surgery_gm_value_id: "",
        gm_surgery_explan_en: "",
        gm_surgery_explan_es: "",
        gm_forced_sterilisation_gm_value_id: "",
        gm_forced_sterilisation_explan_en: "",
        gm_forced_sterilisation_explan_es: "",
        gm_real_life_test_gm_value_id: "",
        gm_real_life_test_explan_en: "",
        gm_real_life_test_explan_es: "",
        gm_expert_testimony_gm_value_id: "",
        gm_expert_testimony_explan_en: "",
        gm_expert_testimony_explan_es: "",
        gm_witnesses_gm_value_id: "",
        gm_witnesses_explan_en: "",
        gm_witnesses_explan_es: "",
        gm_forced_divorce_gm_value_id: "",
        gm_forced_divorce_explan_en: "",
        gm_forced_divorce_explan_es: "",
        gm_no_children_gm_value_id: "",
        gm_no_children_explan_en: "",
        gm_no_children_explan_es: "",
        gm_for_children_gm_avail_value_id: "",
        gm_for_children_explan_en: "",
        gm_for_children_explan_es: "",
        gm_non_binary_gm_avail_value_id: "",
        gm_non_binary_explan_en: "",
        gm_non_binary_explan_es: "",
      };

    default:
      return {};
  }
};

const genericFormDataLoader = (formData, dataEntry, area) => {
  let updatedData = { ...formData };
  Object.keys(initialFormState(area)).forEach((key) => {
    if (dataEntry[key]) updatedData[key] = dataEntry[key];
    else updatedData[key] = null;
  });
  return updatedData;
};

export const formDataLoader = {
  "A1-1": (formData, dataEntry) => {
    return {
      ...formData,
      //MISSING JURISDICTION
      entry_csssa_death_penalty_value_id: dataEntry.entry_csssa_death_penalty_value_id,
      entry_csssa_max_prison_value_id: dataEntry.entry_csssa_max_prison_value_id,
      entry_csssa_other_punishment_value_id: dataEntry.entry_csssa_other_punishment_value_id,
      entry_csssa_penalty_id: dataEntry.entry_csssa_penalty_id,
      legal_framework_decrim_mechanism_id: dataEntry.legal_framework_decrim_mechanism_id,
      legal: dataEntry.legal ? 1 : 0,
      max_prison_years: dataEntry ? dataEntry.max_prison_years : "",
      has_fine: dataEntry ? dataEntry.has_fine : "",
      decrim_date_1: dataEntry ? dataEntry.decrim_date_1 : null,
      decrim_date_2: dataEntry ? dataEntry.decrim_date_2 : null,
      last_amendment_1: dataEntry ? dataEntry.last_amendment_1 : null,
      last_amendment_2: dataEntry ? dataEntry.last_amendment_2 : null,
      explan_en: dataEntry ? dataEntry.explan_en : "",
      explan_es: dataEntry ? dataEntry.explan_es : "",
      fine_en: dataEntry ? dataEntry.fine_en : "",
      fine_es: dataEntry ? dataEntry.fine_es : "",
      dp_en: dataEntry ? dataEntry.dp_en : "",
      dp_es: dataEntry ? dataEntry.dp_es : "",
      prison_en: dataEntry ? dataEntry.prison_en : "",
      prison_es: dataEntry ? dataEntry.prison_es : "",
      other_en: dataEntry ? dataEntry.other_en : "",
      other_es: dataEntry ? dataEntry.other_es : "",
      enforcement_en: dataEntry ? dataEntry.enforcement_en : "",
      enforcement_es: dataEntry ? dataEntry.enforcement_es : "",
    };
  },
  "A1-3": (formData, dataEntry) => {
    return {
      ...formData,
      //MISSING JURISDICTION
      entry_foe_barrier_summary_value_id: dataEntry
        ? dataEntry.entry_foe_barrier_summary_value_id
        : null,
      entry_foe_barrier_general_value_id: dataEntry
        ? dataEntry.entry_foe_barrier_general_value_id
        : null,
      general_critical_date_1: dataEntry
        ? dataEntry.general_critical_date_1
        : null,
      general_critical_date_2: dataEntry
        ? dataEntry.general_critical_date_2
        : null,
      general_mechanism_id: dataEntry ? dataEntry.general_mechanism_id : null,
      general_explan_en: dataEntry ? dataEntry.general_explan_en : "",
      general_explan_es: dataEntry ? dataEntry.general_explan_es : "",
      entry_foe_barrier_education_value_id: dataEntry
        ? dataEntry.entry_foe_barrier_education_value_id
        : null,
      education_critical_date_1: dataEntry
        ? dataEntry.education_critical_date_1
        : null,
      education_critical_date_2: dataEntry
        ? dataEntry.education_critical_date_2
        : null,
      education_mechanism_id: dataEntry
        ? dataEntry.education_mechanism_id
        : null,
      education_explan_en: dataEntry ? dataEntry.education_explan_en : "",
      education_explan_es: dataEntry ? dataEntry.education_explan_es : "",
      entry_foe_barrier_media_value_id: dataEntry
        ? dataEntry.entry_foe_barrier_media_value_id
        : null,
      media_critical_date_1: dataEntry ? dataEntry.media_critical_date_1 : null,
      media_critical_date_2: dataEntry ? dataEntry.media_critical_date_2 : null,
      media_mechanism_id: dataEntry ? dataEntry.media_mechanism_id : null,
      media_explan_en: dataEntry ? dataEntry.media_explan_en : "",
      media_explan_es: dataEntry ? dataEntry.media_explan_es : "",
    };
  },
  "A1-4": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-4"),
  "A1-5": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-5"),
  "A1-6": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-6"),
  "A1-7": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-7"),
  "A1-8": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-8"),
  "A1-9": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-9"),
  "A1-10": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-10"),
  "A1-11": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-11"),
  "A1-12": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-12"),
  "A1-13": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-13"),
  "A1-14": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-14"),
  "A1-15": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-15"),
  "A1-16": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-16"),
  "A1-17": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-17"),
  "A1-18": (formData, dataEntry) =>
    genericFormDataLoader(formData, dataEntry, "A1-18"),
};

export const formConfig = (entryTypeId) => {
  switch (entryTypeId) {
    case "A1-1":
      return {
        tabs: [
          {
            label: "General Information",
            fields: [
              {
                name: "legal",
                label: "Legal",
                mandatory: true,
                type: "select",
                options: entry_csssa_legal_values,
              },
              {
                name: "last_amendment_1",
                label: "Last Amendment",
                type: "year",
              },
              {
                name: "last_amendment_2",
                label: "Illegal Since",
                type: "year",
              },
              {
                name: "decrim_date_1",
                label: "Decrim (unique/date in)",
                type: "year",
              },
              {
                name: "decrim_date_2",
                label: "Decrim (date out)",
                type: "year",
              },
              {
                name: "entry_csssa_penalty_id",
                label: "Penalty",
                type: "select",
                mandatory: true,
                options: entry_csssa_penalties,
              },
              {
                name: "legal_framework_decrim_mechanism_id",
                label: "Decrim Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "entry_csssa_death_penalty_value_id",
                label: "Death Penalty",
                mandatory: true,
                type: "select",
                options: entry_csssa_death_penalty_values,
              },
              {
                name: "entry_csssa_max_prison_value_id",
                label: "Max Prison Time",
                mandatory: true,
                type: "select",
                options: entry_csssa_max_prison_values,
              },
              {
                name: "max_prison_years",
                label: "Max Prison Years",
                type: "number",
                mandatory: true,
                conditional: (formData) => {
                  console.log("formData: ", formData);
                  return formData.maxPrisonValue === 6;
                },
              },
              {
                name: "entry_csssa_other_punishment_value_id",
                label: "Other Punishment",
                mandatory: true,
                type: "select",
                options: entry_csssa_other_punishment_values,
              },
              {
                name: "has_fine",
                label: "Has Fine",
                type: "checkbox",
              },
            ],
          },

          {
            label: "Death Penalty Explan",
            fields: [
              {
                name: "dp_en",
                label: "English version",
                type: "richtext",
              },
              {
                name: "dp_es",
                label: "Spanish version",
                type: "richtext",
              },
            ],
          },
          {
            label: "Prison Sentence Explan",
            fields: [
              {
                name: "prison_en",
                label: "English version",
                type: "richtext",
              },
              {
                name: "prison_es",
                label: "Spanish version",
                type: "richtext",
              },
            ],
          },
          {
            label: "Fine Explan",
            fields: [
              {
                name: "fine_en",
                label: "English version",
                type: "richtext",
              },
              {
                name: "fine_es",
                label: "Spanish version",
                type: "richtext",
              },
            ],
          },
          {
            label: "Other Punishment Explan",
            fields: [
              {
                name: "other_en",
                label: "English version",
                type: "richtext",
              },
              {
                name: "other_es",
                label: "Spanish version",
                type: "richtext",
              },
            ],
          },
          {
            label: "General Explan",
            id: "explan",
            fields: [
              {
                name: "explan",
                type: "sourcetext",
              },
            ],
          },
        ],
      };
    case "A1-3":
      return {
        tabs: [
          {
            label: "General",
            id: "general_explan",
            fields: [
              {
                name: "entry_foe_barrier_summary_value_id",
                label: "Summary",
                mandatory: true,
                type: "select",
                options: entry_foe_barrier_summary_values,
              },
              {
                name: "entry_foe_barrier_general_value_id",
                label: "General Barrier",
                mandatory: true,
                type: "select",
                options: entry_foe_barrier_general_values,
              },
              {
                name: "general_critical_date_1",
                label: "General Critical Date 1",
                type: "year",
              },
              {
                name: "general_critical_date_2",
                label: "General Critical Date 2",
                type: "year",
              },
              {
                name: "general_mechanism_id",
                label: "General Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "General Explan",
                name: "general_explan",
                type: "sourcetext",
                section: 1,
              },
            ],
          },
          {
            label: "Education",
            id: "education_explan",
            fields: [
              {
                name: "entry_foe_barrier_education_value_id",
                label: "Education Barrier",

                type: "select",
                options: entry_foe_barrier_education_values,
              },
              {
                name: "education_critical_date_1",
                label: "Education Critical Date 1",
                type: "year",
              },
              {
                name: "education_critical_date_2",
                label: "Education Critical Date 2",
                type: "year",
              },
              {
                name: "education_mechanism_id",
                label: "Education Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "education_explan",
                type: "sourcetext",
                section: 2,
              },
            ],
          },

          {
            label: "Media",
            id: "media_explan",
            fields: [
              {
                name: "entry_foe_barrier_media_value_id",
                label: "Media Barrier",

                type: "select",
                options: entry_foe_barrier_media_values,
              },
              {
                name: "media_critical_date_1",
                label: "Media Critical Date 1",
                type: "year",
              },
              {
                name: "media_critical_date_2",
                label: "Media Critical Date 2",
                type: "year",
              },
              {
                name: "media_mechanism_id",
                label: "Media Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "media_explan",
                type: "sourcetext",
                section: 3,
              },
            ],
          },
        ],
      };
    case "A1-4":
      return {
        tabs: [
          {
            label: "General",
            id: "general_explan",
            fields: [
              {
                name: "barrier_value_id",
                label: "Barrier",
                mandatory: true,
                type: "select",
                options: entry_foa_barrier_values,
              },
              {
                name: "critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "explan",
                type: "sourcetext",
              },
            ],
          },
        ],
      };
    case "A1-5":
    case "A1-6":
    case "A1-7":
    case "A1-8":
    case "A1-9":
    case "A1-10":
    case "A1-11":
    case "A1-12":
    case "A1-13":
      return {
        tabs: [
          {
            label: "SO",
            id: "so_explan",
            fields: [
              {
                name: "so_protection_type_id",
                label: "Protection",
                mandatory: true,
                type: "select",
                options: entry_protection_types,
              },
              {
                name: "so_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "so_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "so_legal_framework_decrim_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "so_explan",
                type: "sourcetext",
                section: 1,
              },
            ],
          },
          {
            label: "GI",
            id: "gi_explan",
            fields: [
              {
                name: "gi_protection_type_id",
                label: "Protection",
                mandatory: true,
                type: "select",
                options: entry_protection_types,
              },
              {
                name: "gi_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "gi_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "gi_legal_framework_decrim_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "gi_explan",
                type: "sourcetext",
                section: 2,
              },
            ],
          },
          {
            label: "GE",
            id: "ge_explan",
            fields: [
              {
                name: "ge_protection_type_id",
                label: "Protection",
                mandatory: true,
                type: "select",
                options: entry_protection_types,
              },
              {
                name: "ge_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "ge_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "ge_legal_framework_decrim_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "ge_explan",
                type: "sourcetext",
                section: 3,
              },
            ],
          },
          {
            label: "SC",
            id: "sc_explan",
            fields: [
              {
                name: "sc_protection_type_id",
                label: "Protection",
                mandatory: true,
                type: "select",
                options: entry_protection_types,
              },
              {
                name: "sc_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "sc_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "sc_legal_framework_decrim_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "sc_explan",
                type: "sourcetext",
                section: 4,
              },
            ],
          },
        ],
      };
    case "A1-14":
      return {
        tabs: [
          {
            label: "General",
            id: "general_explan",
            fields: [
              {
                name: "general_ban_type_id",
                label: "Conversion Therapy Ban",
                mandatory: true,
                type: "select",
                options: entry_ct_values,
              },

              {
                name: "general_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "critical_date_1",
                label: "General Critical Date 1",
                type: "year",
              },
              {
                name: "critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
            ],
          },
          {
            label: "Explan",
            id: "explan",
            fields: [
              {
                label: "Explan",
                name: "explan",
                type: "sourcetext",
              },
            ],
          },
          {
            label: "Bans",
            id: "banned_practice",
            fields: [
              {
                name: "banned_practice_en",
                label: "Banned Practice (EN)",
                type: "richtext",
              },
              {
                name: "banned_practice_es",
                label: "Banned Practice (ES)",
                type: "richtext",
              },
              {
                name: "banned_practitioners_en",
                label: "Banned Practicioners (EN)",
                type: "richtext",
              },
              {
                name: "banned_practitioners_es",
                label: "Banned Practicioners (ES)",
                type: "richtext",
              },
            ],
          },
          {
            label: "Ads",
            id: "ads",
            fields: [
              {
                name: "explan_ads_en",
                label: "Ads Ban (EN)",
                type: "richtext",
              },
              {
                name: "explan_ads_es",
                label: "Ads Ban (ES)",
                type: "richtext",
              },
              {
                name: "ads_ban_type_id",
                label: "Ads Ban",
                type: "select",
                options: entry_ct_banned_types,
              },
            ],
          },
          {
            label: "Ref",
            id: "ref",
            fields: [
              {
                name: "explan_ref_en",
                label: "Ref Ban (EN)",
                type: "richtext",
              },
              {
                name: "explan_ref_es",
                label: "Ref Ban (ES)",
                type: "richtext",
              },
              {
                name: "ref_ban_type_id",
                label: "Ref Ban",

                type: "select",
                options: entry_ct_banned_types,
              },
            ],
          },
          {
            label: "Values",
            id: "values",
            fields: [
              {
                name: "so_value_id",
                label: "SO",
                mandatory: false, // changed from true to false
                type: "select",
                options: entry_ct_values,
              },
              {
                name: "gi_value_id",
                label: "GI",
                mandatory: false, // changed from true to false
                type: "select",
                options: entry_ct_values,
              },
              {
                name: "minors_value_id",
                label: "Minors",
                mandatory: false, // changed from true to false
                type: "select",
                options: entry_ct_values,
              },
              {
                name: "vulnerable_adults_value_id",
                label: "Vulnerable Adults",
                mandatory: false, // changed from true to false
                type: "select",
                options: entry_ct_values,
              },
              {
                name: "all_adults_value_id",
                label: "All Adults",
                mandatory: false, // changed from true to false
                type: "select",
                options: entry_ct_values,
              },
            ],
          },
        ],
      };
    case "A1-15":
      return {
        tabs: [
          {
            label: "Summary",
            id: "general_explan",
            fields: [
              {
                name: "summary_type_id",
                label: "Summary",
                mandatory: true,
                type: "select",
                options: entry_ssu_summary_types,
              },
            ],
          },
          {
            label: "Marriage",
            id: "marriage_explan",
            fields: [
              {
                name: "marriage_type_id",
                label: "Marriage",
                mandatory: true,
                type: "select",
                options: entry_ssu_marriage_types,
              },
              {
                name: "marriage_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "marriage_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "marriage_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "marriage_explan",
                type: "sourcetext",
                section: 1,
              },
            ],
          },
          {
            label: "Civil",
            id: "civil_explan",
            fields: [
              {
                name: "civil_type_id",
                label: "Civil",
                mandatory: true,
                type: "select",
                options: entry_ssu_civil_types,
              },
              {
                name: "civil_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "civil_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "civil_repeal_date_1",
                label: "Repeal Date 1",
                type: "year",
              },
              {
                name: "civil_repeal_date_2",
                label: "Repeal Date 2",
                type: "year",
              },
              {
                name: "civil_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "civil_explan",
                type: "sourcetext",
                section: 2,
              },
            ],
          },
        ],
      };
    case "A1-16":
      return {
        tabs: [
          {
            label: "Summary",
            id: "summary",
            fields: [
              {
                name: "map_type_id",
                label: "Map",
                mandatory: true,
                type: "select",
                options: entry_ado_map_types,
              },
            ],
          },
          {
            label: "Joint Adoption",
            id: "joint_explan",
            fields: [
              {
                name: "joint_adoption_type_id",
                label: "Joint Adoption",
                mandatory: true,
                type: "select",
                options: entry_ado_adoption_types,
              },
              {
                name: "joint_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "joint_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "joint_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "joint_explan",
                type: "sourcetext",
                section: 1,
              },
            ],
          },
          {
            label: "Second Parent Adoption",
            id: "second_parent_explan",
            fields: [
              {
                name: "second_parent_adoption_type_id",
                label: "Second Parent Adoption",
                mandatory: true,
                type: "select",
                options: entry_ado_adoption_types,
              },
              {
                name: "second_parent_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "second_parent_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "second_parent_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "second_parent_explan",
                type: "sourcetext",
                section: 2,
              },
            ],
          },
        ],
      };
    case "A1-17":
      return {
        tabs: [
          {
            label: "General",
            id: "general",
            fields: [
              {
                name: "pnc_type_id",
                label: "PNC",
                mandatory: true,
                type: "select",
                options: entry_pnc_types,
              },
              {
                name: "critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                label: "Explan",
                name: "explan",
                type: "sourcetext",
              },
            ],
          },
        ],
      };
    case "A1-18":
      return {
        tabs: [
          {
            label: "Name Change",
            name: "name_change_explan",
            fields: [
              // Add the fields for the "Name Change" tab here
              {
                name: "name_change_lgr_type_id",
                label: "LGR Type",
                mandatory: true,
                type: "select",
                options: entry_lgr_types,
              },
              {
                name: "name_change_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "name_change_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "name_change_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "name_change_explan",
                label: "Explan",
                type: "sourcetext",
                section: 1,
              },
            ],
          },
          {
            label: "Gender Marker",
            name: "gender_marker_explan",
            fields: [
              // Add the fields for the "Gender Marker" tab here
              {
                name: "gender_marker_lgr_type_id",
                label: "Gender Marker",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "gender_marker_critical_date_1",
                label: "Critical Date 1",
                type: "year",
              },
              {
                name: "gender_marker_critical_date_2",
                label: "Critical Date 2",
                type: "year",
              },
              {
                name: "established_procedure_id",
                label: "Established Procedure",
                mandatory: true,
                type: "select",
                options: entry_lgr_established_procedures,
              },
              {
                name: "gender_marker_mechanism_id",
                label: "Mechanism",
                type: "select",
                options: legal_framework_decrim_mechanisms,
              },
              {
                name: "gender_marker_explan",
                label: "Explan",
                type: "sourcetext",
                section: 2,
              },

              {
                name: "gender_marker_amended_documents_en",
                label: "GM Amended Documents (EN)",
                type: "richtext",
              },
              {
                name: "gender_marker_amended_documents_es",
                label: "GM Amended Documents (ES)",
                type: "richtext",
              },
              {
                name: "gender_marker_enactment_date",
                label: "Enactment Date",
                type: "year",
              },
            ],
          },
          {
            label: "GM Values",
            name: "gmValues",
            fields: [
              // Add the fields for the "GM Values" tab here
              {
                name: "gm_selfid_value_id",
                label: "Self ID",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "gm_selfid_since",
                label: "Self ID Since",
                type: "year",
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 6,
              },

              {
                name: "gm_selfid_explan_en",
                label: "Self ID Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_selfid_explan_es",
                label: "Self ID Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_hormone_treatment_gm_value_id",
                label: "Hormone Treatment",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "gm_hormone_treatment_since",
                label: "Hormone Treatment Since",
                type: "year",
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 6,
              },
              {
                name: "gm_hormone_treatment_explan_en",
                label: "Hormone Treatment Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_hormone_treatment_explan_es",
                label: "Hormone Treatment Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_surgery_gm_value_id",
                label: "Surgery",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },

              {
                name: "gm_surgery_since",
                label: "Surgery Since",
                type: "year",
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 6,
              },
              {
                name: "gm_surgery_explan_en",
                label: "Surgery Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_surgery_explan_es",
                label: "Surgery Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_forced_sterilisation_gm_value_id",
                label: "Forced Sterilisation",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "gm_forced_sterilisation_since",
                label: "Forced Sterilisation Since",
                type: "year",
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 6,
              },
              {
                name: "gm_forced_sterilisation_explan_en",
                label: "Forced Sterilisation Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_forced_sterilisation_explan_es",
                label: "Forced Sterilisation Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_real_life_test_gm_value_id",
                label: "Real Life Test",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_expert_testimony_explan_en",
                label: "Expert Testimony Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_expert_testimony_explan_es",
                label: "Expert Testimony Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_witnesses_gm_value_id",
                label: "Witnesses",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_witnesses_explan_en",
                label: "Witnesses Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_witnesses_explan_es",
                label: "Witnesses Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_forced_divorce_gm_value_id",
                label: "Forced Divorce",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_forced_divorce_explan_en",
                label: "Forced Divorce Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_forced_divorce_explan_es",
                label: "Forced Divorce Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_no_children_gm_value_id",
                label: "No Children",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_no_children_explan_en",
                label: "No Children Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_no_children_explan_es",
                label: "No Children Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_for_children_gm_avail_value_id",
                label: "For Children",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_for_children_explan_en",
                label: "For Children Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_for_children_explan_es",
                label: "For Children Explan (ES)",
                type: "richtext",
              },
              {
                name: "gm_non_binary_gm_avail_value_id",
                label: "Non-Binary",
                mandatory: true,
                type: "select",
                options: entry_lgr_gm_values,
              },
              {
                name: "filler1",
                label: "filler1",
                type: "filler",
                size: 9,
              },
              {
                name: "gm_non_binary_explan_en",
                label: "Non-Binary Explan (EN)",
                type: "richtext",
              },
              {
                name: "gm_non_binary_explan_es",
                label: "Non-Binary Explan (ES)",
                type: "richtext",
              },
            ],
          },
        ],
      };

    default:
      return {};
  }
};

export const checkErrors = (area) => {
  switch (area) {
    case "A1-1":
      return (formData) => {
        let errors = [];
        if (
          formData.entry_csssa_penalty_id === undefined ||
          formData.entry_csssa_penalty_id === "" ||
          formData.entry_csssa_penalty_id === null
        ) {
          errors.push("Penalty");
        }

        if (
          formData.legal_framework_decrim_mechanism_id === undefined ||
          formData.legal_framework_decrim_mechanism_id === "" ||
          formData.legal_framework_decrim_mechanism_id === null
        ) {
          errors.push("Decrim Mechanism");
        }
        if (
          formData.entry_csssa_death_penalty_value_id === undefined ||
          formData.entry_csssa_death_penalty_value_id === "" ||
          formData.entry_csssa_death_penalty_value_id === null
        ) {
          errors.push("Death Penalty");
        }
        if (
          formData.entry_csssa_max_prison_value_id === undefined ||
          formData.entry_csssa_max_prison_value_id === "" ||
          formData.entry_csssa_max_prison_value_id === null
        ) {
          errors.push("Maximum prison value");
        } else if (
          formData.entry_csssa_max_prison_value_id.value === 6 &&
          (formData.max_prison_years === undefined ||
            formData.max_prison_years === "" ||
            formData.max_prison_years === null ||
            formData.max_prison_years < 1)
        ) {
          errors.push("Prison years must be more than 0");
        }
        if (
          formData.entry_csssa_other_punishment_value_id === undefined ||
          formData.entry_csssa_other_punishment_value_id === "" ||
          formData.entry_csssa_other_punishment_value_id === null
        ) {
          errors.push("Other punishment");
        }
        if (
          formData.legal === undefined ||
          formData.legal === "" ||
          formData.legal === null
        ) {
          errors.push("Legal");
        }

        console.log(errors);

        return errors;
      };
    case "A1-3":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-4":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-5":
    case "A1-6":
    case "A1-7":
    case "A1-8":
    case "A1-9":
    case "A1-10":
    case "A1-11":
    case "A1-12":
    case "A1-13":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-14":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-15":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-16":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-17":
      return (formData) => {
        let errors = [];

        return errors;
      };
    case "A1-18":
      return (formData) => {
        let errors = [];

        return errors;
      };

    default:
      return (formData) => {
        let errors = [];

        return errors;
      };
  }
};

export const formSetter = (setters) => {};
