import { ConfirmProvider } from "material-ui-confirm";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import AlertLogs from "./admin/AlertLogs";
import SimilarityTest from "./admin/SimilarityTest";
import Bin from "./bin/Bin";
import Dashboard from "./dashboard/Dashboard";
import ExclusionRules from "./exclusion_rules/ExclusionRules";
import DeleteSegments from "./delete_segments/DeleteSegments";
import Dashbox from "./inbox/Dashbox";

import Inbox from "./inbox/Inbox";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import Sources from "./source/Sources";
import JurTags from "./tag_manager/JurTags";
import TagManager from "./tag_manager/TagManager";
import TierManager from "./tag_manager/TierManager";
import UntrackedInbox from "./untracked_inbox/UntrackedInbox";
import Home from "./user/Home";
import UserRoles from "./user/UserRoles";
import Users from "./user/Users";
import IwmUsers from "./iwm_user/IwmUsers";
import setAuthToken from "./utils/setAuthToken";
import DashChilds from "./inbox/DashChilds";
import DbUsers from "./db_user/DbUsers";
import CustomChipInput from "./shared/components/FormElements/CustomChipInput";
import DbDashboard from "./db/DbDashboard";
import DbRevisions from "./db/DbRevisions";
import TipTapProvider from "./shared/providers/TipTapProvider";
import LogRocket from "logrocket";
import NewGeneralExplan from "./db/Playground/NewGeneralExplan";
import AppBranch from "./AppBranch";
import DbDrafts from "./db/DbDrafts";
const App = (props) => {
  const { token, login, logout, userId, roles } = useAuth();

  //
  useEffect(() => {
    if (process.env.REACT_APP_LIVE === "yes") {
      LogRocket.init("ilga-aspu/ilga-aspu");
    }
  }, []);
  //useEffect(() => {console.log(location.pathname);}, [token]);

  let routes;

  if (token) {
    setAuthToken(token, logout);
  }

  if (token) {
    routes = (
      <Switch>
        <Route path="/user-roles" exact component={UserRoles} />
        <Route path="/users" exact component={Users} />
        <Route path="/iwm_users" exact component={IwmUsers} />
        <Route path="/db_users" exact component={DbUsers} />
        <Route path="/sources" exact component={Sources} />
        <Route path="/alert-logs" exact component={AlertLogs} />
        <Route path="/similarity-test" exact component={SimilarityTest} />
        <Route path="/inbox" exact component={Inbox} />
        <Route path="/inbox/:id" exact component={Inbox} />
        <Route path="/usi" exact component={UntrackedInbox} />
        <Route path="/dash_childs" exact component={DashChilds} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/dashboard/:id" exact component={Dashboard} />
        <Route path="/dashbox" exact component={Dashbox} />
        <Route path="/bin" exact component={Bin} />
        <Route path="/tag_manager" exact component={TagManager} />
        <Route path="/tier_triggers" exact component={TierManager} />
        <Route path="/exclusion_rules" exact component={ExclusionRules} />
        <Route path="/delete_segments" exact component={DeleteSegments} />
        <Route path="/jur_tags" exact component={JurTags} />
        <Route path="/db-dashboard" exact component={DbDashboard} />
        <Route
          path="/db-entry/:jurisdictionId/:entryTypeId/:subjurisdictionId"
          exact
          component={DbDashboard}
        />
        <Route
          path="/db-my-revisions"
          exact
          render={(props) => (
            <DbRevisions {...props} myRevisions={true} stage={1} />
          )}
        />
        <Route path="/db-revisions" exact component={DbRevisions} />
        <Route path="/db-drafts" exact component={DbDrafts} />
        <Route
          path="/db-drafts-all"
          exact
          render={(props) => <DbDrafts {...props} myDrafts={false} />}
        />
        <Route
          path="/db-revisions-rejected"
          exact
          render={(props) => <DbDrafts {...props} returned={true} />}
        />
        <Route
          path="/db-revisions-admin-rejected"
          exact
          render={(props) => <DbRevisions {...props} stage={4} />}
        />
        <Route
          path="/db-revisions-approved"
          exact
          render={(props) => <DbRevisions {...props} approved={true} />}
        />
        <Route
          path="/db-revisions-to-approve"
          exact
          render={(props) => <DbRevisions {...props} stage={3} />}
        />
        <Route path="/new_explan" exact component={NewGeneralExplan} />

        <Route path="/:say/hello" exact>
          <div>Hello!</div>
        </Route>
        <Route
          path="/"
          exact
          render={(props) => <Home {...props} isLoggedIn={token} />}
        />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route
          path="/"
          render={(props) => <Home {...props} isLoggedIn={token} />}
        />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        roles: roles,
        login: login,
        logout: logout,
      }}
    >
      <ConfirmProvider>
        <Router>
          <AppBranch routes={routes} token={token} props={props} />
        </Router>
      </ConfirmProvider>
    </AuthContext.Provider>
  );
};

export default App;
