import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import clsx from "clsx";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addIwmUser,
  clearCurrentIwmUser,
  clearErrorIwmUsers,
  deleteIwmUser,
  getIwmUsers,
  setCurrentIwmUser,
  setOpenIwmUser,
  updateIwmUser,
} from "../actions/iwm_users";
import { getLanguages } from "../actions/related";

const PREFIX = 'IwmUserForm';

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  }
}));

const emptyIwmUser = {
  pronoun: "",
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  showPassword: false,
  admin: false,
  ilga_user: false,
};

const IwmIwmUserForm = ({
  current,
  open,
  clearCurrentIwmUser,
  updateIwmUser,
  setOpenIwmUser,
  addIwmUser,
  loading,
  allLanguages,
  getLanguages,
}) => {

  const [iwm_user, setIwmUser] = useState(emptyIwmUser);

  const {
    pronoun,
    firstname,
    lastname,
    email,
    password,
    showPassword,
    admin,
    ilga_user,
    disabled,
    blocked,
  } = iwm_user;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setIwmUser(current);
    } else {
      setIwmUser(emptyIwmUser);
    }
  }, [current, open]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenIwmUser(false);
    clearCurrentIwmUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addIwmUser(iwm_user);
      } else {
        updateIwmUser(iwm_user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setIwmUser(emptyIwmUser);
    clearCurrentIwmUser();
  };

  const onChange = (e, newVal = null) => {
    let val;
    if (
      e.target.name === "admin" ||
      e.target.name === "disabled" ||
      e.target.name === "blocked" ||
      e.target.name === "ilga_user"
    ) {
      setIwmUser({ ...iwm_user, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      val = e.target.value;
      setIwmUser({ ...iwm_user, [e.target.name]: val });
    }
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down('md'))
            ? { width: "40%", height: "45%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New ILGA World Monitor User"
            : current && !open && !current._readonly
            ? "Edit ILGA World Monitor User"
            : "ILGA World Monitor User"}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit} autocomplete="off">
            <input
              autocomplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />

            <input type="submit" style={{ display: "none" }} />
            {((disabled && blocked !== 0) || !disabled) && (
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    margin="dense"
                    name="pronoun"
                    label="Pronoun"
                    value={pronoun}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={5}>
                  <TextField
                    margin="dense"
                    name="firstname"
                    label="First Name"
                    value={firstname}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={5}>
                  <TextField
                    margin="dense"
                    name="lastname"
                    label="Last Name"
                    value={lastname}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="email"
                    label="E-mail"
                    autocomplete="off"
                    value={email}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      type={showPassword ? "text" : "password"}
                      margin="dense"
                      name="password"
                      id="password"
                      autocomplete="off"
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) =>
                              setIwmUser({
                                ...iwm_user,
                                showPassword: !iwm_user.showPassword,
                              })
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            size="large">
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      className={classes.textField}
                      labelWidth={70}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={disabled}
                        onChange={onChange}
                        value="1"
                        name="disabled"
                        color="primary"
                      />
                    }
                    label="Disabled"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={blocked}
                        onChange={onChange}
                        value="1"
                        name="blocked"
                        color="primary"
                      />
                    }
                    label="Blocked"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={admin}
                        name="admin"
                        color="primary"
                        value="1"
                        onChange={onChange}
                      />
                    }
                    label="Admin"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ilga_user}
                        name="ilga_user"
                        color="primary"
                        value="1"
                        onChange={onChange}
                      />
                    }
                    label="ILGA User"
                  />
                </Grid>
              </Grid>
            )}
            <br />
            {current && (
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={12}>
                  <strong>ILGA World Monitor detils:</strong>
                </Grid>

                <Grid item xs={12} md={6}>
                  <strong>CSO Name:</strong> {current.cso_name}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>ILGA Mem?:</strong>{" "}
                  {current.ilga_member ? "Yes" : "No"}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>How find?:</strong>{" "}
                  {current.how_find == 1
                    ? "Personal invitation to join"
                    : current.how_find == 2
                    ? "ILGA Newsletter"
                    : current.how_find == 3
                    ? "Recommended by friend/colleague"
                    : current.how_find == 4
                    ? "Other: " + current.how_find_other
                    : ""}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Access Reason:</strong> {current.access_reason}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Reference Name:</strong> {current.reference_name}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Reference E-mail:</strong> {current.reference_email}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>ID Number:</strong> {current.id}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Number of Searches:</strong> {current.number_searches}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Saved Searches:</strong> {current.n_ss}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Saved Folders:</strong> {current.n_sf}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Last Login:</strong>{" "}
                  {current.last_login && (
                    <Fragment>
                      {moment(new Date(current.last_login)).format(
                        "DD MMMM YYYY hh:mm:ss"
                      )}{" "}
                      (
                      {moment().diff(
                        moment(new Date(current.last_login)).format(
                          "DD MMMM YYYY"
                        ),
                        "days"
                      )}{" "}
                      days ago)
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <strong>Account created:</strong>{" "}
                  {moment(new Date(current.created_at)).format("DD MMMM YYYY")}
                </Grid>
              </Grid>
            )}
          </form>
        </DialogContent>

        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>

        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  iwm_users: state.iwm_users.all,
  loading: state.iwm_users.loading,
  error: state.iwm_users.error,
  current: state.iwm_users.current,
  open: state.iwm_users.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteIwmUser: (id) => dispatch(deleteIwmUser(id)),
  getIwmUsers: () => dispatch(getIwmUsers()),
  clearErrorIwmUsers: () => dispatch(clearErrorIwmUsers()),
  setCurrentIwmUser: (iwm_user) => dispatch(setCurrentIwmUser(iwm_user)),
  setOpenIwmUser: (should) => dispatch(setOpenIwmUser(should)),
  clearCurrentIwmUser: () => dispatch(clearCurrentIwmUser()),
  addIwmUser: (iwm_user) => dispatch(addIwmUser(iwm_user)),
  updateIwmUser: (iwm_user) => dispatch(updateIwmUser(iwm_user)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(IwmIwmUserForm);
