import React, { useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import TrashcanIcon from "../Icons/TrashcanIcon";
import TipTapContext from "../../../shared/context/tipTap-context";

const DeleteFootnoteModal = ({ data, lang, section, columnName }) => {
  const [open, setOpen] = useState(false);
  const [footnoteToDelete, setFootnoteToDelete] = useState({});
  const { deleteFootnoteAction, ctxEntry } = useContext(TipTapContext);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("Delete footnote is confirmed -> ", ctxEntry);
    setOpen(false);
    deleteFootnoteAction(footnoteToDelete, ctxEntry, lang, columnName);
  };

  useEffect(() => {
    console.log("ctxEntry in fake effect: ", ctxEntry);
  }, [ctxEntry]);

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => {
            const order = data["data-index"];
            const language = lang;
            const footnotes = ctxEntry.entries.footnotes;
            console.log("order on click: ", order);
            console.log("language on click: ", language);
            console.log("data on click: ", data);
            console.log("entry entries footnotes: ", footnotes);
            const footnoteToDelete = footnotes.filter((footnote) => {
              return (
                footnote.lang === language &&
                footnote.order === order &&
                (!section || footnote.section === section)
              );
            });
            if (footnoteToDelete.length > 0)
              setFootnoteToDelete(footnoteToDelete[0]);

            handleOpen();
          }}
        >
          <TrashcanIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        className="deleteConfirmation__dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete footnote {footnoteToDelete.text}
          <sup>{footnoteToDelete.order}</sup>.
        </DialogTitle>
        <DialogContent>
          <div id="alert-dialog-description">
            <p>
              This will only remove the footnote from the box you are acting on
            </p>
            <p>Are you sure you want to do this?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: "auto" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes, delete it please
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteFootnoteModal;
