import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";

import { MuiChipsInput } from "mui-chips-input";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  addTag,
  clearCurrentTag,
  clearErrorTags,
  deleteTag,
  getTags,
  setCurrentTag,
  setOpenTag,
  updateTag,
} from "../actions/tags";

import { getLanguages } from "../actions/related";
import CustomChipInput from "../shared/components/FormElements/CustomChipInput";

const PREFIX = "TagForm";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const emptyTag = {
  tag: "",
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const TagForm = ({
  current,
  prefilled,
  clearPrefilled,
  open,
  clearCurrentTag,
  updateTag,
  setOpenTag,
  addTag,
  allLanguages,
  getLanguages,
  tag_types,
  getTagTypes,
  tag_statuses,
  getTagStatuses,
  jurisdictions,
  getJurisdictions,
  loading,
}) => {
  const [tag, setTag] = useState(emptyTag);
  const [statusValue, setStatusValue] = React.useState("Active");
  const [typeValue, setTypeValue] = React.useState("Media Outlet");

  const lang_triggers = ["en", "zh", "fr", "pt", "es", "ar", "ms", "it"];

  const {
    en_triggers,
    tag_type_id,
    languages,
    tag_status_id,
    jurisdiction_id,
  } = tag;

  const handleChange = (chips, lang) => {
    setTag({ ...tag, [lang + "_triggers"]: chips.join(";") });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();

    if (current !== null) {
      setTag({ ...current });
    } else {
      setTag(emptyTag);
    }
  }, [current, open, prefilled]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenTag(false);
    clearCurrentTag();
    if (clearPrefilled) clearPrefilled();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current || !current.id) {
        addTag(tag);
      } else {
        updateTag(tag);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setTag(emptyTag);
    clearCurrentTag();
  };

  const onChange = (e, newVal = null) => {
    let val = e.target.value;
    if (e.target.name === "tag")
      setTag({
        ...tag,
        [e.target.name]: val,
        tag_lower:
          val.toLowerCase().charAt(0).toUpperCase() +
          val.toLowerCase().slice(1),
      });
    else setTag({ ...tag, [e.target.name]: val });
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("md"))
            ? { width: "90%", height: "90%", margin: "auto" }
            : {}
        }
        open={
          (current && !(!current.id && current.tag_status_id === 5)) || open
            ? true
            : false
        }
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Tag"
            : current && !open
            ? "Edit Tag"
            : "Add Tag"}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="tag"
                  label="Name"
                  value={tag.tag}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="tag_lower"
                  label="Normal Case"
                  value={tag.tag_lower}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              {allLanguages.length &&
                lang_triggers.map((lang) => (
                  <Grid item xs={12} md={12}>
                    <CustomChipInput
                      label={
                        allLanguages.find((l) => l.id === lang).name +
                        " Triggers"
                      }
                      defaultValue={
                        tag[lang + "_triggers"]
                          ? tag[lang + "_triggers"].split(";")
                          : []
                      }
                      fullWidth
                      onChange={(chips) => handleChange(chips, lang)}
                    />
                  </Grid>
                ))}{" "}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tags.all,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current,
  open: state.tags.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTag: (id) => dispatch(deleteTag(id)),
  getTags: () => dispatch(getTags()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTag: (tag) => dispatch(setCurrentTag(tag)),
  setOpenTag: (should) => dispatch(setOpenTag(should)),
  clearCurrentTag: () => dispatch(clearCurrentTag()),
  addTag: (tag) => dispatch(addTag(tag)),
  updateTag: (tag) => dispatch(updateTag(tag)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
