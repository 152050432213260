import { Button, Grid, Icon, IconButton, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Pagination } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { utc } from "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  addTagTriggers,
  addTierTriggers,
  clearCurrentArticle,
  getDashboxArticles,
  getInboxArticles,
  reassignMother,
  setBackToInbox,
  setCurrentArticle,
  updateJurs,
  updateTags,
} from "../actions/articles";
import {
  getAlertSubaccounts,
  getJurisdictions,
  getLanguages,
  getSourceTypes,
  getTags,
} from "../actions/related";
import { getSources } from "../actions/sources";
import { getUsers } from "../actions/users";
import ArticleForm from "../articles/ArticleForm";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import AddJur from "./AddJur";
import AddTag from "./AddTag";
import DashboxFilters from "./DashboxFilters";
import InboxArticle from "./InboxArticle";
import InboxCard from "./InboxCard";
import "./react-dates-overrides.css";
import TriggerForm from "./TriggerForm";

const PREFIX = "Dashbox";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  snack: `${PREFIX}-snack`,
  marginAutoContainer: `${PREFIX}-marginAutoContainer`,
  marginAutoItem: `${PREFIX}-marginAutoItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
  [`& .${classes.snack}`]: { backgroundColor: "#878bff" },
  [`& .${classes.marginAutoContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
  [`& .${classes.marginAutoItem}`]: {
    //margin: "auto"
  },
});

const Inbox = ({
  error,
  sources,
  users,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
  getSourceTypes,
  source_types,
  getJurisdictions,
  jurisdictions,
  getTags,
  tags,
  languages,
  getLanguages,
  getInboxArticles,
  inbox_articles,
  current,
  setCurrentArticle,
  clearCurrentArticle,
  getDashboxArticles,
  show_articles,
  loading,
  setBackToInbox,
  reassignMother,
  filter,
  updateJurs,
  updateTags,
  addTagTriggers,
  addTierTriggers,
}) => {
  const [date, setDate] = useState(
    utc(new Date()).subtract(19, "days").toDate()
  );

  const [open, setOpen] = React.useState(false);

  const [articleNewJur, setArticleNewJur] = React.useState(null);
  const [articleNewTag, setArticleNewTag] = React.useState(null);

  const [page, setPage] = React.useState(1);

  const [reassign, setReassign] = React.useState(null);
  const [reassignChildren, setReassignChildren] = React.useState([]);

  const [selectedArticle, setSelectedArticle] = React.useState({});

  const [undoDeleteArticle, setUndoDeleteArticle] = React.useState(null);

  const [undoApproveArticle, setUndoApproveArticle] = React.useState(null);

  const [newTrigger, setNewTrigger] = React.useState({});

  const [success, setSuccess] = React.useState("");

  const [multipleRemove, setMultipleRemove] = React.useState([]);

  const handleCloseReassigned = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess("");
  };

  const rowsPerPage = 30;

  const addReassignChildren = (child) => {
    setReassignChildren([...reassignChildren, child]);
  };

  const removeReassignChildren = (child_id) => {
    setReassignChildren(reassignChildren.filter((c) => c.id !== child_id));
  };

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!tags || !tags.length) getTags();
    if (!users || !users.length) getUsers();
    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();

    getDashboxArticles({
      header: "",
      sou: "",
      startDate: utc().subtract(40, "days"),
      endDate: utc().subtract(37, "days"),
    });
  }, [date]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
    setSelectedArticle({});
  }

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashbox - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} />
      <ArticleForm
        setSuccess={setSuccess}
        current={current}
        setCurrentArticle={setCurrentArticle}
        clearCurrentArticle={clearCurrentArticle}
      />
      <InboxArticle
        selectedArticle={selectedArticle}
        open={open || false}
        onClose={handleClose}
      />
      <TriggerForm
        newTrigger={newTrigger}
        setNewTrigger={setNewTrigger}
        addTierTriggers={addTierTriggers}
      />
      {jurisdictions && jurisdictions.length > 0 && (
        <AddJur
          article={articleNewJur}
          jurisdictions={jurisdictions}
          setArticleNewJur={setArticleNewJur}
          updateJurs={updateJurs}
        />
      )}
      {tags && tags.length > 0 && (
        <AddTag
          article={articleNewTag}
          tags={tags}
          setArticleNewTag={setArticleNewTag}
          updateTags={updateTags}
        />
      )}

      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {reassign && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ top: "64px" }}
              ContentProps={{
                classes: {
                  root: classes.snack,
                },
              }}
              open={reassign !== null}
              onClose={handleClose}
              message={
                'Assigning children to "' +
                (reassign.source ? reassign.source.name + "'s\"" : "") +
                ' article "' +
                (reassign.translated_header || reassign.original_header).slice(
                  0,
                  25
                ) +
                '..."'
              }
              action={
                <React.Fragment>
                  {reassignChildren.length > 0 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        const justArticle = {
                          ...reassign,
                        };
                        delete justArticle.articles;
                        reassignMother(justArticle, reassignChildren);
                        setReassign(null);
                        setReassignChildren([]);
                        setSuccess("Children reassigned successfully");
                      }}
                    >
                      REASSIGN {reassignChildren.length} ARTICLES
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => {
                      setReassign(null);
                      setReassignChildren([]);
                    }}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <Snackbar
            open={!loading && success}
            autoHideDuration={3000}
            onClose={handleCloseReassigned}
            style={{ top: "64px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseReassigned}
              severity="success"
            >
              {success.msg ? success.msg : success}
            </MuiAlert>
          </Snackbar>
          {source_types && source_types.length > 0 && (
            <DashboxFilters
              setPage={setPage}
              getDashboxArticles={getDashboxArticles}
              articles={show_articles}
              jurisdictions={jurisdictions}
              source_types={source_types}
              sources={sources}
              users={users}
              source_types={source_types}
              languages={languages}
              tags={tags}
            />
          )}
          <br />
          <br />

          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => setPage(value)}
                boundaryCount={20}
              />
            </div>
          </div>
          {!loading && inbox_articles.length === 0 && (
            <center>
              <iframe
                src="https://giphy.com/embed/3oz8xAFtqoOUUrsh7W"
                width="480"
                height="320"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              />
            </center>
          )}
          <Grid container>
            {show_articles &&
              show_articles.length > 0 &&
              show_articles
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((article, index) => (
                  <Grid item md={12} padding={"0px"} key={index}>
                    <InboxCard
                      key={article.id}
                      article={article}
                      sources={sources}
                      inboxPosition={index}
                      source_types={source_types}
                      jurisdictions={jurisdictions}
                      languages={languages}
                      reassign={reassign}
                      setReassign={setReassign}
                      addReassignChildren={addReassignChildren}
                      reassignChildren={reassignChildren}
                      toReassign={
                        reassignChildren
                          .map((c) => c.id)
                          .indexOf(article.id) !== -1
                      }
                      removeReassignChildren={removeReassignChildren}
                      setUndoDeleteArticle={setUndoDeleteArticle}
                      setUndoApproveArticle={setUndoApproveArticle}
                      setSuccess={setSuccess}
                      success={success}
                      mother={article}
                      setArticleNewJur={setArticleNewJur}
                      setArticleNewTag={setArticleNewTag}
                      setCurrentArticle={setCurrentArticle}
                      setNewTrigger={setNewTrigger}
                      multipleRemove={multipleRemove}
                      setMultipleRemove={setMultipleRemove}
                    />
                  </Grid>
                ))}
          </Grid>
          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                boundaryCount={20}
              />
            </div>
          </div>
          {undoDeleteArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoDeleteArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoDeleteArticle(null);
              }}
              message={
                (undoDeleteArticle.source
                  ? undoDeleteArticle.source.name + "'s \""
                  : '"') +
                (
                  undoDeleteArticle.translated_header ||
                  undoDeleteArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article moved to bin"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoDeleteArticle);
                      setUndoDeleteArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoDeleteArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

          {undoApproveArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoApproveArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoApproveArticle(null);
              }}
              message={
                (undoApproveArticle.source
                  ? undoApproveArticle.source.name + "'s \""
                  : '"') +
                (
                  undoApproveArticle.translated_header ||
                  undoApproveArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article approved"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoApproveArticle);
                      setUndoApproveArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoApproveArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts,
  source_types: state.related.source_types,
  users: state.users.all,
  jurisdictions: state.related.jurisdictions,
  tags: state.related.tags,
  languages: state.related.languages,
  inbox_articles: state.articles.inbox,
  filter: state.articles.filter,
  loading: state.articles.loading,
  show_articles: state.articles.inbox,
  current: state.articles.current,
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getTags: () => dispatch(getTags()),
  getUsers: () => dispatch(getUsers()),
  getLanguages: () => dispatch(getLanguages()),
  getInboxArticles: (date) => dispatch(getInboxArticles(date)),
  getDashboxArticles: (filters) => dispatch(getDashboxArticles(filters)),
  setBackToInbox: (article) => dispatch(setBackToInbox(article)),
  reassignMother: (mother, children) =>
    dispatch(reassignMother(mother, children)),
  updateJurs: (art, jurs) => dispatch(updateJurs(art, jurs)),
  updateTags: (art, tags) => dispatch(updateTags(art, tags)),
  addTagTriggers: (tag, triggers) => dispatch(addTagTriggers(tag, triggers)),
  addTierTriggers: (tier, triggers) =>
    dispatch(addTierTriggers(tier, triggers)),
  setCurrentArticle: (art) => dispatch(setCurrentArticle(art)),
  clearCurrentArticle: () => dispatch(clearCurrentArticle()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
