import React, { useEffect, useState } from "react";


import { styled } from '@mui/material/styles';


import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import {Icon} from "@mui/material"

const PREFIX = 'UserRoles';

const classes = {
  table: `${PREFIX}-table`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
});

const UserRoles = (props) => {


  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedRoles, setLoadedRoles] = useState();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/userRoles"
        );

        setLoadedRoles(responseData.roles);
      } catch (err) {}
    };
    fetchRoles();
  }, [sendRequest]);

  return (
    <Root>
      <ErrorSnack error={error} onClear={clearError} />
      {isLoading && (
        <Container maxWidth="xl">
          <CssBaseline />
          <div className="center">
            <LoadingSpinner />
          </div>
        </Container>
      )}
      {!isLoading && loadedRoles && (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="md">
            <h1>User Roles</h1>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadedRoles.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      <TableCell align="right">
                        <Icon>delete</Icon>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </React.Fragment>
      )}
    </Root>
  );
};

export default UserRoles;
