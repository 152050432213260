import React, { useEffect, useState, useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  deleteSource,
  getSources,
  clearErrorSources,
  setCurrentSource,
  clearCurrentSource,
  setBypassIoSources,
} from "../actions/sources";
import {
  getLanguages,
  getSourceTypes,
  getSourceStatuses,
  getJurisdictions,
} from "../actions/related";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import SourceForm from "./SourceForm";
import AddSourceBtn from "./AddSourceBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Icon, Breadcrumbs, Link, TextField, Grid } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import SourceFilter from "./SourceFilter";
import ProgressBar from "../shared/components/UIElements/ProgressBar";

import { setFilters } from "../actions/sources";

const PREFIX = "Sources";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const Sources = ({
  sources,
  loading,
  error,
  current,
  bypassIo,
  clearErrorSources,
  setCurrentSource,
  clearCurrentSource,
  setBypassIoSources,
  deleteSource,
  getSources,
  setFilters,
  allLanguages,
  getLanguages,
  source_types,
  getSourceTypes,
  source_statuses,
  getSourceStatuses,
  jurisdictions,
  getJurisdictions,
  filter,
}) => {
  const auth = useContext(AuthContext);
  const confirm = useConfirm();
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      width: "10%",
      label: "Name",
      transform: function (row) {
        return row.is_relevant ? <strong>{row.name}</strong> : row.name;
      },
    },
    {
      id: "url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      width: "10%",
      transform: function (row) {
        return (
          <a href={"//" + row.url} target="_blank">
            {row.url}
          </a>
        );
      },
    },
    {
      id: "n_new",
      numeric: true,
      disablePadding: false,
      width: "5%",
      label: "Inbox",
    },
    {
      id: "n_excluded",
      numeric: true,
      width: "5%",
      disablePadding: false,
      label: "Excluded",
    },
    {
      id: "n_rejected",
      numeric: true,
      width: "5%",
      disablePadding: false,
      label: "Rejected",
    },
    {
      id: "n_approved",
      numeric: true,
      width: "5%",
      disablePadding: false,
      label: "Approved",
    },
    {
      id: "source_type",
      numeric: false,
      width: "10%",
      disablePadding: false,
      label: "Source Type",
      transform: function (row) {
        let st = source_types.find((st) => st.id === row.source_type_id);
        return st ? st.name : "";
      },
    },
    {
      id: "source_status",
      numeric: false,
      width: "10%",
      disablePadding: false,
      label: "Status",
      transform: function (row) {
        let ss = source_statuses.find((ss) => ss.id === row.source_status_id);
        return ss ? ss.name : "";
      },
    },
    {
      id: "jurisdiction",
      numeric: false,
      width: "10%",
      disablePadding: false,
      label: "JUR",
      transform: function (row) {
        let j = jurisdictions.find((j) => j.id === row.jurisdiction_id);
        return j ? j.name : "";
      },
    },

    {
      id: "languages",
      numeric: false,
      disablePadding: false,
      label: "Languages",
      property: "name",
      width: "10%",
      transform: function (row) {
        if (row.languages)
          return row.languages
            .map((lang) =>
              allLanguages.find((l) => l.id === lang.id)
                ? allLanguages.find((l) => l.id === lang.id).name
                : "[" + lang.id + "]"
            )
            .join(", ");
        else return "-";
      },
    },
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!source_statuses || !source_statuses.length) getSourceStatuses();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if ((!sources || sources.length === 0) && !error) getSources();

    if (!loading) {
      subscribeTo("sourcesChanged", (err, data) => {
        if (!bypassIo) getSources();
        else {
          getSources();
          setBypassIoSources(false);
        }
      });
      return () => {
        subscribeTo("sourcesChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickEdit = (source) => {
    setCurrentSource(source);
  };

  const onClickDelete = (source) => {
    confirm({
      description: `Are you sure you want to delete the source "${source.name}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => deleteSource(source.id));
  };

  const clearFilter = () => {
    setFilters({});
  };

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Source Manager - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorSources} />
      <React.Fragment>
        <CssBaseline />
        <SourceForm
          prefilled={false}
          current={current}
          clearCurrentSource={clearCurrentSource}
        />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {sources.length !== 0 &&
            allLanguages.length !== 0 &&
            source_types.length !== 0 && (
              <Fragment>
                <EnhancedTable
                  rows={filter.active ? filter.result : sources}
                  columns={columns}
                  options={{ title: "Sources List", multiple_select: false }}
                  actions={{
                    edit: onClickEdit,
                    delete: onClickDelete,
                    clearFilter: clearFilter,
                  }}
                >
                  <SourceFilter />
                </EnhancedTable>
              </Fragment>
            )}
          <AddSourceBtn />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  loading: state.sources.loading,
  error: state.sources.error,
  current: state.sources.current,
  filter: state.sources.filter,
  bypassIo: state.sources.bypassIo,
  allLanguages: state.related.all_languages,
  source_types: state.related.source_types,
  source_statuses: state.related.source_statuses,
  jurisdictions: state.related.jurisdictions,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSource: (id) => dispatch(deleteSource(id)),
  getSources: () => dispatch(getSources()),
  clearErrorSources: () => dispatch(clearErrorSources()),
  setCurrentSource: (source) => dispatch(setCurrentSource(source)),
  clearCurrentSource: () => dispatch(clearCurrentSource()),
  setBypassIoSources: (bypass) => dispatch(setBypassIoSources(bypass)),
  getLanguages: () => dispatch(getLanguages()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getSourceStatuses: () => dispatch(getSourceStatuses()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  setFilters: (f) => dispatch(setFilters(f)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sources);
