import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RadioButtonsGroup = ({ selected, setSelectedValue }) => {
  // selected needs to be inferred from filename in drawer
  const handleSelectionChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <FormControl className="radioGroup__form-container">
      <RadioGroup
        className="radioGroup__container"
        row
        aria-labelledby="some-type-label"
        name="row-radio-buttons-group"
        value={selected}
        onChange={handleSelectionChange}
      >
        <FormControlLabel value="EXE" control={<Radio />} label="EXE" />
        <FormControlLabel value="LEG" control={<Radio />} label="LEG" />
        <FormControlLabel value="JUD" control={<Radio />} label="JUD" />
        <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="Other"
        />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonsGroup