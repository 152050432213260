import React, { useEffect, useState, useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  deleteExclusionRule,
  getExclusionRules,
  clearErrorExclusionRules,
  setCurrentExclusionRule,
  clearCurrentExclusionRule,
  setBypassIoExclusionRules,
} from "../actions/exclusion_rules";

import { getLanguages } from "../actions/related";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import ExclusionRuleForm from "./ExclusionRuleForm";
import AddExclusionRuleBtn from "./AddExclusionRuleBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Icon, Breadcrumbs, Link, Grid } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";
import { Helmet } from "react-helmet";

const PREFIX = "ExclusionRules";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const ExclusionRules = ({
  exclusion_rules,
  loading,
  error,
  current,
  bypassIo,
  clearErrorExclusionRules,
  setCurrentExclusionRule,
  clearCurrentExclusionRule,
  setBypassIoExclusionRules,
  deleteExclusionRule,
  getExclusionRules,
  dispatch,
  getLanguages,
  allLanguages,
}) => {
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if ((!exclusion_rules || exclusion_rules.length === 0) && !error)
      getExclusionRules();
    if (!loading) {
      subscribeTo("exclusion_rulesChanged", (err, data) => {
        if (!bypassIo) getExclusionRules();
        else {
          getExclusionRules();
          setBypassIoExclusionRules(false);
        }
      });
      return () => {
        subscribeTo("exclusion_rulesChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, exclusion_rule) => {
    confirm({
      description: `Are you sure you want to delete the exclusion_rule "${exclusion_rule.email}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => deleteExclusionRule(exclusion_rule.id));
  };

  const onClickEdit = (lang) => {
    setCurrentExclusionRule(lang);
  };

  const columns = [
    {
      id: "language_id",
      numeric: false,
      disablePadding: false,
      label: "Language",
      transform: function (row) {
        let lang = allLanguages.find((r) => r.id === row.language_id).name;
        return lang === "English" ? "All languages" : lang;
      },
      width: "15%",
    },
    {
      id: "rules",
      numeric: false,
      disablePadding: false,
      label: "Rules",
      noSelect: true,
      transform: function (row) {
        return row.rules ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.rules
                .sort((a, b) => a.localeCompare(b))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    {t.t}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Exclusion Rules - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorExclusionRules} />
      <React.Fragment>
        <CssBaseline />
        <ExclusionRuleForm
          current={current}
          clearCurrentExclusionRule={clearCurrentExclusionRule}
        />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {exclusion_rules.length !== 0 && (
            <EnhancedTable
              rows={exclusion_rules.sort((a, b) => {
                let aL = allLanguages.find((r) => r.id === a.language_id).name;
                let bL = allLanguages.find((r) => r.id === b.language_id).name;
                if (aL === "English") return -1;
                return aL > bL ? 1 : aL < bL ? -1 : 0;
              })}
              columns={columns}
              options={{
                title: "Exclusion Rules Manager",
                multiple_select: false,
                hide_pagination: true,
              }}
              actions={{ edit: onClickEdit }}
            ></EnhancedTable>
          )}
          <AddExclusionRuleBtn />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  exclusion_rules: state.exclusion_rules.all,
  loading: state.exclusion_rules.loading,
  error: state.exclusion_rules.error,
  current: state.exclusion_rules.current,
  bypassIo: state.exclusion_rules.bypassIo,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteExclusionRule: (id) => dispatch(deleteExclusionRule(id)),
  getExclusionRules: () => dispatch(getExclusionRules()),
  clearErrorExclusionRules: () => dispatch(clearErrorExclusionRules()),
  setCurrentExclusionRule: (exclusion_rule) =>
    dispatch(setCurrentExclusionRule(exclusion_rule)),
  clearCurrentExclusionRule: () => dispatch(clearCurrentExclusionRule()),
  setBypassIoExclusionRules: (bypass) =>
    dispatch(setBypassIoExclusionRules(bypass)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExclusionRules);
