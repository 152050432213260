import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import CssBaseline from "@mui/material/CssBaseline";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLanguages } from "../actions/related";
import {
  addUser,
  clearCurrentUser,
  clearErrorUsers,
  deleteUser,
  getUsers,
  setCurrentUser,
  setOpenUser,
  updateUser,
} from "../actions/users";

const PREFIX = "UserForm";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const emptyUser = {
  name: "",
  email: "",
  password: "",
  roles: [],
  showPassword: false,
  weekly_digest_ok: false,
  languages: [{ name: "English", id: "en" }],
};

const UserForm = ({
  current,
  open,
  clearCurrentUser,
  updateUser,
  setOpenUser,
  addUser,
  loading,
  allLanguages,
  getLanguages,
}) => {
  const [user, setUser] = useState(emptyUser);

  const {
    name,
    email,
    password,
    languages,
    roles,
    showPassword,
    weekly_digest_ok,
  } = user;
  const ITEM_HEIGHT = 72;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theRoles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Editor" },
    { id: 3, name: "Editor Plus" },
    { id: 4, name: "Database Editor" },
    { id: 5, name: "Database Proofreader" },
    { id: 6, name: "Database Admin" },
    { id: 7, name: "Read Only" },
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setUser(current);
    } else {
      setUser(emptyUser);
    }
  }, [current, open]);

  useEffect(() => {
    console.log(roles);
  }, [roles]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenUser(false);
    clearCurrentUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addUser(user);
      } else {
        updateUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setUser(emptyUser);
    clearCurrentUser();
  };

  const onChange = (e, newVal = null) => {
    let val;
    if (e === "languages") {
      setUser({ ...user, [e]: newVal });
    } else if (e.target.name === "roles") {
      val = e.target.value;
      val = val.map((v) => {
        if (Number.isInteger(v))
          return {
            id: v,
            name: theRoles.find((r) => r.id === v).name,
          };
        else return v;
      });
      setUser({ ...user, [e.target.name]: val });
    } else {
      val = e.target.value;
      setUser({ ...user, [e.target.name]: val });
    }
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("lg"))
            ? { width: "40%", height: "40%", margin: "auto" }
            : { width: "95%", height: "95%", margin: "auto" }
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open ? "New User" : current && !open ? "Edit User" : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="email"
                  label="E-mail"
                  value={email}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    name="password"
                    id="password"
                    autocomplete="off"
                    onChange={onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) =>
                            setUser({
                              ...user,
                              showPassword: !user.showPassword,
                            })
                          }
                          onMouseDown={(e) => e.preventDefault()}
                          size="large"
                        >
                          {showPassword ? (
                            <Icon>visibility</Icon>
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={classes.textField}
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Roles
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="roles"
                    multiple
                    value={roles.map((r) => r.id)}
                    onChange={onChange}
                    input={<Input />}
                    renderValue={(selected) =>
                      selected
                        .map((s) => theRoles.find((r) => r.id === s).name)
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {theRoles.map((role, index) => (
                      <MenuItem value={role.id} key={index}>
                        <Checkbox
                          checked={
                            roles.find((r) => role.id === r.id) ? true : false
                          }
                        />
                        <ListItemText primary={role.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8} md={8}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={allLanguages}
                    disableClearable={true}
                    classes={{
                      option: classes.option,
                    }}
                    multiple
                    size="small"
                    onChange={(e, newVal) => onChange("languages", newVal)}
                    autoHighlight
                    defaultValue={languages}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weekly_digest_ok}
                      name="weekly_digest_ok"
                      color="primary"
                      value="1"
                      onChange={onChange}
                    />
                  }
                  label="Weekly Digest"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.all,
  loading: state.users.loading,
  error: state.users.error,
  current: state.users.current,
  open: state.users.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: () => dispatch(getUsers()),
  clearErrorUsers: () => dispatch(clearErrorUsers()),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setOpenUser: (should) => dispatch(setOpenUser(should)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  addUser: (user) => dispatch(addUser(user)),
  updateUser: (user) => dispatch(updateUser(user)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
