import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { connect } from "react-redux";

import { getAlertSubaccounts } from "../actions/related";

import EnhancedTable from "../shared/components/UIElements/EnhancedTable";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import { Breadcrumbs, Grid, Link } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";

import axios from "axios";
import "react-calendar/dist/Calendar.css";
import { Helmet } from "react-helmet";
import { getSources } from "../actions/sources";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import AlertLog from "./AlertLog";

const PREFIX = "AlertLogs";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const AlertLogs = ({
  error,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
}) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [issuesLogs, setIssuesLogs] = useState([]);
  const [alertsAccounts, setAlertsAccounts] = useState({});

  const [open, setOpen] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState({});

  const mainAccounts = [
    { email: "mmadameu@gmail.com" },
    { email: "ilgammadmena@gmail.com" },
    { email: "mmadasiapacific@gmail.com" },
    { email: "govmmadsou01@gmail.com" },
    { email: "blankones2020@gmail.com" },
    { email: "alerta.terapia@gmail.com" },
  ];

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();

    const fetchData = async () => {
      setLoading(true);
      const result = await axios(
        process.env.REACT_APP_BACKEND_URL +
          "/alerts/log/" +
          date.toISOString().split("T")[0]
      );
      setLoading(false);
      setLogs(result.data.correct);
      setIssuesLogs(result.data.issues);
      setAlertsAccounts(result.data.byEmail);
    };

    fetchData();
  }, [date]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
    setSelectedArticle({});
  }

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logs - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} />
      <AlertLog
        selectedArticle={selectedArticle}
        open={open || false}
        onClose={handleClose}
      />
      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Configuration
            </Link>
            <Link color="textPrimary" href="/alert-logs" aria-current="page">
              Alet Logs
            </Link>
          </Breadcrumbs>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <Calendar onChange={setDate} value={date} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: "10px" }}>
                <strong>Date:</strong> {date.toISOString().split("T")[0]}
                <br />
                <strong>Number of news collected:</strong>{" "}
                {logs.length + issuesLogs.length}
                <br />
                <strong>Number of news with associated source:</strong>{" "}
                {logs.length}
                <br />
                <strong>Number of news without source:</strong>{" "}
                {issuesLogs.length}
                <br />
                <br />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
              <br />
              <br />
              <Paper style={{ padding: "10px" }}>
                <strong>Entries by account:</strong> <br />
                <br />
                <Grid container spacing={2}>
                  {[
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 0),
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 1),
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 2),
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 3),
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 4),
                    Object.keys(alertsAccounts)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .filter((_, index) => index % 6 === 5),
                  ].map((column, index) => (
                    <Grid item xs={2} key={index}>
                      {column.map((alert, index) => (
                        <div
                          style={{
                            ...(!alertsAccounts[alert] && { color: "red" }),
                          }}
                        >
                          {mainAccounts.find((a) => a.email === alert) ? (
                            <strong>
                              {alert.split("@")[0]}: {alertsAccounts[alert]}
                            </strong>
                          ) : (
                            <>
                              {alert.split("@")[0]}: {alertsAccounts[alert]}
                            </>
                          )}
                        </div>
                      ))}
                    </Grid>
                  ))}
                </Grid>{" "}
              </Paper>
            </Grid>
          </Grid>
          <br />
          <br />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts,
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertLogs);
