import {
  Autocomplete,
  Button,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, openFile, truncateName } from "../../../utils/functions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import YearPicker from "../YearPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";
import EyeIcon from "../Icons/EyeIcon";
import UploadIcon from "../Icons/UploadIcon";
import DeleteFileModal from "../ConfirmationModal/DeleteFileModal";
import { deleteEntryFile } from "../../Api/EntryApi";
import TipTapContext from "../../../shared/context/tipTap-context";

const FileUpload = React.forwardRef(
  (
    {
      currentFile,
      setCurrentFile,
      fileName,
      sourceType,
      language,
      language2,
      setLanguage,
      setLanguage2,
      langCalled,
      lang,
      section,
      columnName,
      addingNewSource,
      currentSource,
      year,
      setYearValue,
      handleYearChange,
      handleReload,
      location,
      history,
    },
    currentFileRef
  ) => {
    const { data, error, isLoading } = useSWR(
      process.env.REACT_APP_BACKEND_URL + "/database/languages",
      fetcher
    );

    console.log("data: ", currentFile);

    const { ctxEntry, clearCtxEntryLanguage2 } = useContext(TipTapContext);

    const [addLanguage, setAddLanguage] = useState(false);
    const names = {
      oroff: "Original Official",
      or: "Original",
      tr: "Translation",
    };
    const keys = {
      oroff: "OFF",
      or: "OR",
      tr: "TR",
    };

    const [deleteData, setDeleteData] = useState({});

    useEffect(() => {
      const containingSource = ctxEntry.entries.sources.filter(
        (source) => source.order === currentSource.order
      )[0];
      if (containingSource) {
        console.log("containingSource: ", containingSource);
        const deletingData = {
          fileName,
          entry_id: ctxEntry.entries.id,
          source_id: containingSource.id,
          order: containingSource
            ? containingSource.order
            : ctxEntry.entries.sources.length,
          fileType: names[sourceType],
          fileKey: keys[sourceType],
          section,
          columnName,
        };
        setDeleteData(deletingData);
      }
    }, []);
    const deleteFileAction = () => {
      console.log("deleteFileAction called!!! -> ", deleteData);
      try {
        deleteEntryFile(deleteData)
          .then((res) => {
            console.log("res in delete file: ", res);
            handleReload(location, history);
          })
          .catch((err) => console.log("error133333: ", err));
      } catch (e) {
        throw new Error("Error deleting source");
      }
    };

    return (
      <div className="FileUpload__container">
        <div
          component="label"
          style={{
            maxHeight: "25px",
            minHeight: "25px",
            fontSize: "14px",
            paddingTop: "3px",
            minWidth: "30px",
            color: "#444",
            fontWeight: "bold",
            textUnderlineOffset: "2px",
            ...(!fileName &&
              (!currentFile || !currentFile.name) && {
                backgroundColor: "#FFF",
              }),
          }}
          onClick={(evt) => evt.preventDefault()}
        >
          <span
            style={{
              textDecoration: "underline",
            }}
          >
            {keys[sourceType]}
          </span>
          :
          <input
            hidden
            accept="application/pdf"
            type="file"
            ref={currentFileRef}
            onChange={(ev) => {
              setCurrentFile(ev.target.files[0]);
            }}
          />
        </div>

        {currentFile ? (
          <span className="SourceDownloadUploadFilename">
            {currentFile.name
              ? lang === "es"
                ? fileName
                : truncateName(currentFile.name)
              : ""}
          </span>
        ) : (
          <span className="SourceDownloadUploadFilename">
            {fileName
              ? lang === "es"
                ? fileName
                : truncateName(fileName)
              : ""}
          </span>
        )}

        {data && lang !== "es" && (
          <>
            <div className="yearPicker__container">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearPicker
                  disabled={lang === "es"}
                  name="year"
                  label="Year"
                  size="small"
                  value={year ? year[sourceType] : ""}
                  onChange={(value) => {
                    handleYearChange(sourceType, value && value["$y"]);
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="FileUpload__ac-container">
              {sourceType !== "tr" && (
                <>
                  <div className="FileUpload__languagesContainer">
                    <Autocomplete
                      disabled={lang === "es"}
                      sx={{
                        maxWidth: "120px",
                        width: "120px",
                        display: "inline-block",
                      }}
                      options={data.languages.filter(
                        (l) => !language2 || l.id !== language2
                      )}
                      disableClearable={true}
                      clearOnBlur={false}
                      size="small"
                      autoHighlight
                      //isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.name_en}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{ ...props.style, fontSize: "12px" }}
                        >
                          {option.name_en}
                        </li>
                      )}
                      onChange={(e, newVal) => {
                        setLanguage(newVal.id);
                      }}
                      value={
                        !addingNewSource
                          ? data.languages.find(
                              (delang) => delang?.id === language
                            )
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Language *"
                          variant="outlined"
                          size="small"
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                      )}
                    />

                    {addLanguage || language2 ? (
                      <>
                        <Autocomplete
                          sx={{
                            maxWidth: "120px",
                            width: "120px",
                            display: "inline-block",
                          }}
                          disabled={lang === "es"}
                          options={data.languages.filter(
                            (l) => l.id !== language
                          )}
                          disableClearable={true}
                          clearOnBlur={false}
                          size="small"
                          autoHighlight
                          // isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option.name_en}
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              style={{ ...props.style, fontSize: "12px" }}
                            >
                              {option.name_en}
                            </li>
                          )}
                          onChange={(e, newVal) => {
                            setLanguage2(newVal.id);
                          }}
                          value={
                            !addingNewSource
                              ? data.languages.find(
                                  (delang) => delang?.id === language2
                                )
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Language 2"
                              variant="outlined"
                              size="small"
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,

                                autoComplete: "off",
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                            />
                          )}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {addLanguage || language2 ? (
                    <Tooltip title={`Remove second language`}>
                      <IconButton
                        variant="contained"
                        size="small"
                        aria-label="close"
                        color="primary"
                        className="addRemoveLang__btn"
                        onClick={() => {
                          clearCtxEntryLanguage2(
                            currentSource["source-id"],
                            sourceType
                          );
                          setLanguage2(null);
                          setAddLanguage(false);
                        }}
                      >
                        <Icon fontSize="small">remove</Icon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add second language">
                      <IconButton
                        className="addRemoveLang__btn"
                        disabled={lang === "es"}
                        variant="contained"
                        size="small"
                        aria-label="close"
                        color="primary"
                        onClick={() => setAddLanguage(true)}
                      >
                        <Icon fontSize="small">add</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}

              <div className="uploadAction__btns">
                {lang === "en" && (
                  <Tooltip
                    title={`Upload ${names[sourceType].toLowerCase()} file`}
                  >
                    <span>
                      <IconButton
                        disabled={sourceType !== "tr" && !language}
                        component="label"
                        aria-label={`Upload ${names[
                          sourceType
                        ].toLowerCase()} file`}
                      >
                        <UploadIcon />
                        <input
                          hidden
                          accept="application/pdf"
                          type="file"
                          ref={currentFileRef}
                          onChange={(ev) => {
                            setCurrentFile(ev.target.files[0]);
                          }}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {fileName && (
                  <Tooltip
                    title={`View current ${names[
                      sourceType
                    ].toLowerCase()} source`}
                  >
                    <span>
                      <IconButton
                        aria-label="view"
                        onClick={(event) => openFile(event, fileName)}
                      >
                        <EyeIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {
                  <DeleteFileModal
                    deleteAction={deleteFileAction}
                    data={deleteData}
                  />
                }
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default FileUpload;
