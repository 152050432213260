import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setCurrentAreaJur } from "../actions/database";
import DbEntry from "./DbEntry";
import DbLayout from "./DbLayout";

import TreeMenu from "./Components/TreeMenu";
function DbDashboard() {
  const { entryTypeId, jurisdictionId, subjurisdictionId } = useParams();

  return (
    <DbLayout>
      <div
        style={{
          backgroundColor: "#fff", // A slight grey background for the Paper for a bit of depth
        }}
      >
        {entryTypeId && jurisdictionId ? (
          <>
            <DbEntry
              entryTypeId={entryTypeId}
              jurisdictionId={jurisdictionId}
              subjurisdictionId={subjurisdictionId}
            />
          </>
        ) : (
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#fff", // A slight grey background for the Paper for a bit of depth
              padding: "20px",
            }}
          >
            {" "}
            {/* Use elevation for shadow effect */}
            <Typography variant="h4" component="h1" gutterBottom>
              Intake Wizard - Dashboard
            </Typography>
            <Typography variant="subtitle1">
              Please choose an entry type and jurisdiction to begin
            </Typography>
            <TreeMenu />
          </Grid>
        )}
      </div>
    </DbLayout>
  );
}
const mapStateToProps = (state) => ({
  entryTypeId: state.database.entryTypeId,
  jurisdictionId: state.database.jurisdictionId,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentAreaJur: (areaId, jurId) =>
    dispatch(setCurrentAreaJur(areaId, jurId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DbDashboard);
