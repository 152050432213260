import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { connect } from "react-redux";
import { setFilters } from "../actions/sources";

const PREFIX = 'SourceFilter';

const classes = {
  fab: `${PREFIX}-fab`,
  extendedIcon: `${PREFIX}-extendedIcon`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`& .${classes.fab}`]: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },

  [`& .${classes.extendedIcon}`]: {
    marginRight: theme.spacing(1),
  }
}));

const SourceFilter = ({
  dispatch,
  filter,
  source_types,
  source_statuses,
  allLanguages,
  jurisdictions,
}) => {

  const [filterForm, setFilterForm] = useState(filter.filters);

  const onChange = (e, val) => {
    if (
      e === "source_type" ||
      e === "source_status" ||
      e === "jurisdiction" ||
      e === "languages"
    ) {
      setFilterForm({ ...filterForm, [e]: val });
    } else {
      setFilterForm({ ...filterForm, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(setFilters(filterForm));
  };

  return (
    <Root onSubmit={onSubmit}>
      <input type="submit" style={{ display: "none" }} />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={2}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            onChange={onChange}
            value={filterForm.name}
            className={classes.textField}
            variant="outlined"
            fullWidth
          />
        </Grid>{" "}
        <Grid item xs={12} md={2}>
          <TextField
            margin="dense"
            name="url"
            label="URL"
            onChange={onChange}
            value={filterForm.url}
            className={classes.textField}
            variant="outlined"
            fullWidth
          />
        </Grid>{" "}
        <Grid item xs={12} md={2}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={source_types}
            disableClearable={true}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            defaultValue={
              filterForm.source_type &&
              source_types.find((st) => st.id === filterForm.source_type.id)
            }
            size="small"
            onChange={(e, newVal) => onChange("source_type", newVal)}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Type"
                variant="outlined"
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={source_statuses}
            clearOnBlur={true}
            defaultValue={
              filterForm.source_status
                ? source_statuses.find(
                    (ss) => ss.id === filterForm.source_status.id
                  )
                : source_statuses.find((ss) => ss.id === 1)
            }
            disableClearable={true}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            size="small"
            onChange={(e, newVal) => onChange("source_status", newVal)}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={jurisdictions}
            clearOnBlur={true}
            defaultValue={
              filterForm.jurisdiction
                ? jurisdictions.find(
                    (sj) => sj.id === filterForm.jurisdiction.id
                  )
                : jurisdictions.find((sj) => sj.id === 1)
            }
            disableClearable={true}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            size="small"
            onChange={(e, newVal) => onChange("jurisdiction", newVal)}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Jurisdiction"
                variant="outlined"
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          {allLanguages && (
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%" }}
              options={allLanguages}
              disableClearable={true}
              classes={{
                option: classes.option,
              }}
              multiple
              size="small"
              onChange={(e, newVal) => onChange("languages", newVal)}
              autoHighlight
              defaultValue={filterForm.languages}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="outlined"
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "8px" }}
          >
            FILTER
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    sources: state.sources.sources,
    current: state.sources.current,
    filter: state.sources.filter,
    source_types: state.related.source_types,
    source_statuses: state.related.source_statuses,
    allLanguages: state.related.languages,
    jurisdictions: state.related.jurisdictions,
  };
};

export default connect(mapStateToProps)(SourceFilter);
