import React, { useEffect, useState } from "react";
import nicaragua from "./nicaragua.json";

export default function NewGeneralExplan() {
  const [currentEntry, setCurrentEntry] = useState("");
  const [sources, setSources] = useState([]);
  const [updatedSources, setUpdatedSources] = useState([]);
  const [previousExplanEn, setPreviousExplanEn] = useState("");

  useEffect(() => {
    setCurrentEntry(nicaragua);
    setSources(nicaragua.entries.sources);
    setUpdatedSources(nicaragua.entries.sources);
  }, [nicaragua]);

  useEffect(() => {
    const explanEn = currentEntry.explan_en;

    if (explanEn) {
      const asteriskCount = (explanEn.match(/\*/g) || []).length;
      if (asteriskCount % 2 === 0) {
        const newMatches = explanEn.match(/\*(.*?)\*/g) || [];
        const prevMatches = previousExplanEn.match(/\*(.*?)\*/g) || [];

        newMatches.forEach((entry, index) => {
          const extractedEntry = entry.replace(/\*/g, "");
          if (prevMatches[index] !== entry) {
            console.log(
              `New Entry: ${extractedEntry}, Position in original array: ${index}`
            );
          }
        });

        prevMatches.forEach((entry, index) => {
          const extractedEntry = entry.replace(/\*/g, "");
          if (newMatches[index] !== entry) {
            console.log(
              `Removed Entry: ${extractedEntry}, Position in original array: ${index}`
            );
          }
        });

        setPreviousExplanEn(explanEn);
      }
      /*
        let newSources = sources;
          newSources.splice(indexInOriginal, 0, {
            id: null,
            text: extractedEntry,
          });
        setSources(newSources);
*/
    }
  }, [currentEntry.explan_en]);

  const changeExplan = (e) => {
    setCurrentEntry({ ...currentEntry, explan_en: e.target.value });
  };
  return (
    <div>
      {currentEntry && (
        <textarea
          style={{
            border: "1px solid black",
            backgroundColor: "white",
            overflow: "auto",
            height: "200px",
            width: "80%",
          }}
          onChange={changeExplan} // handle innerHTML change
        >
          {currentEntry.explan_en}
        </textarea>
      )}
      {sources &&
        sources.map((source, index) => {
          return (
            <div>
              <div>
                {index} {source.id || "NEW"}
              </div>
            </div>
          );
        })}
    </div>
  );
}
