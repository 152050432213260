const initialState = {
  entryTypeId: null,
  jurisdictionId: null,
};

const database = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_AREA_JUR":
      return { ...action.payload };

    default:
      return state;
  }
};

export default database;
