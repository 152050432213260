const initialState = {
  en: 0,
  es: 0,
};

// Action Types
const SET_EN = "SET_EN";
const SET_ES = "SET_ES";

// Action Creators
export const setEn = (number, columnName = "") => ({
  type: SET_EN,
  payload: { number, columnName },
});

export const setEs = (number, columnName = "") => ({
  type: SET_ES,
  payload: { number, columnName },
});

const sourcesValidation = (state = initialState, action) => {
  console.log("action: ", action);
  switch (action.type) {
    case SET_EN:
      return {
        ...state,
        en: action.payload.number,
        [action.payload.columnName + "_en"]: action.payload.number,
      };
    case SET_ES:
      return {
        ...state,
        es: action.payload.number,
        [action.payload.columnName + "_es"]: action.payload.number,
      };
    default:
      return state;
  }
};

export default sourcesValidation;
