import React, { useContext, useEffect, useState } from "react";
import TipTapContext from "../../../../../shared/context/tipTap-context";
import { deleteEntryFootnote } from "../../../../Api/EntryApi";
import FootnoteMark from "./FootnoteMark";
const FootnoteMarksRenderer = ({
  addingNewFootnote,
  editor,
  getMarks,
  currentFootnote,
  changeCurrentFootnote,
  updateFootnotesNumber,
  lang,
  columnName,
  section,
}) => {
  const [theElements, setElements] = useState([]);
  const { ctxEntry } = useContext(TipTapContext);

  const deleteFootnote = (footnote) => {
    console.log("deleteFootnote runs!: ", footnote);
    const {
      id,
      order,
      lang,
      entry_id, // redundant
    } = footnote;

    const { explan_en, explan_es } = ctxEntry;

    const handleReload = () => {
      window.location.reload();
    };

    try {
      deleteEntryFootnote({
        id,
        entry_id,
        order,
        lang,
        explan_en,
        explan_es,
        columnName,
      })
        .then((res) => {
          console.log("deleteEntryFootnote -> res: ", res);
          handleReload();
        })
        .catch((err) => console.log("error133333: ", err));
    } catch (e) {
      throw new Error("Error deleting footnote");
    }
  };

  useEffect(() => {
    if (editor) {
      const theMarks = getMarks(editor, "customFootnote");

      if (theMarks.length) {
        updateFootnotesNumber(theMarks.length);
      }

      const elements = theMarks.map((theFootnote, index) => {
        return (
          <FootnoteMark
            key={`mark${index + 1}`}
            index={index}
            theFootnote={theFootnote}
            lang={lang}
            changeCurrentFootnote={changeCurrentFootnote}
            section={section}
            columnName={columnName}
          />
        );
      });

      setElements(elements);
    }
  }, [editor, addingNewFootnote, currentFootnote, ctxEntry]);

  return <div className="renderFootnoteMarks">{theElements}</div>;
};

export default FootnoteMarksRenderer;
