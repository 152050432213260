import {
  Autocomplete,
  Button,
  DialogActions,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  TextField
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import React, { Fragment, useEffect, useState } from "react";

const PREFIX = 'TriggerForm';

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  }
}));

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

function TriggerForm(props) {
  const {
    newTrigger,
    setNewTrigger,
    type,
    tags,
    jurs,
    tiers,
    addTierTriggers,
  } = props;

  const [newTags, setNewTags] = useState(null);
  const [iniTags, setIniTags] = useState([]);

  const [form, setForm] = useState({ tier: 2 });



  useEffect(() => {
    setForm({ ...form, ...newTrigger });
  }, [newTrigger]);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setNewTrigger({});
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    addTierTriggers(form.tier, form.text);
    handleClose();
  };
  let forms = {
    tier: (
      <Fragment>
        <Paper style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                select
                name="tier"
                value={form.tier}
                variant="outlined"
                margin="dense"
                onChange={onChange}
                fullWidth
              >
                {[2, 4, 5].map((tier) => (
                  <MenuItem key={tier} value={tier}>
                    <ListItemText primary={"Tier " + tier} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                style={{
                  marginTop: "7px",
                  marginLeft: "8px",
                  paddingRight: "8px",
                  height: "16px",
                }}
                name="text"
                label="Trigger - separate multiple with ;"
                onChange={onChange}
                value={form.text}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    ),
  };
  return (
    <StyledDialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={newTrigger.type || false}
    >
      {forms[newTrigger.type]}

      {tags && tags.length > 0 && (
        <Paper style={{ padding: "10px" }}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={tags}
            disableClearable={false}
            clearOnBlur={true}
            classes={{
              option: classes.option,
            }}
            multiple
            defaultValue={iniTags}
            size="small"
            onChange={(e, newVal) => onChange(newVal)}
            getOptionLabel={(option) => option.tag}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.tag}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                variant="outlined"
                autoFocus
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>{" "}
        <Button
          onClick={(e) => handleSave()}
          variant="contained"
          color="primary"
        >
          SAVE
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default TriggerForm;
