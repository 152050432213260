import React, { useEffect, useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  deleteUser,
  getUsers,
  clearErrorUsers,
  setCurrentUser,
  clearCurrentUser,
  setBypassIoUsers,
} from "../actions/users";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import UserForm from "./UserForm";
import AddUserBtn from "./AddUserBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Icon, Breadcrumbs, Link } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";
import moment from "moment";

const PREFIX = "Users";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const Users = ({
  users,
  users_logs,
  loading,
  error,
  current,
  bypassIo,
  clearErrorUsers,
  setCurrentUser,
  clearCurrentUser,
  setBypassIoUsers,
  deleteUser,
  getUsers,
  dispatch,
}) => {
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if ((!users || users.length === 0) && !error) getUsers();
    if (!loading) {
      subscribeTo("usersChanged", (err, data) => {
        if (!bypassIo) getUsers();
        else {
          getUsers();
          setBypassIoUsers(false);
        }
      });
      return () => {
        subscribeTo("usersChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, user) => {
    confirm({
      description: `Are you sure you want to delete the user "${user.email}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => deleteUser(user.id));
  };

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Users - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorUsers} />
      <React.Fragment>
        <CssBaseline />
        <UserForm current={current} clearCurrentUser={clearCurrentUser} />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Configuration
            </Link>
            <Link color="textPrimary" href="/users" aria-current="page">
              Users
            </Link>
          </Breadcrumbs>

          {users.length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>Name</TableCell>

                    <TableCell>Accepted</TableCell>
                    <TableCell>Rejected</TableCell>
                    <TableCell>Last Login</TableCell>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((day) => (
                      <TableCell style={{ textAlign: "center" }}>
                        Active
                        <br />
                        {moment().subtract(day, "days").format("D/M")}
                      </TableCell>
                    ))}

                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow
                      key={row.name}
                      style={{
                        ...(row.is_disabled && {
                          textDecoration: "line-through",
                        }),
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      <TableCell>{row.accepted}</TableCell>
                      <TableCell>{row.rejected}</TableCell>
                      <TableCell>
                        {row.last_login &&
                          new Date(row.last_login).toUTCString()}
                      </TableCell>
                      {users_logs &&
                        [0, 1, 2, 3, 4, 5, 6, 7].map((day) => (
                          <TableCell style={{ textAlign: "center" }}>
                            {users_logs[
                              moment()
                                .subtract(day, "days")
                                .format("YYYY-MM-DD")
                            ] &&
                            parseInt(
                              users_logs[
                                moment()
                                  .subtract(day, "days")
                                  .format("YYYY-MM-DD")
                              ][row.id]
                            ) > 0
                              ? String(
                                  Math.floor(
                                    moment
                                      .duration(
                                        users_logs[
                                          moment()
                                            .subtract(day, "days")
                                            .format("YYYY-MM-DD")
                                        ][row.id],
                                        "minutes"
                                      )
                                      .asHours()
                                  )
                                ).padStart(2, "0") +
                                ":" +
                                String(
                                  Math.floor(
                                    moment
                                      .duration(
                                        users_logs[
                                          moment()
                                            .subtract(day, "days")
                                            .format("YYYY-MM-DD")
                                        ][row.id],
                                        "minutes"
                                      )
                                      .minutes()
                                  )
                                ).padStart(2, "0")
                              : "-"}
                          </TableCell>
                        ))}
                      <TableCell align="right">
                        {row.id && (
                          <Fragment>
                            <a onClick={() => setCurrentUser(row)}>
                              <Icon>edit</Icon>
                            </a>{" "}
                            <a onClick={(e) => onClickDelete(confirm, row)}>
                              <Icon color="secondary">delete</Icon>
                            </a>
                          </Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <AddUserBtn />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.all,
  users_logs: state.users.users_logs,
  loading: state.users.loading,
  error: state.users.error,
  current: state.users.current,
  bypassIo: state.users.bypassIo,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: () => dispatch(getUsers()),
  clearErrorUsers: () => dispatch(clearErrorUsers()),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  setBypassIoUsers: (bypass) => dispatch(setBypassIoUsers(bypass)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
