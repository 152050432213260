import { MuiChipsInput } from "mui-chips-input";
import React from "react";

export default function CustomChipInput({
  label,
  defaultValue,
  fullWidth,
  onChange,
}) {
  return (
    <MuiChipsInput
      label={label}
      value={defaultValue}
      onChange={onChange}
    />
  );
}
