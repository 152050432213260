import { Mark, mergeAttributes } from '@tiptap/react'

export const SourceReference = Mark.create({
  name: 'sourceReference',

  defaultOptions: {
    HTMLAttributes: {}
  },

  addAttributes() {
    return {
      "data-ref-id": {
        default: null
      },
      "data-order": {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'custom-source-reference',
        attrs: {
          class: "sourceReference"
        }
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "custom-source-reference",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ]
  },

  addCommands() {
    return {
      setSourceReference:
        () =>
          ({ commands }) => {
            return commands.setMark(this.name);
          },
      toggleSourceReference:
        () =>
          ({ commands }) => {
            return commands.toggleMark(this.name);
          },
      unsetSourceReference:
        () =>
          ({ commands }) => {
            return commands.unsetMark(this.name);
          },
    };
  },
})