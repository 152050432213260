import React, { useEffect, useState } from "react";
import TipTapContext from "../context/tipTap-context";
import { deleteEntryFootnote, deleteEntrySource } from "../../db/Api/EntryApi";
import { useHistory, useLocation } from "react-router-dom";

const TipTapProvider = ({ children }) => {
  const [ctxData, setData] = useState({});
  const [ctxEntry, setEntry] = useState({});
  const [ctxSource, setSource] = useState({});
  const [ctxColumn, setColumn] = useState({});
  const [ctxLang, setLang] = useState({});
  const [ctxCountryCode, setCountryCode] = useState("");
  const [ctxJurisdictions, setJurisdictions] = useState([]);
  const [pendingTransactionData, setPendingTransactionData] = useState(null);
  const [addingNewSource, setAddingNewSource] = useState(false);
  const [addingNewFootnote, setAddingNewFootnote] = useState(false);
  const [saveChanges, setSaveChanges] = useState({});
  const location = useLocation();
  const history = useHistory();

  const changeCtxJurisdictions = (list) => {
    setJurisdictions(list);
  };

  const changeData = (data) => {
    setData(data);
  };

  const changeColumn = (data) => {
    setColumn(data);
  };

  const changeEntry = (entry) => {
    console.log("ENTRYYY", entry);
    setEntry(entry);
  };

  const changeLang = (lang) => {
    setLang(lang);
  };

  const deleteSourceAction = (source, entry, section, lang, columnName) => {
    console.log("deleteSource runs entry!: ", entry);
    console.log("deleteSource runs!: ", source);

    const { id, order, text } = source;
    const { explan_en, explan_es } = entry;
    const sources = entry.entries.sources;

    console.log("id!: ", id);
    console.log("order!: ", order);
    console.log("explan_en!: ", explan_en);
    console.log("explan_es!: ", explan_es);
    console.log("text!: ", text);

    const dbSource = sources.filter((src) => id === src.id)[0];

    console.log("dbSource!: ", dbSource);

    const handleReload = (location, history) => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("tab", ctxColumn);
      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
      window.location.reload();
    };

    try {
      deleteEntrySource({
        sourcesDeleteId: id,
        entry_id: entry.entries.id,
        order,
        columnName,
        section,
        lang,
      })
        .then((res) => {
          console.log("deleteEntrySource -> res: ", res);
          handleReload(location, history);
        })
        .catch((err) => {
          console.log("error133333: ", err);
        });
    } catch (e) {
      throw new Error("Error deleting source");
    }
  };

  const deleteFootnoteAction = (footnote, entry, section, lang, columnName) => {
    const { id, order } = footnote;
    const { explan_en, explan_es } = entry;

    const handleReload = (location, history) => {
      window.location.reload();
    };

    try {
      deleteEntryFootnote({
        id,
        entry_id: footnote.entry_id,
        order,
        lang,
        columnName,
        section,
      })
        .then((res) => {
          handleReload(location, history);
        })
        .catch((err) => {
          console.log("error133333: ", err);
        });
    } catch (e) {
      throw new Error("Error deleting footnote");
    }
  };

  const clearCtxEntryLanguage2 = (sourceId, sourceType) => {
    const modifiedEntry = { ...ctxEntry };
    const sourceProperty =
      sourceType === "or"
        ? "original_language_id2"
        : sourceType === "oroff"
        ? "original_official_language_id2"
        : undefined;

    if (!sourceProperty) {
      console.log("something went wrong!!!");
      return;
    }

    let sourceFound = false;
    for (let source of modifiedEntry.entries.sources) {
      if (source.id === sourceId) {
        sourceFound = true;
        source[sourceProperty] = 0;
        break;
      }
    }

    if (!sourceFound) {
      console.log("no source found!");
      return;
    }

    setEntry(modifiedEntry);
  };

  const changeCtxSource = (source) => {
    setSource(source);
  };

  useEffect(() => {
    console.log("ctxEntryyyyyyyyyyyyy", ctxEntry);
    if (ctxEntry && ctxEntry.entries) {
      const jurisdictionId = ctxEntry.entries.jurisdiction_id;
      const theJurisdiction = ctxJurisdictions.filter(
        (jur) => jur.id === jurisdictionId
      );
      if (theJurisdiction.length) {
        setCountryCode(theJurisdiction[0].a2_code);
      }
    }
  }, [ctxEntry, ctxJurisdictions]);

  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);

  const [snackSuccessMessage, setSnackSuccessMessage] = useState("Success");

  const showSuccessSnack = () => {
    setOpenSuccessSnack(true);
    setTimeout(() => setOpenSuccessSnack(false), 3000);
  };
  const closeSuccessSnack = () => setOpenSuccessSnack(false);

  const [openErrorSnack, setOpenErrorSnack] = useState(false);

  const [snackErrorMessage, setSnackErrorMessage] = useState("Success");

  const showErrorSnack = () => {
    setOpenErrorSnack(true);
    setTimeout(() => setOpenErrorSnack(false), 3000);
  };
  const closeErrorSnack = () => setOpenErrorSnack(false);

  return (
    <TipTapContext.Provider
      value={{
        ctxData,
        ctxEntry,
        ctxLang,
        ctxCountryCode,
        ctxSource,
        ctxColumn,
        pendingTransactionData,
        setPendingTransactionData,
        addingNewSource,
        setAddingNewSource,
        addingNewFootnote,
        setAddingNewFootnote,
        openSuccessSnack,
        showSuccessSnack,
        snackSuccessMessage,
        setSnackSuccessMessage,
        closeSuccessSnack,
        openErrorSnack,
        showErrorSnack,
        snackErrorMessage,
        setSnackErrorMessage,
        closeErrorSnack,
        changeCtxSource,
        changeData,
        changeEntry,
        changeLang,
        changeColumn,
        clearCtxEntryLanguage2,
        deleteFootnoteAction,
        deleteSourceAction,
        changeCtxJurisdictions,
        saveChanges,
        setSaveChanges,
      }}
    >
      {children}
    </TipTapContext.Provider>
  );
};

export default TipTapProvider;
