import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Grid, Icon, TextField } from "@mui/material";
import moment, { utc } from "moment";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { setFilters } from "../actions/articles";

import { getUserLanguages } from "../actions/users";

import Autocomplete from "@mui/material/Autocomplete";
import CustomDatePicker from "../utils/CustomDatePicker";
import { consultantTiers, daysWithConsultantTiers } from "../utils/functions";

const PREFIX = "InboxFilters";

const classes = {
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "13.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const rainbowColors = [
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
];

const inboxTabs = ["MOU", "CSO", "DOG", "IHR", "OTHER"];
export const inboxTabsIds = {
  MOU: { ids: [1], color: "#4eb3d3" },
  CSO: { ids: [2], color: "#7bccc4" },
  GOV: { ids: [3, 8, 9, 10, 11], color: "#7bccc4" },
  DOG: { ids: [7, 14], color: "#a8ddb5" },
  IHR: { ids: [4], color: "#ccebc5" },
  OTHER: { ids: [5, 6, 12, 13], color: "#f0f9e8" },
};
const tierTabs = ["#2c7bb6", "#abd9e9", "#ffffbf", "#fdae61", "#d7191c"];

const shiftPickerDateToTZDate = (pickerDateMoment) => {
  let pickerDate = pickerDateMoment.toDate();
  let pickerOffset = pickerDate.getTimezoneOffset();
  let utcDate = new Date();
  utcDate.setTime(pickerDate.getTime() - pickerOffset * 60000);

  let tzOffset = moment.tz(pickerDate, "UTC").utcOffset();
  let tzDate = new Date();
  tzDate.setTime(utcDate.getTime() - tzOffset * 60000);
  return moment(tzDate);
};

const shiftTzDateToPickerDate = (tzDateMD) => {
  let tzDate = tzDateMD._isAMomentObject ? tzDateMD.toDate() : tzDateMD;

  let tzUTCOffset = moment.tz(tzDate, "UTC").utcOffset();
  let utcDate = new Date();
  utcDate.setTime(tzDate.getTime() + tzUTCOffset * 60000);

  let pickerDate = new Date();
  let pickerOffset = pickerDate.getTimezoneOffset();
  pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);
  return moment(pickerDate);
};

function InboxFilters({
  filter,
  setFilters,
  date,
  languages,
  setDate,
  source_types,
  setPage,
  inbox_articles,
  getUserLanguages,
  user_languages,
  show_articles,
  loading,
  tier,
  setTier,
}) {
  const [filtersTmp, setFiltersTmp] = React.useState({
    header: "",
    tier: 2,
    sou: "MOU",
    languages: [],
  });

  const [dateOpen, setDateOpen] = useState(false);

  useEffect(() => {
    if (!user_languages || !user_languages.length) getUserLanguages();
    else {
      setFiltersTmp({
        ...filter.filters,
        languages: user_languages,
      });
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filtersTmp,
        ...(filtersTmp.header && { tier: null }),
      });
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersTmp.header]);

  useEffect(() => {
    setPage(1);
  }, [filter.filters.sou, filter.filters.tier]);

  useEffect(() => {
    setFiltersTmp({
      ...filter.filters,
      languages: user_languages,
    });
  }, [user_languages]);

  useEffect(() => {
    setFilters({
      ...filter.filters,
      languages: filtersTmp.languages,
    });
  }, [filtersTmp.languages]);

  return (
    <Root>
      {(inbox_articles.length > 0 ||
        (!loading && inbox_articles.length === 0)) && (
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="MMMM DD"
                    slotProps={{
                      textField: {
                        size: "small",
                        onClick: () => setDateOpen(true),
                      },
                    }}
                    value={shiftTzDateToPickerDate(date)}
                    onChange={(date) => setDate(shiftPickerDateToTZDate(date))}
                    inputVariant="outlined"
                    margin="dense"
                    open={dateOpen}
                    onClose={() => setDateOpen(false)}
                    sx={{ backgroundColor: "white", marginTop: "8px" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            {languages && (
              <Autocomplete
                id="country-select-demo"
                style={{ width: "100%" }}
                options={languages}
                disableClearable={false}
                classes={{
                  option: classes.option,
                }}
                multiple
                size="small"
                onChange={(e, newVal) =>
                  setFiltersTmp({ ...filter.filters, languages: newVal })
                }
                autoHighlight
                defaultValue={filtersTmp.languages || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => value.id === option.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Languages"
                    variant="outlined"
                    margin="dense"
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{ textAlign: "right", paddingTop: "8px" }}
          >
            <TextField
              variant="outlined"
              size="small"
              style={{
                width: "84%",
                backgroundColor: "white",
              }}
              value={filtersTmp.header || ""}
              placeholder="Search..."
              onChange={(e) =>
                setFiltersTmp({ ...filter.filters, header: e.target.value })
              }
            />
            <Button
              variant="contained"
              style={{ width: "10%", marginLeft: "10px" }}
              color="primary"
            >
              <Icon>search</Icon>
            </Button>
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
                {tierTabs.map((color, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      marginTop: "2px",
                      backgroundColor: color,
                      color: "rgba(0, 0, 0, 0.87)",
                      ...(filter.filters.tier === index + 1 && {
                        border: "2px solid black",
                      }),
                      textTransform: "unset",
                    }}
                    onClick={(e) =>
                      setFilters({
                        ...filter.filters,
                        tier: index + 1,
                        sou: index + 1 === 1 ? undefined : "MOU",
                      })
                    }
                  >
                    Tier {index + 1} (
                    {
                      inbox_articles.filter(
                        (a) =>
                          a.tier === index + 1 &&
                          (!filter.filters.languages ||
                            !filter.filters.languages.length ||
                            filter.filters.languages.find(
                              (l) => l.id === a.detected_language
                            ))
                      ).length
                    }
                    )
                  </Button>
                ))}
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                {inboxTabs.map((souFilter, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    disabled={filter.filters.tier === 1}
                    style={{
                      marginLeft: "10px",
                      marginTop: "2px",
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.87)",
                      textTransform: "unset",
                      ...(filter.filters.sou === souFilter && {
                        border: "2px solid black",
                      }),
                      backgroundColor: inboxTabsIds[souFilter].color,
                    }}
                    onClick={(e) =>
                      setFilters({ ...filter.filters, sou: souFilter })
                    }
                  >
                    {souFilter} (
                    {
                      inbox_articles.filter(
                        (a) =>
                          (!filter.filters.tier ||
                            a.tier === filter.filters.tier) &&
                          inboxTabsIds[souFilter].ids.indexOf(
                            a.source.source_type_id
                          ) !== -1 &&
                          (!filter.filters.languages ||
                            !filter.filters.languages.length ||
                            filter.filters.languages.find(
                              (l) => l.id === a.detected_language
                            ))
                      ).length
                    }
                    )
                  </Button>
                ))}
              </Grid>
              {filter.filters.tier === 2 &&
                daysWithConsultantTiers.indexOf(
                  utc(date).format("YYYY-MM-DD")
                ) !== -1 && (
                  <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
                    {consultantTiers(utc(date).format("YYYY-MM-DD")).map((user, index) => (
                      <Button
                        key={user.pos}
                        variant="contained"
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginTop: "2px",
                          backgroundColor: "#ccc",
                          color: "rgba(0, 0, 0, 0.87)",
                          ...(filter.filters.consultantTier === user.id && {
                            border: "2px solid black",
                          }),
                          textTransform: "unset",
                        }}
                        onClick={(e) =>
                          setFilters({
                            ...filter.filters,
                            consultantTier: user.id,
                          })
                        }
                      >
                        {user.name} (
                        {
                          inbox_articles.filter(
                            (a) =>
                              a.tier === 2 &&
                              parseInt(
                                String(a.consultant_original_id || a.id).slice(
                                  -2
                                )
                              ) >=
                                0 + user.pos * 25 &&
                              parseInt(
                                String(a.consultant_original_id || a.id).slice(
                                  -2
                                )
                              ) <
                                25 + user.pos * 25 &&
                              (!filter.filters.languages ||
                                !filter.filters.languages.length ||
                                filter.filters.languages.find(
                                  (l) => l.id === a.detected_language
                                ))
                          ).length
                        }
                        )
                      </Button>
                    ))}
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Root>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  filter: state.articles.filter,
  inbox_articles: state.articles.inbox,
  user_languages: state.users.user_languages,
  show_articles: state.articles.filter.active
    ? state.articles.filter.result
    : state.articles.inbox,
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filters) => dispatch(setFilters(filters)),
  getUserLanguages: () => dispatch(getUserLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxFilters);
