import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TipTap from "./TipTap/TipTap";
import TipTapContext from "../../shared/context/tipTap-context";

const config = {};

export default function ExplanTabPanel({
  columnName,
  formData,
  dataEntry,
  mutate,
  addingNewFootnote,
  setAddingNewFootnote,
  section,
}) {
  const [activeInDrawer, setActiveInDrawer] = useState("source");
  const [visible, setVisible] = useState(false);
  

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {config[columnName]}
        </Typography>
      </Grid>
      {formData[columnName + "_en"] || visible ? (
        <>
          <Grid item xs={12} className="TipTap__box">
            <Typography variant="h6">English version</Typography>
            <TipTap
              content={formData[columnName + "_en"]}
              entry={
                dataEntry && dataEntry.entry && dataEntry.entry.length
                  ? dataEntry.entry[0]
                  : ""
              }
              section={section}
              mutate={mutate}
              hasSources={true}
              lang={"en"}
              columnName={columnName}
              activeInDrawer={activeInDrawer}
              setActiveInDrawer={setActiveInDrawer}
              addingNewFootnote={addingNewFootnote}
              setAddingNewFootnote={setAddingNewFootnote}
            />
          </Grid>
          <Grid item xs={12} className="TipTap__box">
            <Typography variant="h6" gutterBottom>
              Spanish version
            </Typography>
            <TipTap
              content={formData[columnName + "_es"] || ""}
              entry={
                dataEntry && dataEntry.entry && dataEntry.entry.length
                  ? dataEntry.entry[0]
                  : ""
              }
              mutate={mutate}
              hasSources={true}
              lang="es"
              columnName={columnName}
              section={section}
              activeInDrawer={activeInDrawer}
              setActiveInDrawer={setActiveInDrawer}
              addingNewFootnote={addingNewFootnote}
              setAddingNewFootnote={setAddingNewFootnote}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} className="TipTap__box">
            Currently, there is no content visible on the front end for this
            entry.{" "}
            <a
              onClick={() => setVisible(true)}
              style={{ textDecoration: "underline" }}
            >
              Would you like to add new content and display information?
            </a>
          </Grid>
        </>
      )}
    </Grid>
  );
}
