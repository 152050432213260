import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getLanguages } from "../actions/related";

import { Grid, Icon, TextField } from "@mui/material";

import { setFilters } from "../actions/articles";

import Autocomplete from "@mui/material/Autocomplete";

const PREFIX = "UntrackedInboxFilters";

const classes = {
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "13.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const rainbowColors = [
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
];

const inboxTabs = ["MOU", "CSO", "DOG", "GOV", "IHR", "OTHER"];

function InboxFilters({
  filter,
  setFilters,
  date,
  setDate,
  source_types,
  setPage,
  getLanguages,
  languages,
  jurisdictions,
  getJurisdictions,
  tier,
  setTier,
  subtier,
  setSubtier,
  loading,
  show_articles,
}) {
  const [showN, setShowN] = React.useState(false);
  const [filtersTmp, setFiltersTmp] = React.useState({
    header: "",
  });

  useEffect(() => {
    if (!languages || !languages.length) getLanguages();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    const delayDebounceFn = setTimeout(() => {
      setFilters(filtersTmp);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersTmp.header]);

  useEffect(() => {
    setPage(1);
  }, [filter.filters.sou]);

  useEffect(() => {
    if (loading) setShowN(false);
    else setShowN(true);
  }, [loading]);

  const tierTabs = [
    "#2c7bb6",
    "#abd9e9",
    "#ffffbf",
    "#fdae61",
    "#FF6600",
    "#CC3333",
    "#CC3300",
    "#FF0033",
  ];

  return (
    <Root>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          {tierTabs.map((color, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={(e) => {
                setShowN(false);
                setFilters({ ...filter, show_number: false });
                setTier(index + 1);
              }}
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                marginTop: "10px",
                backgroundColor: color,
                color: "rgba(0, 0, 0, 0.87)",

                ...(tier === index + 1 && {
                  border: "2px solid black",
                }),
                textTransform: "unset",
              }}
            >
              Tier {index + 1}
            </Button>
          ))}
          {/*<Button
            key={0}
            variant="contained"
            onClick={(e) => setTier(0)}
            style={{
              marginRight: "10px",
              fontWeight: "bold",
              marginTop: "10px",
              backgroundColor: "whitesmoke",
              ...(tier === 0 && {
                border: "2px solid black",
              }),
              textTransform: "unset",
            }}
          >
            ALL
          </Button>*/}
        </Grid>
        <Grid item xs={12} md={3}>
          {jurisdictions && jurisdictions.length > 0 && (
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%", paddingRight: "5px" }}
              options={jurisdictions
                .filter((j) => j.tlds)
                .map((j) => ({ id: j.tlds, name: j.name }))}
              disableClearable={false}
              classes={{
                option: classes.option,
              }}
              multiple
              size="small"
              onChange={(e, newVal) =>
                setFilters({ ...filter.filters, tlds: newVal })
              }
              autoHighlight
              defaultValue={filter.tlds}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estimated CONs"
                  variant="outlined"
                  margin="dense"
                  style={{ backgroundColor: "white" }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {languages && (
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%" }}
              options={languages}
              disableClearable={false}
              classes={{
                option: classes.option,
              }}
              multiple
              size="small"
              onChange={(e, newVal) =>
                setFilters({ ...filter.filters, languages: newVal })
              }
              autoHighlight
              defaultValue={filter.languages}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="outlined"
                  margin="dense"
                  style={{ backgroundColor: "white" }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
        </Grid>



        {tier && tier <= 5 && (
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "left", marginTop: "8px" }}
          >
            {jurisdictions &&
              jurisdictions.length > 0 &&
              jurisdictions
                .filter((jur) => jur.class === tier && jur.n_usi > 0)
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((jur, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    onClick={(e) => {
                      setPage(1);
                      if (tier <= 5) setSubtier(jur.tlds);
                    }}
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      backgroundColor: tierTabs[tier - 1],
                      color: "rgba(0, 0, 0, 0.87)",

                      ...(subtier === jur.tlds && {
                        border: "2px solid black",
                      }),
                      textTransform: "unset",
                    }}
                  >
                    {jur.name} ({jur.n_usi})
                  </Button>
                ))}
          </Grid>
        )}
        {tier && tier > 5 && tier <= 8 && (
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "left", marginTop: "8px" }}
          >
            {languages &&
              languages.length > 0 &&
              languages
                .filter(
                  (lang) =>
                    lang.priority === tier - 5 && (lang.n_usi > 0 || tier >= 6)
                )
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((lang, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    onClick={(e) => {
                      setPage(1);
                      setSubtier(lang.id);
                    }}
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      backgroundColor: tierTabs[tier - 1],
                      ...(subtier === lang.id && {
                        border: "2px solid black",
                      }),
                      textTransform: "unset",
                    }}
                  >
                    {lang.name} {tier !== 8 && <>({lang.n_usi})</>}
                  </Button>
                ))}
          </Grid>
        )}
      </Grid>
    </Root>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  filter: state.articles.filter,
  languages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filters) => dispatch(setFilters(filters, "_USI")),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxFilters);
