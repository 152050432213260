import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CssBaseline from "@mui/material/CssBaseline";
import {
  addExclusionRule,
  clearCurrentExclusionRule,
  clearErrorExclusionRules,
  deleteExclusionRule,
  getExclusionRules,
  setCurrentExclusionRule,
  setOpenExclusionRule,
  updateExclusionRule,
} from "../actions/exclusion_rules";

import { MuiChipsInput } from "mui-chips-input";
import { getLanguages } from "../actions/related";
import CustomChipInput from "../shared/components/FormElements/CustomChipInput";

const PREFIX = "ExclusionRuleForm";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const emptyExclusionRule = {
  rules: [],
  language_id: null,
};

const ExclusionRuleForm = ({
  current,
  open,
  clearCurrentExclusionRule,
  updateExclusionRule,
  setOpenExclusionRule,
  addExclusionRule,
  loading,
  allLanguages,
  getLanguages,
}) => {
  const [exclusion_rule, setExclusionRule] = useState(emptyExclusionRule);

  const { language_id, rules } = exclusion_rule;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theRoles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Editor" },
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setExclusionRule(current);
    } else {
      setExclusionRule(emptyExclusionRule);
    }
  }, [current, open]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenExclusionRule(false);
    clearCurrentExclusionRule();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addExclusionRule(exclusion_rule);
      } else {
        updateExclusionRule(exclusion_rule);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenExclusionRule(false);
    clearCurrentExclusionRule();
  }

  const onChange = (e, newVal = null) => {
    console.log(newVal);
    if (e === "jurisdiction") {
      setExclusionRule({
        ...exclusion_rule,
        jurisdiction_id: newVal ? newVal.id : "",
      });
    } else if (e === "language") {
      setExclusionRule({
        ...exclusion_rule,
        language_id: newVal.id,
      });
    } else {
      let val = e.target.value;
      if (e.target.name === "languages") {
        val = val.map((v) => {
          if (typeof v === "string")
            return {
              id: v,
              name: allLanguages.find((r) => r.id === v).name,
            };
          else return v;
        });
      }

      setExclusionRule({ ...exclusion_rule, [e.target.name]: val });
    }
  };

  const handleChange = (chips) => {
    setExclusionRule({ ...exclusion_rule, rules: chips });
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("md"))
            ? { width: "80%", height: "80%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Entry"
            : current && !open
            ? "Edit Entry: " + current.original_header
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <input type="hidden" autocomplete="false" />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={10}>
                <CustomChipInput
                  label={"Rules"}
                  defaultValue={
                    rules ? rules.sort((a, b) => a.localeCompare(b)) : []
                  }
                  fullWidth
                  onChange={(chips) => handleChange(chips)}
                />
              </Grid>{" "}
              <Grid item xs={12} md={2}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={allLanguages}
                    disabled={language_id}
                    disableClearable={true}
                    autoComplete="off"
                    classes={{
                      option: classes.option,
                    }}
                    size="small"
                    onChange={(e, newVal) => onChange("language", newVal)}
                    autoHighlight
                    value={
                      allLanguages.find((lang) => lang.id === language_id) ||
                      null
                    }
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>{" "}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  exclusion_rules: state.exclusion_rules.all,
  loading: state.exclusion_rules.loading,
  error: state.exclusion_rules.error,
  current: state.exclusion_rules.current,
  open: state.exclusion_rules.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteExclusionRule: (id) => dispatch(deleteExclusionRule(id)),
  getExclusionRules: () => dispatch(getExclusionRules()),
  clearErrorExclusionRules: () => dispatch(clearErrorExclusionRules()),
  setCurrentExclusionRule: (exclusion_rule) =>
    dispatch(setCurrentExclusionRule(exclusion_rule)),
  setOpenExclusionRule: (should) => dispatch(setOpenExclusionRule(should)),
  clearCurrentExclusionRule: () => dispatch(clearCurrentExclusionRule()),
  addExclusionRule: (exclusion_rule) =>
    dispatch(addExclusionRule(exclusion_rule)),
  updateExclusionRule: (exclusion_rule) =>
    dispatch(updateExclusionRule(exclusion_rule)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExclusionRuleForm);
