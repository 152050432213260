import React, { useEffect, useState, useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import { Helmet } from "react-helmet";
import {
  getLanguages,
  getSourceTypes,
  getSourceStatuses,
  getJurisdictions,
  getAlertSubaccounts,
  getTags,
  getLanguageSyntaxes,
  updateJurisdictionsTlds,
} from "../actions/related";

import { AuthContext } from "../shared/context/auth-context";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAlert from "@mui/material/Alert";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Dialog,
  Input,
  Typography,
  Card,
  Snackbar,
  IconButton,
  Button,
  Accordion,
} from "@mui/material";
import { Alert, AlertTitle, Pagination } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import ProgressBar from "../shared/components/UIElements/ProgressBar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import {
  getSources,
  setCurrentSource,
  clearErrorSources,
  clearCurrentSource,
  addSource,
  confirmAlertsImport,
  setSuccessSources,
} from "../actions/sources";
import InboxArticle from "./InboxArticle";

import { getDomain } from "../utils/functions.js";

import moment from "moment";

import UntrackedInboxCard from "./UntrackedInboxCard";
import { Box } from "victory";
import {
  getUntrackedInbox,
  setBackToInbox,
  reassignMother,
  updateJurs,
  updateTags,
  addTagTriggers,
  setCurrentArticle,
  clearCurrentArticle,
  addSourceToArticles,
  setFilters,
  blockSources,
  setLoading,
} from "../actions/articles";
import ReactPaginate from "react-paginate";
import LazyLoad from "react-lazyload";
import UntrackedInboxFilters from "./UntrackedInboxFilters";

import SourceForm from "../source/SourceForm";
import SyntaxForm from "./SyntaxForm";

const PREFIX = "UntrackedInbox";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  snack: `${PREFIX}-snack`,
  marginAutoContainer: `${PREFIX}-marginAutoContainer`,
  marginAutoItem: `${PREFIX}-marginAutoItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
  [`& .${classes.snack}`]: { backgroundColor: "#878bff" },
  [`& .${classes.marginAutoContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
  [`& .${classes.marginAutoItem}`]: {
    //margin: "auto"
  },
});

const Inbox = ({
  error,
  errorSources,
  clearErrorSources,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
  getSourceTypes,
  blockSources,
  source_types,
  getJurisdictions,
  getLanguageSyntaxes,
  language_syntaxes,
  jurisdictions,
  getTags,
  tags,
  languages,
  getLanguages,
  getUntrackedInbox,
  inbox_articles,
  current,
  currentSource,
  setCurrentArticle,
  clearCurrentArticle,
  show_articles,
  loading,
  setBackToInbox,
  reassignMother,
  filter,
  updateJurs,
  updateTags,
  addTagTriggers,
  setCurrentSource,
  clearCurrentSource,
  addSource,
  confirmAlertsImport,
  successSources,
  setSuccessSources,
  setFilters,
}) => {
  const [date, setDate] = useState(null);

  const [tier, setTier] = React.useState(null);
  const [subtier, setSubtier] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [multipleRemove, setMultipleRemove] = React.useState([]);
  const deletingMemo = React.useMemo(() => {
    console.log(multipleRemove.length);
    return (
      "Blocking " +
      multipleRemove.length +
      " sources, aprox finish time: " +
      moment()
        .add(multipleRemove.length * 12, "seconds")
        .format("HH:mm:ss")
    );
  }, [multipleRemove]);

  const [prefilledSource, setPrefilledSource] = React.useState(null);

  const [articleNewJur, setArticleNewJur] = React.useState(null);
  const [articleNewTag, setArticleNewTag] = React.useState(null);

  const [page, setPage] = React.useState(1);

  const [reassign, setReassign] = React.useState(null);
  const [reassignChildren, setReassignChildren] = React.useState([]);

  const [selectedArticle, setSelectedArticle] = React.useState({});

  const [undoDeleteArticle, setUndoDeleteArticle] = React.useState(null);

  const [undoApproveArticle, setUndoApproveArticle] = React.useState(null);

  const [success, setSuccess] = React.useState("");

  const [alertsPending, setAlertsPending] = React.useState(null);

  const handleCloseReassigned = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess("");
  };

  const rowsPerPage = 30;

  const addReassignChildren = (child) => {
    setReassignChildren([...reassignChildren, child]);
  };

  const removeReassignChildren = (child) => {
    setReassignChildren(reassignChildren.filter((c) => c.id !== child.id));
  };

  useEffect(() => {
    setLoading(false);
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!source_types || !source_types.length) getSourceTypes();

    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!language_syntaxes || !language_syntaxes.length) getLanguageSyntaxes();
    if (!tags || !tags.length) {
      console.log("tagssssss");
      getTags();
    }
    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();
    if (
      (!show_articles ||
        show_articles.length === 0 ||
        show_articles[0].source_id) &&
      tier &&
      subtier
    ) {
      //getSources();
      getUntrackedInbox(tier, subtier);
    }
  }, [date, currentSource]);

  useEffect(() => {
    if (show_articles && tier && subtier) {
      getUntrackedInbox(tier, subtier).then(() => {});
    }
  }, [tier, subtier]);
  useEffect(() => {
    console.log("sources changed");
    console.log(sources);
    let srcs = sources.filter(
      (s) => s.syntax_created === 0 && s.source_status_id === 1
    );
    let i = 0;
    for (let src of srcs) {
      for (let langstx of language_syntaxes) {
        if (src.languages.find((l) => l.id === langstx.language_id)) i++;
      }
    }
    setAlertsPending(i);
  }, [sources, language_syntaxes]);

  useEffect(() => {
    if (successSources) {
      setSuccess(successSources);
      setTimeout(() => setSuccessSources(""), 5000);
    }
  }, [successSources]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const confirm = useConfirm();

  const filterSubtier = (a) => {
    if (!subtier) return true;
    else if (tier <= 5) return subtier.indexOf(a.tld) !== -1;
    else if (tier > 5 && tier <= 8)
      return subtier.indexOf(a.detected_language) !== -1;
    else return true;
  };

  const handleClickAdd = (article) => {
    const { parseDomain, fromUrl } = require("parse-domain");
    let psl = require("psl");

    let { tld } = psl.parse(fromUrl(article.original_url));

    tld = tld.substring(tld.lastIndexOf(".") + 1);

    const { getCountry, getTLD } = require("tld-countries");
    console.log(tld, getCountry(tld));
    let sourceCountryName = tld ? getCountry(tld) : null;

    let name = article.publisher;
    let source_type_id = 1;

    if (
      name.indexOf("Facebook") !== -1 ||
      name.indexOf("facebook.com") !== -1 ||
      name.indexOf("Twitter") !== -1
    ) {
      let str = getDomain(article.original_url).split("/")[1];
      name = str.charAt(0).toUpperCase() + str.slice(1) + " " + name;
      source_type_id = 2;
    }

    alertsPending <= 50
      ? setCurrentSource({
          name: name,
          url: getDomain(article.original_url),
          source_type_id: source_type_id,
          source_status_id: 1,
          article_id: article.id,
          ...(sourceCountryName && {
            jurisdiction_id: jurisdictions.find(
              (jur) => jur.name === sourceCountryName
            )
              ? jurisdictions.find((jur) => jur.name === sourceCountryName).id
              : null,
          }),
          ...(article.detected_language && {
            languages: [
              {
                name: languages.find((r) => r.id === article.detected_language)
                  .name,
                id: article.detected_language,
              },
            ],
          }),
        })
      : confirm({
          title: "Create alerts",
          cancellationButtonProps: { style: { display: "none" } },
          description:
            "Please create pending alerts on Google before adding more sources",
        })
          .then(() => {})
          .catch(() => {
            /* ... */
          });
  };

  const handleClickBlock = (article, e) => {
    if (!e.ctrlKey && !e.metaKey && !multipleRemove.length && !e.shiftKey) {
      updateJurisdictionsTlds(
        jurisdictions.find((jur) => jur.tlds === subtier).id
      );
      let domain = getDomain(article.original_url);
      getJurisdictions();
      confirm({
        title: "Confirm block",
        description:
          "This will block all entries from: " +
          article.publisher +
          " (" +
          domain +
          ")",
      })
        .then(() => {
          let newSource = {
            source_status_id: 5,
            name: article.publisher,
            url: domain,
            source_type_id: 1,
            jurisdiction_id: "REG-4",
            languages: [{ name: "English", id: "en" }],
          };
          setCurrentSource(newSource); //to refresh articles
          addSource({ ...newSource, article_id: article.id }).then((res) => {
            updateJurisdictionsTlds(
              jurisdictions.find((jur) => jur.tlds === subtier).id
            );
          });
        })
        .catch(() => {
          /* ... */
        });
    } else if (e.shiftKey) {
      setMultipleRemove(
        multipleRemove.concat(
          show_articles
            .filter((a) => filterSubtier(a))
            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .filter((a) => !multipleRemove.find((aa) => aa.id === a.id))
        )
      );
    } else {
      if (multipleRemove.find((a) => a.id === article.id)) {
        setMultipleRemove(multipleRemove.filter((a) => a.id !== article.id));
      } else {
        setMultipleRemove(multipleRemove.concat([article]));
      }
    }
  };

  const handleSyntaxDone = () => {
    confirm({
      title: "Confirm syntax import",
      description: "IMPORTANT: This will mark all alerts pending as imported",
    })
      .then(() => {
        confirmAlertsImport();
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Untracked Sources - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack
        error={error || errorSources}
        clearError={clearErrorSources}
      />

      <SourceForm
        current={currentSource}
        clearCurrentSource={clearCurrentSource}
      />

      <SyntaxForm
        open={open || false}
        setOpen={setOpen}
        sources={sources}
        language_syntaxes={language_syntaxes}
        setSuccess={setSuccess}
        alert_subaccounts={alert_subaccounts}
      />

      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {multipleRemove && multipleRemove.length > 0 && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ top: "64px" }}
              ContentProps={{
                classes: {
                  root: classes.snack,
                },
              }}
              open={multipleRemove.length > 0}
              onClose={(event, reason) => {
                if (reason && reason == "backdropClick") return;
              }}
              message={
                !deleting
                  ? "Confirm multiple block (no undo)"
                  : multipleRemove.length
                  ? deletingMemo
                  : "Please wait..."
              }
              action={
                <React.Fragment>
                  {multipleRemove.length > 0 && (
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      disabled={deleting && multipleRemove.length}
                      onClick={async (e) => {
                        //TODO
                        if (!deleting) setDeleting(moment());
                        for (let a of multipleRemove) {
                          let newSource = {
                            source_status_id: 5,
                            name: a.publisher,
                            url: getDomain(a.original_url),
                            source_type_id: 1,
                            jurisdiction_id: "REG-4",
                            languages: [{ name: "English", id: "en" }],
                          };
                          setCurrentSource(newSource); //to refresh articles
                          await addSource({ ...newSource, article_id: a.id });
                        }
                        blockSources(multipleRemove);
                        setMultipleRemove([]);
                        setSuccess("Multiple sources blocked successfully");
                        setDeleting(false);
                      }}
                    >
                      {!deleting ? (
                        <div>BLOCK {multipleRemove.length} SOURCES</div>
                      ) : (
                        <div>Please wait...</div>
                      )}
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => {
                      setMultipleRemove([]);
                    }}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <Snackbar
            open={!loading && success}
            autoHideDuration={3000}
            onClose={handleCloseReassigned}
            style={{ top: "64px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseReassigned}
              severity="success"
            >
              {success.msg ? success.msg : success}
            </MuiAlert>
          </Snackbar>
          <Grid container>
            <Grid item md={12}>
              <h1 style={{ margin: 0, color: "#002060" }}>
                Untracked Source Inbox
              </h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              {source_types && source_types.length > 0 && (
                <UntrackedInboxFilters
                  setTier={(t) => {
                    setSubtier(null);
                    setTimeout(() => setTier(t), 200);
                  }}
                  tier={tier}
                  date={date}
                  setDate={setDate}
                  source_types={source_types}
                  setPage={setPage}
                  jurisdictions={jurisdictions}
                  getJurisdictions={getJurisdictions}
                  show_articles={show_articles}
                  subtier={subtier}
                  setSubtier={setSubtier}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              md={12}
              style={{
                textAlign: "right",
                paddingTop: "12px",
              }}
            >
              {alertsPending > 0 && (
                <h3 style={{ color: "#002060" }}>
                  {alertsPending + " alerts pending alert setup"}{" "}
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={async (e) => {
                      handleClickOpen();
                    }}
                  >
                    GENERATE SCRIPT
                  </Button>{" "}
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      handleSyntaxDone();
                    }}
                  >
                    CONFIRM ALERTS CREATED
                  </Button>
                </h3>
              )}
            </Grid>
          </Grid>
          {!loading && subtier && (
            <div>
              <div className={classes.marginAutoContainer}>
                <div className={classes.marginAutoItem}>
                  <Pagination
                    count={Math.ceil(
                      show_articles.filter((a) => filterSubtier(a)).length /
                        rowsPerPage
                    )}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    boundaryCount={20}
                  />
                </div>
              </div>

              <Grid container>
                {show_articles
                  .filter((a) => filterSubtier(a))
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((article, index) => (
                    <Grid item md={12} padding={"0px"} key={index}>
                      <UntrackedInboxCard
                        key={article.id}
                        article={article}
                        sources={sources}
                        inboxPosition={index}
                        source_types={source_types}
                        jurisdictions={jurisdictions}
                        languages={languages}
                        reassign={reassign}
                        setReassign={setReassign}
                        addReassignChildren={addReassignChildren}
                        reassignChildren={reassignChildren}
                        toReassign={
                          reassignChildren
                            .map((c) => c.id)
                            .indexOf(article.id) !== -1
                        }
                        removeReassignChildren={removeReassignChildren}
                        setUndoDeleteArticle={setUndoDeleteArticle}
                        setUndoApproveArticle={setUndoApproveArticle}
                        setSuccess={setSuccess}
                        success={success}
                        setArticleNewJur={setArticleNewJur}
                        setArticleNewTag={setArticleNewTag}
                        setCurrentArticle={setCurrentArticle}
                        setCurrentSource={setCurrentSource}
                        multipleRemove={multipleRemove}
                        handleClickAdd={handleClickAdd}
                        handleClickBlock={handleClickBlock}
                      />
                    </Grid>
                  ))}
              </Grid>
              <div className={classes.marginAutoContainer}>
                <div className={classes.marginAutoItem}>
                  <Pagination
                    count={Math.ceil(
                      show_articles.filter((a) => filterSubtier(a)).length /
                        rowsPerPage
                    )}
                    page={page}
                    onChange={(e, value) => {
                      setPage(value);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    boundaryCount={20}
                  />
                </div>
              </div>
            </div>
          )}
          {undoDeleteArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoDeleteArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoDeleteArticle(null);
              }}
              message={
                (undoDeleteArticle.source
                  ? undoDeleteArticle.source.name + "'s \""
                  : '"') +
                (
                  undoDeleteArticle.translated_header ||
                  undoDeleteArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article moved to bin"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoDeleteArticle);
                      setUndoDeleteArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoDeleteArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

          {undoApproveArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoApproveArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoApproveArticle(null);
              }}
              message={
                (undoApproveArticle.source
                  ? undoApproveArticle.source.name + "'s \""
                  : '"') +
                (
                  undoApproveArticle.translated_header ||
                  undoApproveArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article approved"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoApproveArticle);
                      setUndoApproveArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoApproveArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  errorSources: state.sources.error,
  alert_subaccounts: state.related.alert_subaccounts,
  source_types: state.related.source_types,
  language_syntaxes: state.related.language_syntaxes,
  jurisdictions: state.related.jurisdictions,
  tags: state.related.tags,
  languages: state.related.languages,
  inbox_articles: state.articles.inbox,
  filter: state.articles.filter,
  loading: state.articles.loading,
  show_articles: state.articles.filter.active
    ? state.articles.filter.result
    : state.articles.untracked_inbox,
  current: state.articles.current,
  currentSource: state.sources.current,
  filter: state.articles.filter,
  successSources: state.sources.success,
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  updateJurisdictionsTlds: () => dispatch(updateJurisdictionsTlds()),
  getTags: () => dispatch(getTags()),
  getLanguages: () => dispatch(getLanguages()),
  getUntrackedInbox: (tier, subtier) =>
    dispatch(getUntrackedInbox(tier, subtier)),
  setBackToInbox: (article) => dispatch(setBackToInbox(article)),
  reassignMother: (mother, children) =>
    dispatch(reassignMother(mother, children)),
  updateJurs: (art, jurs) => dispatch(updateJurs(art, jurs)),
  updateTags: (art, tags) => dispatch(updateTags(art, tags)),
  addTagTriggers: (tag, triggers) => dispatch(addTagTriggers(tag, triggers)),
  addSourceToArticles: (publisher, source) =>
    dispatch(addSourceToArticles(publisher, source)),
  setLoading: dispatch(setLoading(false)),
  setCurrentArticle: (art) => dispatch(setCurrentArticle(art)),
  clearCurrentArticle: () => dispatch(clearCurrentArticle()),
  clearErrorSources: () => dispatch(clearErrorSources()),
  setCurrentSource: (source) => dispatch(setCurrentSource(source)),
  addSource: (source) => dispatch(addSource(source, true)),
  blockSources: (arts) => dispatch(blockSources(arts)),

  clearCurrentSource: () => dispatch(clearCurrentSource()),
  getLanguageSyntaxes: () => dispatch(getLanguageSyntaxes()),
  confirmAlertsImport: () => dispatch(confirmAlertsImport()),

  setFilters: (filters) => dispatch(setFilters(filters, "_USI")),
  setSuccessSources: (msg) => dispatch(setSuccessSources(msg)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
