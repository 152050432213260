import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";

const InfoPopup = ({ props, onClose }) => {
  const [info, setInfo] = useState("");

  const handleSubmit = () => {
    props.submit(info);
    onClose(); // Close the dialog after submission
  };

  const labels = {
    revision: {
      title: "Submit for revision",
      content: "Please provide any additional information for the proofreader.",
      inputLabel: "",
      submit: "Submit for revision",
    },
    return_draft: {
      title: "Return to editor",
      content: "Please provide any additional information for the editor.",
      inputLabel: "",
      submit: "Return to editor",
    },
    return_revision: {
      title: "Return to proofreader",
      content: "Please provide any additional information for the proofreader.",
      inputLabel: "",
      submit: "Return to proofreader",
    },
    admin: {
      title: "Send to admin",
      content: "Please provide any additional information for the admin.",
      inputLabel: "",
      submit: "Send to admin",
    },
  };

  return (
    <Dialog open={props.open} onClose={onClose}>
      <DialogTitle>{labels[props.type].title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{labels[props.type].content}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={labels[props.type].inputLabel}
          type="text"
          multiline
          fullWidth
          variant="standard"
          value={info}
          onChange={(e) => setInfo(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>{labels[props.type].submit}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoPopup;
