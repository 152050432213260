import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import SourceIcon from "../Icons/SourceIcon";
import RemoveLine from "../Icons/RemoveLine";
import LinkIcon from "../Icons/LinkIcon";
import RemoveLinkIcon from "../Icons/RemoveLinkIcon";
import TipTapContext from "../../../shared/context/tipTap-context";
import SaveIcon from "../Icons/SaveIcon";
import { updateEntrySourceReference } from "../../Api/EntryApi";
import SuccessSnack from "../SuccessSnack";
import { useSelector } from "react-redux";
import { useActionTrigger } from "../../ActionTriggerContext";

const MenuBar = ({
  editor,
  openDrawer,
  setOpenDrawer,
  noSource = false,
  hasActiveSelection = true,
  lang,
  columnName,
  setActiveInDrawer,
  setAddingNewFootnote,
  section,
}) => {
  const {
    deleteFootnoteAction,
    deleteSourceAction,
    ctxEntry,
    addingNewSource,
    addingNewFootnote,
    showSuccessSnack,
    setSnackSuccessMessage,
    showErrorSnack,
    setSnackErrorMessage,
    setSaveChanges,
    saveChanges,
  } = useContext(TipTapContext);

  const { triggerCounter, acknowledgeAction } = useActionTrigger();

  const sourcesValidation = useSelector((state) => state.sourcesValidation);

  // footnoteDelete
  const [openFootnoteDelete, setOpenFootnoteDelete] = useState(false);
  const [footnoteToDelete, setFootnoteToDelete] = useState({});

  const handleOpenFootnoteDelete = () => {
    setOpenFootnoteDelete(true);
  };

  const handleCloseFootnoteDelete = () => {
    setOpenFootnoteDelete(false);
  };

  const saveCurrentEditor = async (outside = false) => {
    try {
      const formData = extractFormContent();
      let res = await updateEntrySourceReference(formData);

      if (!outside) {
        showSuccessSnack();
        setSnackSuccessMessage("Editor save was successful");
      }
    } catch (e) {
      console.log("e.message: ", e.message);
    }
  };

  useEffect(() => {
    if (columnName) {
      //columnName comes only when its a Source Editor
      console.log("Action triggered in child component", lang + columnName);
      // Perform the desired action here

      // Acknowledge the action
      saveCurrentEditor(true).then(acknowledgeAction(lang + columnName));
    }
  }, [triggerCounter, lang, columnName]);

  const handleConfirmFootnoteDelete = () => {
    setOpenFootnoteDelete(false);
    // this mark has the index/order
    deleteFootnoteAction(footnoteToDelete, ctxEntry, lang, columnName);
  };
  // end of footnoteDelete

  // sourceDelete
  const [openSourceDelete, setOpenSourceDelete] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState({});
  const handleOpenSourceDelete = () => {
    setOpenSourceDelete(true);
  };

  const handleCloseSourceDelete = () => {
    setOpenSourceDelete(false);
  };

  const handleConfirmSourceDelete = () => {
    setOpenSourceDelete(false);
    // this mark has the index/order
    deleteSourceAction(sourceToDelete, ctxEntry, section, lang, columnName);
  };
  // end of sourceDelete

  const extractFormContent = () => {
    const formData = new FormData();
    formData.append("lang", lang);
    formData.append("columnName", columnName);
    formData.append("section", section);
    formData.append("entry", JSON.stringify(ctxEntry));
    formData.append("content", editor.getHTML());

    return formData;
  };

  const [showSnack, setShowSnack] = useState(false);
  const handleShowSnack = () => {
    setShowSnack(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnack(false);
  };

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl || "https://");

    // cancelled
    if (url === null) {
      return;
    }
    if (
      !/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
        url
      )
    ) {
      setLink(url);
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="TipTapMenu">
      {!noSource && (
        <Tooltip title="Open Source/Footnotes">
          <span>
            <IconButton
              size="small"
              aria-label="close"
              color="primary"
              onClick={() => setOpenDrawer(!openDrawer)}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={`DrawerBtn DrawerBtn__refManager ${
                openDrawer ? "is-active editor" : "editor"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 -960 960 960"
              >
                <path d="M142.5-165q-22.5 0-40-17.5T85-222.363v-515.274Q85-760 102.5-777.5t40-17.5h281l57.5 57.5h336.5q22.737 0 39.869 17.5Q874.5-702.5 875-680H456l-57.5-57.5h-256v511l101-396h695L832-210q-6.5 23.5-22 34.25T769.896-165H142.5Zm59-57.5H776L861-565H286.5l-85 342.5Zm0 0 85-342.5-85 342.5Zm-59-457.5v-57.5 57.5Z" />
              </svg>{" "}
              Reference Manager
            </IconButton>
          </span>
        </Tooltip>
      )}

      <span
        style={{
          color: "rgb(204, 204, 204)",
          borderRight: "1px solid rgb(204, 204, 204)",
          height: "30px",
          marginRight: "10px",
          marginTop: "3px",
          marginLeft: "6px",
        }}
      >
        {" "}
      </span>

      {noSource && (
        <>
          <Tooltip title="Make selection bold">
            <span>
              <IconButton
                size="small"
                aria-label="close"
                color="primary"
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={`TipTapIconFix ${
                  editor.isActive("bold") ? "is-active editor" : "editor"
                }`}
              >
                <Icon fontSize="small">format_bold</Icon>
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Make selection Italic">
            <span>
              <IconButton
                size="small"
                aria-label="close"
                color="primary"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={`TipTapIconFix ${
                  editor.isActive("italic") ? "is-active editor" : "editor"
                }`}
              >
                <Icon fontSize="small">format_italic</Icon>
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Make Bulleted List">
            <span>
              <IconButton
                size="small"
                color="primary"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                disabled={
                  !editor.can().chain().focus().toggleBulletList().run()
                }
                className={
                  editor.isActive("bulletList") ? "is-active editor" : "editor"
                }
              >
                <Icon fontSize="small">format_list_bulleted</Icon>
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Make Ordered List">
            <span>
              <IconButton
                size="small"
                aria-label="close"
                color="primary"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                disabled={
                  !editor.can().chain().focus().toggleOrderedList().run()
                }
                className={
                  editor.isActive("orderedList") ? "is-active editor" : "editor"
                }
              >
                <Icon fontSize="small">format_list_numbered</Icon>
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}

      {!noSource && (
        <>
          <Tooltip
            title={editor.isActive("source") ? "Remove source" : "Add source"}
          >
            <span>
              <IconButton
                size="small"
                aria-label={
                  editor.isActive("source") ? "Remove source" : "Add source"
                }
                color="primary"
                onClick={() => {
                  if (!editor.isActive("source")) {
                    showSuccessSnack();
                    setSnackSuccessMessage("Adding a source");
                    return editor.chain().focus().toggleSource().run();
                  } else {
                    console.log("whatwhaaaat source!!!! -> ", ctxEntry);
                    // Get the current selection
                    const { from, to } = editor.state.selection;

                    // Define the mark type we are looking for
                    const customSourceMarkType = editor.schema.marks.source;

                    // Iterate through all nodes within the current selection
                    editor.state.doc.nodesBetween(from, to, (node) => {
                      // Check if this node has the 'customFootnote' mark
                      const mark = node.marks.find(
                        (mark) => mark.type === customSourceMarkType
                      );

                      // If the mark is found, log its attributes
                      if (mark) {
                        const sourceOrder =
                          mark.attrs["data-original-source"] + 1;
                        const source = ctxEntry.entries.sources.filter(
                          (source) =>
                            source.order === sourceOrder &&
                            (!section || source.section === section)
                        )[0];
                        if (source) {
                          setSourceToDelete(source);
                          handleOpenSourceDelete();
                        } else {
                          //Source not added yet
                          //refresh page

                          window.location.reload();
                        }
                      }
                    });
                  }
                }}
                disabled={!hasActiveSelection}
                className={
                  editor.isActive("source") ? "is-active editor" : "editor"
                }
              >
                <SourceIcon />
                {editor.isActive("source") && <RemoveLine />}
              </IconButton>
            </span>
          </Tooltip>

          <Dialog
            className="deleteConfirmation__dialog"
            open={openSourceDelete}
            onClose={handleCloseSourceDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              You are about to delete source:{" "}
              <strong>"{sourceToDelete.text}"</strong>
            </DialogTitle>
            <DialogContent>
              <div id="alert-dialog-description">
                <p>
                  This will remove the source in question and files concerned
                  from both English and Spanish entries
                </p>
                <p>Are you sure you want to do this?</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: "auto" }}
                onClick={handleCloseSourceDelete}
              >
                Cancel
              </Button>
              <Button onClick={handleConfirmSourceDelete} autoFocus>
                Yes, delete it please
              </Button>
            </DialogActions>
          </Dialog>

          <Tooltip
            title={
              editor.isActive("customFootnote")
                ? "Remove footnote"
                : "Add footnote"
            }
          >
            <span>
              <IconButton
                size="small"
                aria-label={
                  editor.isActive("customFootnote")
                    ? "Remove footnote"
                    : "Add footnote"
                }
                color="primary"
                onClick={() => {
                  // if editor.isActive("customFootnote") is not true
                  if (!editor.isActive("customFootnote")) {
                    setOpenDrawer(true);
                    setActiveInDrawer("footnote");
                    setAddingNewFootnote(true);

                    return editor.chain().focus().toggleFootnote().run();
                  } else {
                    // Get the current selection
                    const { from, to } = editor.state.selection;

                    // Define the mark type we are looking for
                    const customFootnoteMarkType =
                      editor.schema.marks.customFootnote;

                    // Iterate through all nodes within the current selection
                    editor.state.doc.nodesBetween(from, to, (node) => {
                      // Check if this node has the 'customFootnote' mark
                      const mark = node.marks.find(
                        (mark) => mark.type === customFootnoteMarkType
                      );

                      // If the mark is found, log its attributes
                      if (mark) {
                        console.log(
                          "customFootnoteMark attributes: ",
                          mark.attrs
                        );
                        const footnoteOrder = mark.attrs["data-index"];
                        const footnote = ctxEntry.entries.footnotes.filter(
                          (footnote) =>
                            footnote.order === footnoteOrder &&
                            footnote.lang === lang &&
                            (!section || footnote.section === section)
                        )[0];
                        console.log("footnote: ", footnote);
                        setFootnoteToDelete(footnote);

                        handleOpenFootnoteDelete();
                      }
                    });
                  }
                }}
                disabled={!hasActiveSelection}
                className={
                  editor.isActive("customFootnote")
                    ? "is-active editor"
                    : "editor"
                }
              >
                <strong
                  style={{
                    marginLeft: "2px",
                    marginRight: "2px",
                    paddingTop: "0px",
                    fontSize: "16px",
                  }}
                >
                  T<sup>1</sup>
                </strong>
                {editor.isActive("customFootnote") && <RemoveLine />}
              </IconButton>
            </span>
          </Tooltip>

          {footnoteToDelete && (
            <Dialog
              className="deleteConfirmation__dialog"
              open={openFootnoteDelete}
              onClose={handleCloseFootnoteDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                You are about to delete footnote {footnoteToDelete.text}
                <sup>{footnoteToDelete.order}</sup>.
              </DialogTitle>
              <DialogContent>
                <div id="alert-dialog-description">
                  <p>
                    This will only remove the footnote from the box you are
                    acting on
                  </p>
                  <p>Are you sure you want to do this?</p>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ marginRight: "auto" }}
                  onClick={handleCloseFootnoteDelete}
                >
                  Cancel
                </Button>
                <Button onClick={handleConfirmFootnoteDelete} autoFocus>
                  Yes, delete it please
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}

      <Tooltip
        title={`${
          editor.isActive("link") ? "Remove external link" : "Add external link"
        }`}
      >
        <span>
          <IconButton
            size="small"
            aria-label="close"
            color="primary"
            disabled={!hasActiveSelection}
            onClick={() => {
              if (editor.isActive("link")) {
                editor.chain().focus().unsetLink().run();
              } else {
                setLink();
              }
            }}
            className={`TipTapIconFix ${
              editor.isActive("link") ? "is-active editor" : "editor"
            }`}
          >
            {editor.isActive("link") ? <RemoveLinkIcon /> : <LinkIcon />}
          </IconButton>
        </span>
      </Tooltip>

      {!noSource && false && (
        <Tooltip title={"Save current editor content"}>
          <span className={"saveEditor__content"}>
            <IconButton
              size="small"
              disabled={addingNewSource || addingNewFootnote}
              color="primary"
              onClick={() => saveCurrentEditor()}
              className={"editor"}
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default MenuBar;
