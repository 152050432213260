import {
  Autocomplete,
  Button,
  DialogActions,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import React, { useEffect, useState } from "react";

const PREFIX = "AddJur";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

function AddJur(props) {
  const { article, jurisdictions, setArticleNewJur, updateJurs } = props;

  const [newJurs, setNewJurs] = useState(null);
  const [iniJurs, setIniJurs] = useState([]);

  useEffect(() => {
    console.log(article);
  }, [article]);
  useEffect(() => {
    console.log(iniJurs);
  }, [iniJurs]);

  useEffect(() => {
    if (article && jurisdictions && jurisdictions.length) {
      setIniJurs(
        jurisdictions.filter((j) => {
          if (article.jurisdictions && article.jurisdictions.length)
            return article.jurisdictions.find(
              (jur) => jur.jurisdiction_id === j.id
            );
          else return j.id === article.source.jurisdiction_id;
        })
      );
    }
  }, [article, jurisdictions, newJurs]);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setNewJurs(null);
    setIniJurs([]);
    setArticleNewJur(null);
  };

  const onChange = (val) => {
    setNewJurs(val);
  };
  const handleSave = (article) => {
    if (newJurs) {
      updateJurs(article, newJurs);
    } else {
      updateJurs(article, iniJurs);
    }
    handleClose();
  };
  return (
    <StyledDialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={article || false}
    >
      {article && jurisdictions && jurisdictions.length > 0 && (
        <Paper style={{ padding: "10px" }}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={jurisdictions}
            disableClearable={false}
            clearOnBlur={true}
            classes={{
              option: classes.option,
            }}
            multiple
            value={newJurs ? newJurs : iniJurs}
            autoHighlight
            size="small"
            start
            filterOptions={createFilterOptions({
              matchFrom: "start",
            })}
            onChange={(e, newVal) => {
              console.log(e, newVal);
              onChange(newVal);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {true && (
                  <span>
                    <img
                      width="32px"
                      src={
                        "/img/country_flags/" + option.country_code + "@3x.png"
                      }
                    />
                  </span>
                )}{" "}
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Jurisdictions"
                variant="outlined"
                autoFocus
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>{" "}
        <Button
          onClick={(e) => handleSave(article)}
          variant="contained"
          color="primary"
        >
          SAVE
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default AddJur;
