import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import Auth from "./Auth";

const Home = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  if (props.isLoggedIn) history.push("/inbox");

  return (
    <React.Fragment>
      <ErrorSnack error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth={false}>
            {!props.isLoggedIn ? <Auth /> : <Fragment></Fragment>}
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
