import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

function YearPicker({ label, name, onChange, value = "", disabled = false }) {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  useEffect(() => {
    console.log("VALUEEEE", label, value);
    if (value) {
      const theThing = new Date(value, 0, 1);
      setSelectedDate(theThing);
    } else {
      setSelectedDate(null);
    }
  }, [value]);

  return (
    <DatePicker
      styles={{ width: "100%" }}
      disabled={disabled}
      views={["year"]}
      label={label}
      name={name}
      value={selectedDate ? dayjs(selectedDate) : null}
      onChange={handleDateChange}
      minDate={dayjs("1700-01-01")}
      maxDate={dayjs()}
      format="YYYY"
      input={(params) => <TextField {...params} />}
    />
  );
}

export default YearPicker;
