import React, {useState} from 'react'
import Tooltip from "@mui/material/Tooltip";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import TrashcanIcon from "../Icons/TrashcanIcon";

const DeleteSourceModal = ({ deleteAction, id }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    console.log("Delete source is confirmed")
    setOpen(false)
    deleteAction(id)
  }

  return <>

    <Tooltip title="Delete">
      <IconButton aria-label="delete"
                  onClick={handleOpen}
      >
        <TrashcanIcon />
      </IconButton>
    </Tooltip>

    <Dialog
      className="deleteConfirmation__dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        You are about to delete a source.
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>This will remove the source in question and files concerned from both English and Spanish entries</p>
          <p>Are you sure you want to do this?</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ marginRight: 'auto' }} onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Yes, delete it please
        </Button>
      </DialogActions>
    </Dialog>

  </>
}

export default DeleteSourceModal