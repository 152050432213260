import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getLanguages, getSourceTypes, getTags } from "../../actions/related";

import { getAllSubJurisdictions } from "../../actions/selectOptions";

import Skeleton from "@mui/material/Skeleton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import useSWR from "swr";
import CustomizedSelect from "./Selects/CustomizedSelect";
import TabPanel from "./TabPanel";
import RichTextTap from "./TipTap/RichTextTap";
import TipTap from "./TipTap/TipTap";
import YearPicker from "./YearPicker";
import TipTapContext from "../../shared/context/tipTap-context";
import { checkErrors } from "../Config/area1";

const postFetcherSWR = async (url, data) => {
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = response;
  return responseData;
};

const FullForm = ({
  allJurisdictions,
  allSubJurisdictions,
  getAllSubJurisdictions,
  allDecrimMechanisms,
  penalties,
  otherPenalties,
  maxPrisonValues,
  deathPenaltyValues,
  dataEntry,
  mutate,
  loadingJurisdictions,
  jurisdictionId,
  entryTypeId,
  setSuccess,
  users,
  fromRevisions = false,
  enabled = true,
}) => {
  const history = useHistory();
  const [selectedJurisdiction, setSelectedJurisdiction] = useState("");
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const location = useLocation();
  const {
    changeEntry,
    changeCtxJurisdictions,
    addingNewFootnote,
    setAddingNewFootnote,
  } = useContext(TipTapContext);

  useEffect(() => {
    if (dataEntry) changeEntry(dataEntry.entry[0]);
  }, [dataEntry]);

  useEffect(() => {
    console.log("ALLL JURS", allJurisdictions);
    if (allJurisdictions && allJurisdictions.jurisdictions) {
      changeCtxJurisdictions(allJurisdictions.jurisdictions);
      const jurOptions = allJurisdictions.jurisdictions.map((item) => ({
        label: item.name_en,
        value: {
          value: item.id,
          label: item.name_en,
          countryCode: item.a2_code,
        },
      }));
      console.log("ALLL JURS 222", jurOptions);
      setJurisdictionOptions(jurOptions);
    }
  }, [allJurisdictions]);
  // end of jurisdictions options and state

  const initialFormState = {
    jurisdiction: selectedJurisdiction ? selectedJurisdiction : "",
    subJurisdiction: "",
    legal: "",
    lastAmendment: "",
    lastAmendment2: "",
    decrimDate: "",
    decrimDate2: "",
    explan_en: "",
    explan_es: "",
    penalty: "",
    decrimMechanism: "",
    deathPenalty: "",
    dpExplanEn: "",
    dpExplanEs: "",
    maxPrisonValue: "",
    maxPrisonYears: "",
    prisonExplanEn: "",
    prisonExplanEs: "",
    hasFine: false,
    fineExplanEn: "",
    fineExplanEs: "",
    otherPunishment: "",
    otherExplanEn: "",
    otherExplanEs: "",
    enforcementEn: "",
    enforcementEs: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const [loadingOptions, setLoadingOptions] = useState(true);

  const [selectedDecrimMechanism, setSelectedDecrimMechanism] = useState("");
  const [decrimMechanismOptions, setDecrimMechanismOptions] = useState([]);
  useEffect(() => {
    if (allDecrimMechanisms) {
      const decrimMechanismOptions = allDecrimMechanisms.map((item) => ({
        label: item.name_en,
        value: item.id,
      }));
      setDecrimMechanismOptions(decrimMechanismOptions);
    }
  }, [allDecrimMechanisms]);
  // end of decrimMechanismOptions

  const [selectedPenalty, setSelectedPenalty] = useState("");
  const [penaltyOptions, setPenaltyOptions] = useState([]);

  useEffect(() => {
    if (penalties) {
      const penaltiesOptions = penalties.map((item) => ({
        label: item.name_en,
        value: item.id,
      }));
      setPenaltyOptions(penaltiesOptions);
    }
  }, [penalties]);
  // end of penaltyOptions

  const [selectedOtherPunishment, setSelectedOtherPunishment] = useState("");
  const [otherPunishmentOptions, setOtherPunishmentOptions] = useState([]);

  useEffect(() => {
    if (otherPenalties) {
      const otherPenaltiesOptions = otherPenalties.map((item) => ({
        label: item.name_en,
        value: item.id,
      }));
      setOtherPunishmentOptions(otherPenaltiesOptions);
    }
  }, [otherPenalties]);

  // end of otherPunishmentOptions

  const [selectedDeathPenalty, setSelectedDeathPenalty] = useState("");
  const [deathPenaltyValueOptions, setDeathPenaltyValueOptions] = useState([]);

  useEffect(() => {
    if (deathPenaltyValues) {
      const selectOptions = deathPenaltyValues.map((item) => ({
        label: item.name_en,
        value: item.id,
      }));
      setDeathPenaltyValueOptions(selectOptions);
    }
  }, [deathPenaltyValues]);

  // end of deathPenaltyOptions

  const [selectedMaxPrisonTime, setSelectedMaxPrisonTime] = useState("");
  const [maxPrisonValueOptions, setMaxPrisonValueOptions] = useState([]);

  useEffect(() => {
    if (maxPrisonValues.length > 2) {
      const mpvOptions = maxPrisonValues.map((item) => ({
        label: item.name_en,
        value: item.id,
      }));
      setMaxPrisonValueOptions(mpvOptions);
    }
  }, [maxPrisonValues]);
  // end of maxPrisonValueOptions

  //subjurisdictions options and state
  const [subJurisdictionOptions, setSubJurOptions] = useState([]);
  useEffect(() => {
    if (
      allSubJurisdictions &&
      allSubJurisdictions.subjurisdictions &&
      allSubJurisdictions.subjurisdictions.length > 0
    ) {
      const subJurOptions = allSubJurisdictions.subjurisdictions.map(
        (item) => ({
          label: item.name_en,
          value: {
            value: item.id,
            label: item.name_en,
          },
        })
      );
      setSubJurOptions(subJurOptions);
    }
  }, [allSubJurisdictions]);
  // end of subjurisdiction changes

  useEffect(() => {
    if (
      allDecrimMechanisms.length &&
      penalties.length &&
      otherPenalties.length &&
      maxPrisonValues.length &&
      deathPenaltyValues.length &&
      allSubJurisdictions.length
    ) {
      setLoadingOptions(false);
    }
  }, [
    allDecrimMechanisms,
    penalties,
    otherPenalties,
    deathPenaltyValues,
    maxPrisonValues,
    allSubJurisdictions,
  ]);

  const [errors, setErrors] = useState([]);

  const {
    mutate: mutation,
    error: errorEntry,
    isLoading: isLoadingEntry,
  } = useSWR(
    `${process.env.REACT_APP_BACKEND_URL}'/database/entry/${jurisdictionId}/${entryTypeId}`
  );
  const handleFormSubmit = async (e, draft = false) => {
    e.preventDefault();

    const errorsss = checkErrors(entryTypeId)(formData);

    if (errorsss.length > 0) {
      setErrors(errorsss);
      return false;
    } else {
      setErrors([]);
      try {
        await postFetcherSWR(
          `${process.env.REACT_APP_BACKEND_URL}/database/entry/${jurisdictionId}/${entryTypeId}`,
          { ...formData, draft }
        );
        // does a false positive on
        setSuccess("Entry saved successfully");
      } catch (err) {
        setSuccess({
          msg: "There was an error saving the entry",
          severity: "error",
        });
        console.log("err: ", err);
      }
    }
  };

  const [selectedSubJurisdiction, setSelectedSubJurisdiction] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleYearChange = (name, year) => {
    console.log({ ...formData, [name]: year });
    setFormData({ ...formData, [name]: year });
  };

  const handleDecrimMechanismChange = (_, value) => {
    setSelectedDecrimMechanism(value);
    setFormData({ ...formData, decrimMechanism: value });
  };

  const handlePenaltyChange = (_, value) => {
    setSelectedPenalty(value);
    setFormData({ ...formData, penalty: value });
  };

  const handleOtherPunishmentChange = (_, value) => {
    setSelectedOtherPunishment(value);
    setFormData({ ...formData, otherPunishment: value });
  };

  const handleMaxPrisonValueChange = (_, value) => {
    setSelectedMaxPrisonTime(value);
    setFormData({ ...formData, maxPrisonValue: value });
  };

  const handleDeathPenaltyChange = (_, value) => {
    setSelectedDeathPenalty(value);
    setFormData({ ...formData, deathPenalty: value });
  };

  const handleCheckboxChange = (event, name) => {
    setFormData({ ...formData, [name]: event.target.checked });
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (formData && formData.jurisdiction && formData.jurisdiction.value) {
      getAllSubJurisdictions(formData.jurisdiction.value.value);
    }
  }, [formData.jurisdiction]);

  useEffect(() => {
    getAllSubJurisdictions(jurisdictionId);
    if (dataEntry.entry && dataEntry.entry.length && dataEntry.entry[0]) {
      const jurSelected = jurisdictionOptions.filter(
        (jurisdiction) =>
          jurisdiction.value.value ===
          dataEntry.entry[0].entries.jurisdiction_id
      )[0];
      setSelectedJurisdiction(jurSelected);

      const penaltySelected = penaltyOptions.filter(
        (penalty) => penalty.value === dataEntry.entry[0].entry_csssa_penalty_id
      )[0];
      setSelectedPenalty(penaltySelected);

      const deathPenaltySelected = deathPenaltyValueOptions.filter(
        (deathPenalty) =>
          deathPenalty.value ===
          dataEntry.entry[0].entry_csssa_death_penalty_value_id
      )[0];
      setSelectedDeathPenalty(deathPenaltySelected);

      const decrimMechanismSelected = decrimMechanismOptions.filter(
        (decrimMechanism) =>
          decrimMechanism.value ===
          dataEntry.entry[0].legal_framework_decrim_mechanism_id
      )[0];

      setSelectedDecrimMechanism(decrimMechanismSelected);

      const maxPrisonTimeSelected = maxPrisonValueOptions.filter(
        (maxPrisonValue) => {
          return (
            maxPrisonValue.value ===
            dataEntry.entry[0].entry_csssa_max_prison_value_id
          );
        }
      )[0];
      setSelectedMaxPrisonTime(maxPrisonTimeSelected);

      const otherPunishmentSelected = otherPunishmentOptions.filter(
        (otherPunishmentValue) => {
          return (
            otherPunishmentValue.value ===
            dataEntry.entry[0].entry_csssa_other_punishment_value_id
          );
        }
      )[0];
      setSelectedOtherPunishment(otherPunishmentSelected);

      setFormData({
        ...formData,
        jurisdiction: jurSelected,
        deathPenalty: deathPenaltySelected,
        maxPrisonValue: maxPrisonTimeSelected,
        otherPunishment: otherPunishmentSelected,
        penalty: penaltySelected,
        decrimMechanism: decrimMechanismSelected,
        legal: dataEntry.entry[0].legal ? 1 : 0,
        maxPrisonYears: dataEntry.entry[0]
          ? dataEntry.entry[0].max_prison_years
          : "",
        hasFine: dataEntry.entry[0] ? dataEntry.entry[0].has_fine : "",
        decrimDate: dataEntry.entry[0]
          ? dataEntry.entry[0].decrim_date_1
          : null,
        decrimDate2: dataEntry.entry[0]
          ? dataEntry.entry[0].decrim_date_2
          : null,
        lastAmendment: dataEntry.entry[0]
          ? dataEntry.entry[0].last_amendment_1
          : null,
        lastAmendment2: dataEntry.entry[0]
          ? dataEntry.entry[0].last_amendment_2
          : null,
        explan_en: dataEntry.entry[0] ? dataEntry.entry[0].explan_en : "",
        explan_es: dataEntry.entry[0] ? dataEntry.entry[0].explan_es : "",
        fineExplanEn: dataEntry.entry[0] ? dataEntry.entry[0].fine_en : "",
        fineExplanEs: dataEntry.entry[0] ? dataEntry.entry[0].fine_es : "",
        dpExplanEn: dataEntry.entry[0] ? dataEntry.entry[0].dp_en : "",
        dpExplanEs: dataEntry.entry[0] ? dataEntry.entry[0].dp_es : "",
        prisonExplanEn: dataEntry.entry[0] ? dataEntry.entry[0].prison_en : "",
        prisonExplanEs: dataEntry.entry[0] ? dataEntry.entry[0].prison_es : "",
        otherExplanEn: dataEntry.entry[0] ? dataEntry.entry[0].other_en : "",
        otherExplanEs: dataEntry.entry[0] ? dataEntry.entry[0].other_es : "",
        enforcementEn: dataEntry.entry[0]
          ? dataEntry.entry[0].enforcement_en
          : "",
        enforcementEs: dataEntry.entry[0]
          ? dataEntry.entry[0].enforcement_es
          : "",
      });
    }
  }, [selectedJurisdiction, dataEntry.entry[0].id, jurisdictionId]);

  // find entry and lang from tiptaps
  // pass error to tip tap
  const [activeInDrawer, setActiveInDrawer] = useState("source");
  const [sourceLengthEn, setSourceLengthEn] = useState(0);
  const [sourceLengthEs, setSourceLengthEs] = useState(0);
  const [errorSources, setErrorSources] = useState("");

  useEffect(() => {
    const regex = /\*[^*]+\*/g;
    const sourcesEn = dataEntry.entries ? dataEntry.entries[0].explan_en : "";
    const matchesEn = sourcesEn.match(regex);
    const sourcesEs = dataEntry.entries ? dataEntry.entries[0].explan_es : "";
    const matchesEs = sourcesEs.match(regex);
    setSourceLengthEn(matchesEn ? matchesEn.length : 0);

    setSourceLengthEs(matchesEs ? matchesEs.length : 0);
  }, [dataEntry.entries]);

  const entry =
    dataEntry && dataEntry.entry && dataEntry.entry.length
      ? dataEntry.entry[0]
      : "";

  useEffect(() => {
    if (sourceLengthEn !== sourceLengthEs) {
      setErrorSources("Please add all sources in both languages");
    } else {
      setErrorSources("");
    }
  }, [sourceLengthEn, sourceLengthEs]);

  // moved all the code above

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const explanation = queryParams.get("explanation");
    if (explanation) setActiveTab(5);
  }, []);

  const [sources, setSources] = useState(entry.entries.sources);

  useEffect(() => {
    // global state =>
    window.desources = sources;
    console.log("entry.entries: ", entry.entries);
  }, [entry.entries.sources]);

  if (!loadingJurisdictions || !loadingOptions)
    return (
      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        width={"calc(100vw - 300px)"}
        height={"calc(100vh - 310px)"}
      />
    );

  const changeStyles = (input) => {
    const isRichText =
      input === "dpExplanEn" ||
      input === "dpExplanEs" ||
      input === "prisonExplanEn" ||
      input === "prisonExplanEs" ||
      input === "fineExplanEn" ||
      input === "fineExplanEs" ||
      input === "otherExplanEn" ||
      input === "otherExplanEs";
    if (!dataEntry.entry[0].original_child) return {};
    const originalProperty = (input, dataEntry) => {
      const entry = dataEntry.entry[0];
      const properties = {
        legal:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.legal,
        lastAmendment:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.last_amendment_1,
        lastAmendment2:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.last_amendment_2,
        decrimDate:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.decrim_date_1,
        decrimDate2:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.decrim_date_2,
        penalty:
          dataEntry.entry[0].original_child &&
          formData.penalty &&
          dataEntry.entry[0].original_child.entry_csssa_penalty_id,
        decrimMechanism:
          dataEntry.entry[0].original_child &&
          formData.decrimMechanism &&
          dataEntry.entry[0].original_child.legal_framework_decrim_mechanism_id,
        deathPenalty:
          dataEntry.entry[0].original_child &&
          formData.deathPenalty &&
          dataEntry.entry[0].original_child.entry_csssa_death_penalty_value_id,
        maxPrisonValue:
          dataEntry.entry[0].original_child &&
          formData.maxPrisonValue &&
          dataEntry.entry[0].original_child.entry_csssa_max_prison_value_id,
        maxPrisonYears:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.max_prison_years,
        otherPunishment:
          dataEntry.entry[0].original_child &&
          formData.otherPunishment &&
          dataEntry.entry[0].original_child
            .entry_csssa_other_punishment_value_id,
        hasFine:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.has_fine,
        dpExplanEn:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.dp_en,
        dpExplanEs:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.dp_es,
        prisonExplanEn:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.prison_en,
        prisonExplanEs:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.prison_es,
        fineExplanEn:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.fine_en,
        fineExplanEs:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.fine_es,
        otherExplanEn:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.other_en,
        otherExplanEs:
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child.other_es,
      };

      return properties[input];
    };
    const formValue = (input) => {
      if (input === "penalty" && formData.penalty)
        return formData.penalty.value;
      if (input === "decrimMechanism" && formData.decrimMechanism)
        return formData.decrimMechanism.value;
      if (input === "deathPenalty" && formData.deathPenalty)
        return formData.deathPenalty.value;
      if (input === "maxPrisonValue" && formData.maxPrisonValue)
        return formData.maxPrisonValue.value;
      if (input === "otherPunishment" && formData.otherPunishment)
        return formData.otherPunishment.value;

      if (isRichText && formData[input])
        return formData[input]
          .replace(/(<p>)/gi, "")
          .replace(/(<\/p>)/gi, "")
          .replace(/\r\n/gi, "")
          .replace(/\n/gi, "");

      return formData[input];
    };
    if (input === "prisonExplanEn")
      console.log(originalProperty(input, dataEntry), formValue(input));
    return {
      backgroundColor:
        originalProperty(input, dataEntry) != formValue(input) ? "#ffffcf" : "",
    };
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Card sx={{ width: "100%" }}>
        <Card sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            aria-label="Data entry form tabs"
          >
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="Death Penalty Explan" {...a11yProps(1)} />
            <Tab label="Prison Sentence Explan" {...a11yProps(2)} />
            <Tab label="Fine Explan" {...a11yProps(3)} />
            <Tab label="Other Punishment Explan" {...a11yProps(5)} />
            <Tab label="General Explan" {...a11yProps(5)} />
            <Tab label="Enforcement" {...a11yProps(6)} />
          </Tabs>
        </Card>

        <TabPanel value={activeTab} index={0} enabled={enabled}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                General Information
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3} style={changeStyles("legal")}>
              <FormControl fullWidth>
                <InputLabel htmlFor="legal">Legal*</InputLabel>
                <Select
                  id="legal"
                  name="legal"
                  label="Legal"
                  value={formData.legal}
                  onChange={handleInputChange}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              style={changeStyles("lastAmendment")}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearPicker
                  name="lastAmendment"
                  label="Last Amendment"
                  value={formData.lastAmendment}
                  onChange={(value) => {
                    handleYearChange("lastAmendment", value && value["$y"]);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              style={changeStyles("lastAmendment2")}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearPicker
                  name="lastAmendment2"
                  label="Illegal Since"
                  value={formData.lastAmendment2}
                  onChange={(value) => {
                    handleYearChange("lastAmendment2", value && value["$y"]);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={4} md={3} style={changeStyles("decrimDate")}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearPicker
                  name="decrimDate"
                  label="Decrim (unique/date in)"
                  value={formData.decrimDate}
                  onChange={(value) => {
                    handleYearChange("decrimDate", value && value["$y"]);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={4} md={3} style={changeStyles("decrimDate2")}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearPicker
                  name="decrimDate2"
                  label="Decrim (date out)"
                  value={formData.decrimDate2}
                  onChange={(value) => {
                    handleYearChange("decrimDate2", value && value["$y"]);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={3} style={changeStyles("penalty")}>
              <CustomizedSelect
                required
                options={penaltyOptions}
                mutate={handlePenaltyChange}
                value={selectedPenalty}
                name="penalty"
                label="Penalty"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={changeStyles("decrimMechanism")}
            >
              <CustomizedSelect
                required
                options={decrimMechanismOptions}
                mutate={handleDecrimMechanismChange}
                name="decrimMechanism"
                label="Decrim Mechanism"
                value={selectedDecrimMechanism}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={changeStyles("deathPenalty")}
            >
              <CustomizedSelect
                required
                options={deathPenaltyValueOptions}
                mutate={handleDeathPenaltyChange}
                name="deathPenalty"
                label="Death Penalty"
                value={selectedDeathPenalty}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={changeStyles("maxPrisonValue")}
            >
              <CustomizedSelect
                options={maxPrisonValueOptions}
                mutate={handleMaxPrisonValueChange}
                name="maxPrisonValue"
                label="Max Prison Time"
                value={selectedMaxPrisonTime}
              />
            </Grid>
            {selectedMaxPrisonTime && selectedMaxPrisonTime.value === 6 && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={changeStyles("maxPrisonYears")}
              >
                <FormControl fullWidth>
                  <TextField
                    id="maxPrisonYears"
                    name="maxPrisonYears"
                    label="Max Prison Years*"
                    type="number"
                    value={formData.maxPrisonYears}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={changeStyles("otherPunishment")}
            >
              <CustomizedSelect
                options={otherPunishmentOptions}
                mutate={handleOtherPunishmentChange}
                name={"otherPunishment"}
                label="Other Punishment"
                value={selectedOtherPunishment}
                isOptionEqualToValue={(option, value) => {
                  console.log("options: ", option);
                  console.log("value: ", value);
                  return option.value === value.value;
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2} style={changeStyles("hasFine")}>
              <FormGroup>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      name="hasFine"
                      checked={formData.hasFine}
                      onChange={(event) => {
                        console.log("event: ", event);
                        return handleCheckboxChange(event, "hasFine");
                      }}
                    />
                  }
                  label="Has Fine"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={1} enabled={enabled}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Death Penalty explanation
              </Typography>
            </Grid>

            <Grid item xs={12} xl={6} style={changeStyles("dpExplanEn")}>
              <Typography variant="h6">English</Typography>

              <RichTextTap
                content={formData.dpExplanEn}
                update={(thing) => {
                  setFormData({ ...formData, dpExplanEn: thing });
                }}
              />
            </Grid>

            <Grid item xs={12} xl={6} style={changeStyles("dpExplanEs")}>
              <Typography variant="h6" gutterBottom>
                Spanish
              </Typography>
              <RichTextTap
                content={formData.dpExplanEs}
                update={(thing) => {
                  setFormData({ ...formData, dpExplanEs: thing });
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={2} enabled={enabled}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Prison Sentence explanation
              </Typography>
            </Grid>
            <Grid item xs={12} xl={6} style={changeStyles("prisonExplanEn")}>
              <Typography variant="h6">English</Typography>
              <RichTextTap
                content={formData.prisonExplanEn}
                update={(thing) => {
                  setFormData({ ...formData, prisonExplanEn: thing });
                }}
              />
            </Grid>
            <Grid item xs={12} xl={6} style={changeStyles("prisonExplanEs")}>
              <Typography variant="h6" gutterBottom>
                Spanish
              </Typography>
              <RichTextTap
                content={formData.prisonExplanEs}
                update={(thing) => {
                  setFormData({ ...formData, prisonExplanEs: thing });
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={3} enabled={enabled}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    Fine Explanation
                  </Typography>
                </Grid>
                <Grid item xs={12} xl={6} style={changeStyles("fineExplanEn")}>
                  <Typography variant="h6">English</Typography>
                  <RichTextTap
                    content={formData.fineExplanEn}
                    update={(thing) => {
                      setFormData({ ...formData, fineExplanEn: thing });
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6} style={changeStyles("fineExplanEs")}>
                  <Typography variant="h6" gutterBottom>
                    Spanish
                  </Typography>
                  <RichTextTap
                    content={formData.fineExplanEs}
                    update={(thing) => {
                      setFormData({ ...formData, fineExplanEs: thing });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={4} enabled={enabled}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Other Punishment Explanation
              </Typography>
            </Grid>
            <Grid item xs={12} xl={6} style={changeStyles("otherExplanEn")}>
              <Typography variant="h6">English</Typography>
              <RichTextTap
                content={formData.otherExplanEn}
                update={(thing) => {
                  setFormData({ ...formData, otherExplanEn: thing });
                }}
              />
            </Grid>
            <Grid item xs={12} xl={6} style={changeStyles("otherExplanEs")}>
              <Typography variant="h6" gutterBottom>
                Spanish
              </Typography>
              <RichTextTap
                content={formData.otherExplanEs}
                update={(thing) => {
                  setFormData({ ...formData, otherExplanEs: thing });
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={5} enabled={enabled}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                General Explanation
              </Typography>
            </Grid>
            <Grid item xs={12} className="TipTap__box">
              <Typography variant="h6">English version</Typography>
              <TipTap
                content={formData.explan_en}
                entry={
                  dataEntry && dataEntry.entry && dataEntry.entry.length
                    ? dataEntry.entry[0]
                    : ""
                }
                mutate={mutate}
                hasSources={true}
                lang={"en"}
                columnName={"explan"}
                activeInDrawer={activeInDrawer}
                setActiveInDrawer={setActiveInDrawer}
                addingNewFootnote={addingNewFootnote}
                setAddingNewFootnote={setAddingNewFootnote}
              />
            </Grid>
            <Grid item xs={12} className="TipTap__box">
              <Typography variant="h6" gutterBottom>
                Spanish version
              </Typography>
              <TipTap
                content={formData.explan_es}
                entry={
                  dataEntry && dataEntry.entry && dataEntry.entry.length
                    ? dataEntry.entry[0]
                    : ""
                }
                mutate={mutate}
                hasSources={true}
                lang="es"
                columnName={"explan"}
                activeInDrawer={activeInDrawer}
                setActiveInDrawer={setActiveInDrawer}
                addingNewFootnote={addingNewFootnote}
                setAddingNewFootnote={setAddingNewFootnote}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={6} enabled={enabled}>
          <Grid container spacing={3} style={changeStyles("enforcementEn")}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Enforcement - TODO
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {errorSources && <p className="form__errors">{errorSources}</p>}
          {errors.length > 0 ? (
            <span className="form__errors">
              Missing mandatory fields: {errors.join(", ")}
            </span>
          ) : (
            ""
          )}

          <div className="form__actions">
            {!fromRevisions ? (
              enabled && (
                <>
                  {activeTab !== 5 && (
                    <Button
                      className="submit__btn draft__btn"
                      type="button"
                      onClick={(e) => handleFormSubmit(e, true)}
                      variant="contained"
                    >
                      {isLoadingEntry ? "loading" : "Save Draft"}
                    </Button>
                  )}

                  {activeTab !== 5 && (
                    <Button
                      className="submit__btn"
                      type="submit"
                      variant="contained"
                      disabled
                    >
                      {isLoadingEntry ? "loading" : "Submit for Revision"}
                    </Button>
                  )}
                </>
              )
            ) : (
              <>
                {activeTab !== 1 && (
                  <Button
                    className="submit__btn reject__btn"
                    type="button"
                    onClick={(e) => {}}
                    variant="contained"
                  >
                    {isLoadingEntry ? "loading" : "REJECT"}
                  </Button>
                )}

                {activeTab !== 1 && (
                  <Button
                    className="submit__btn draft__btn"
                    type="submit"
                    variant="contained"
                  >
                    {isLoadingEntry ? "loading" : "APPROVE"}
                  </Button>
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  allJurisdictions: state.selectOptions.allJurisdictions,
  allSubJurisdictions: state.selectOptions.allSubJurisdictions,
  allDecrimMechanisms: state.selectOptions.allDecrimMechanisms,
  penalties: state.selectOptions.penalties,
  otherPenalties: state.selectOptions.otherPenalties,
  maxPrisonValues: state.selectOptions.maxPrisonValues,
  deathPenaltyValues: state.selectOptions.deathPenaltyValues,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSubJurisdictions: (jurisdictionId) =>
    dispatch(getAllSubJurisdictions(jurisdictionId)),
  getLanguages: () => dispatch(getLanguages()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getTags: () => dispatch(getTags()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FullForm);
