import React, { useContext, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import { openFile, truncateName } from "../../../../../utils/functions";
import EyeIcon from "../../../Icons/EyeIcon";
import EditIcon from "../../../Icons/EditIcon";
import DeleteSourceModal from "../../../ConfirmationModal/DeleteSourceModal";
import { deleteEntrySource } from "../../../../Api/EntryApi";
import TipTapContext from "../../../../../shared/context/tipTap-context";
import { useHistory, useLocation } from "react-router-dom";
const SourceMarksRenderer = ({
  addingNewSource,
  editor,
  getMarks,
  currentSource,
  newSourceToAdd,
  setAddingNewSource,
  setCurrentSource,
  setNewSourceToAdd,
  updateSourcesNumber,
  lang,
  columnName,
  section,
  isInvalid,
}) => {
  const { ctxEntry, ctxColumn } = useContext(TipTapContext);
  const history = useHistory();
  const location = useLocation();
  const [theElements, setElements] = useState([]);
  const [thePlaceHolder, setThePlaceHolder] = useState(<></>);

  const deleteSource = (currentSrc) => {
    const order = currentSrc["data-original-source"];
    const sourcesDeleteId = ctxEntry.entries.sources
      .filter((s) => !section || s.section === section)
      .find((source) => source?.order === order).id;
    const text = ctxEntry.entries.sources
      .filter((s) => !section || s.section === section)
      .find((source) => source?.id === sourcesDeleteId).text;
    const { entry_id } = ctxEntry;
    console.log("should delete source with order -> ", order);
    console.log("should delete source entry -> ", sourcesDeleteId);
    console.log("should delete currentSource xwith entry -> ", entry_id);
    console.log("should delete currentSource with text -> ", text);

    const handleReload = () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("tab", columnName);
      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
      window.location.reload();
    };

    try {
      deleteEntrySource({
        sourcesDeleteId,
        entry_id,
        order,
        columnName,
        section,
        text,
      })
        .then((res) => {
          handleReload();
        })
        .catch((err) => console.log("error133333: ", err));
    } catch (e) {
      throw new Error("Error deleting source");
    }
  };

  useEffect(() => {
    if (editor) {
      const theMarks = getMarks(editor, "source").filter((e, index) => {
        return currentSource === null || currentSource === index;
      });

      if (theMarks.length) {
        updateSourcesNumber(theMarks.length);
      }

      console.log("SourceMarksRenderer: entry.entries - ", ctxEntry.entries);

      let oldCounter = 0;

      const elements = theMarks.map((e, index) => {
        let newE = { ...e };
        console.log("WWAAAA", newE);
        if (
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index] &&
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].order
        ) {
          newE["data-original-source"] = ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].order
            ? ctxEntry.entries.sources.filter(
                (s) => !section || s.section === section
              )[index].order
            : ctxEntry.entries.sources.filter(
                (s) => !section || s.section === section
              ).length + 1;
        }
        if (
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index] &&
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].original_official_filename
        ) {
          newE["data-original-official-filename"] =
            ctxEntry.entries.sources.filter(
              (s) => !section || s.section === section
            )[index].original_official_filename;
        }
        if (
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index] &&
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].original_filename
        ) {
          newE["data-original-filename"] = ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].original_filename;
        }
        if (
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index] &&
          ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].translated_filename
        ) {
          newE["data-translated-filename"] = ctxEntry.entries.sources.filter(
            (s) => !section || s.section === section
          )[index].translated_filename;
        }

        let currentEntry = [];

        if (lang === "en") {
          currentEntry = ctxEntry.entries.sources
            .filter((s) => !section || s.section === section)
            .filter((src) => {
              return src.text && src.text === e.text;
            });
        }

        const options = [
          newE["data-translated-filename"],
          newE["data-original-filename"],
          newE["data-original-official-filename"],
        ];

        const presentCount = options.filter(Boolean).length;

        const hasMultiple = presentCount >= 2;

        const filename = hasMultiple
          ? "[Multiple Files]"
          : options.find(Boolean);

        const editEntry = (event) => {
          event.preventDefault();

          setAddingNewSource(false);
          const targetValue = {
            ...newE,
            order:
              ctxEntry.entries.sources &&
              ctxEntry.entries.sources.filter(
                (s) => !section || s.section === section
              )[index] &&
              ctxEntry.entries.sources.filter(
                (s) => !section || s.section === section
              )[index].order
                ? ctxEntry.entries.sources.filter(
                    (s) => !section || s.section === section
                  )[index].order
                : ctxEntry.entries.sources.filter(
                    (s) => !section || s.section === section
                  ).length + 1,
          };

          setCurrentSource(targetValue);
        };

        if (currentEntry.length === 0) {
          oldCounter++;

          setTimeout(() => {
            if (editor)
              getMarks(editor, "source").filter((item) => {
                if (
                  item &&
                  item["data-original-source"] === "" &&
                  !currentSource
                ) {
                  console.log("item => ", item);
                  setAddingNewSource(true);
                  setNewSourceToAdd(item);
                }
              });
          }, 5);

          if (e && e.text === newSourceToAdd.text) {
            const targetValue = {
              ...newE,
              index: index,
              order:
                ctxEntry.entries.sources &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index] &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index].order
                  ? ctxEntry.entries.sources.filter(
                      (s) => !section || s.section === section
                    )[index].order
                  : ctxEntry.entries.sources.filter(
                      (s) => !section || s.section === section
                    ).length + 1,
              "original-official-language-id":
                ctxEntry.entries.sources &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index] &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index].original_official_language_id
                  ? ctxEntry.entries.sources.filter(
                      (s) => !section || s.section === section
                    )[index].original_official_language_id
                  : null,
              "original-language-id":
                ctxEntry.entries.sources &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index] &&
                ctxEntry.entries.sources.filter(
                  (s) => !section || s.section === section
                )[index].original_language_id
                  ? ctxEntry.entries.sources.filter(
                      (s) => !section || s.section === section
                    )[index].original_language_id
                  : null,
            };

            setCurrentSource(targetValue);
          }

          return (
            <div
              key={`what${index + 1}`}
              className={`DrawerEntry ${
                newSourceToAdd && e && e.text === newSourceToAdd.text
                  ? "active"
                  : ""
              } `}
              onClick={(event) => {
                if (e && e.text === newSourceToAdd.text && hasMultiple) {
                  const targetValue = {
                    ...newE,
                    index: index,
                    order:
                      ctxEntry.entries.sources &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index] &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index].order
                        ? ctxEntry.entries.sources.filter(
                            (s) => !section || s.section === section
                          )[index].order
                        : ctxEntry.entries.sources.filter(
                            (s) => !section || s.section === section
                          ).length + 1,
                    "original-official-language-id":
                      ctxEntry.entries.sources &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index] &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index].original_official_language_id
                        ? ctxEntry.entries.sources.filter(
                            (s) => !section || s.section === section
                          )[index].original_official_language_id
                        : null,
                    "original-language-id":
                      ctxEntry.entries.sources &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index] &&
                      ctxEntry.entries.sources.filter(
                        (s) => !section || s.section === section
                      )[index].original_language_id
                        ? ctxEntry.entries.sources.filter(
                            (s) => !section || s.section === section
                          )[index].original_language_id
                        : null,
                  };

                  setCurrentSource(targetValue);
                }
              }}
            >
              <b>Source {index + 1}</b>&nbsp; -{" "}
              {e && e.text && truncateName(e.text)}
              {!addingNewSource ? (
                <>
                  {!hasMultiple ? (
                    <button
                      aria-label="Download File"
                      onClick={(event) =>
                        filename ? openFile(event, filename) : ""
                      }
                      className="link"
                    >
                      {filename ? truncateName(filename) : "ERROR"}
                    </button>
                  ) : (
                    <button
                      aria-label="Open File Drawer"
                      onClick={editEntry}
                      className="link"
                    >
                      {filename ? truncateName(filename) : "ERROR"}
                    </button>
                  )}

                  <div className="quickBtns">
                    <Tooltip title="View">
                      <IconButton
                        aria-label="view"
                        diabled={hasMultiple.toString() || !filename}
                        onClick={(event) => {
                          if (hasMultiple) {
                            editEntry(event);
                          } else if (filename) {
                            openFile(event, filename);
                          }
                        }}
                      >
                        <EyeIcon />
                      </IconButton>
                    </Tooltip>

                    {lang !== "es" && (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={(evt) => {
                            console.log("run edit action");
                            editEntry(evt);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <DeleteSourceModal id={newE} deleteAction={deleteSource} />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          );
        }

        return (
          <div key={`what${index + 1}`} className="DrawerEntry">
            <strong>Source {index + 1}</strong>&nbsp; - {truncateName(e.text)}{" "}
            {!hasMultiple && filename ? (
              <button
                aria-label="Download File"
                onClick={() => {
                  if (filename) {
                    console.log("Download named file");
                    window.open(
                      `https://dbtest.naftecbr.com/api/downloader/download/1/${filename}`,
                      "_blank"
                    );
                  }
                }}
                className="link"
              >
                {filename ? truncateName(filename) : ""}
              </button>
            ) : (
              <button
                aria-label="Open File Drawer"
                onClick={editEntry}
                className="link"
              >
                {filename ? truncateName(filename) : ""}
              </button>
            )}
            <div className="quickBtns">
              <Tooltip title="View">
                <IconButton
                  className={hasMultiple ? "hideView" : ""}
                  aria-label="view"
                  diabled={hasMultiple.toString() || !filename}
                  onClick={(event) => {
                    console.log("filename: ", filename);
                    if (filename) {
                      openFile(event, filename);
                    }
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  className={lang === "es" ? "hideView" : ""}
                  onClick={(evt) => {
                    console.log("evt: ", evt);
                    editEntry(evt);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <DeleteSourceModal id={newE} deleteAction={deleteSource} />
            </div>
          </div>
        );
      });

      setElements(elements);
    }
  }, [editor, addingNewSource, currentSource, ctxEntry]);

  useEffect(() => {
    if (isInvalid && currentSource && ctxEntry) {
      let containingSource = null;
      if (lang === "es" && currentSource)
        containingSource = ctxEntry.entries.sources.filter(
          (source) =>
            source.order === currentSource.order &&
            (!section || source.section === section)
        )[0];
      
      if (containingSource) setThePlaceHolder(<></>);
    } else setThePlaceHolder(<>Select the spanish text for the source</>);
  }, [editor, addingNewSource, currentSource, ctxEntry]);

  if (isInvalid) {
    return <>{thePlaceHolder}</>;
  } else return <>{theElements}</>;
};

export default SourceMarksRenderer;
