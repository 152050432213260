import { Mark, mergeAttributes } from "@tiptap/core";

export const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/;
export const starPasteRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))/g;
export const underscoreInputRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))$/;
export const underscorePasteRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g;

const replace = (editor, me) => {
  const asteriskRegex = /((?:\*)((?:[^*]+))(?:\*))/g;
  let i = 0;
  editor.state.doc.descendants((node, pos) => {
    if (node.isText) {
      let match;

      while ((match = asteriskRegex.exec(node.text)) !== null) {
        const startPos = pos + match.index - i * 2;
        const endPos = startPos + match[0].length;

        const from = startPos; // Exclude starting asterisk
        const to = endPos; // Exclude ending asterisk

        const actualSource = window.desources.filter(
          (source) => match[2] === source.text
        );

        editor.commands.deleteRange({ from: from, to: to });
        editor.commands.insertContentAt(
          from,
          `<custom-source data-original-source="${
            actualSource && actualSource.length ? actualSource[0].order - 1 : i
          }" class="source">${match[2].replace(/\*/gi, "")}</custom-source>`
        );

        editor.commands.toggleSource();

        i++;
      }
    }
  });
};

export const Source = Mark.create({
  name: "source",
  exitable: true,
  spanning: false,
  onCreate({ editor }) {
    // console.log("editor: ", editor)
    replace(editor, this);

    this.editor.on("transaction", ({ transaction, editor }) => {
      return replace(editor, this);
    });

    this.editor.on("selectionUpdate", ({ transaction, editor }) => {
      if (
        editor.state.selection.$anchor === editor.state.selection.$head && //fix for when selecting multiple to not dissappear
        !editor.state.selection.$anchor
          .marks()
          .find((m) => m.type.name === "source")
      ) {
        editor.commands.unsetSource();
      }
    });
  },

  addOptions() {
    return {
      HTMLAttributes: { class: "source" },
    };
  },

  parseHTML() {
    return [
      {
        tag: "custom-source",
        attrs: {
          class: "source",
        },
      },
    ];
  },

  addAttributes() {
    return {
      "data-original-source": {
        default: "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "custom-source",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setSource:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleSource:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetSource:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-m": () => this.editor.commands.toggleSource(),
      "Mod-M": () => this.editor.commands.toggleSource(),
    };
  },
});
