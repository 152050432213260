import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import { utc } from "moment-timezone";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  approveArticle,
  assignToMotherInboxDashboard,
  convertToMother,
  deleteArticle,
  removeDashMother,
  removeFromMother,
  removeMultipleFromMother,
  setArticleCorrection,
  setPossibleLegalDevelopment,
} from "../actions/articles";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  CRIM_ENFORCEMENT_TAG,
  isCrimCountry,
  jurWarnings,
} from "../utils/functions";
import DashParentConfirm from "./DashParentConfirm";
import "./InboxCard.css";
import JurButtons from "./JurButtons";
import TagButtons from "./TagButtons";

const PREFIX = "InboxCard";

const classes = {
  root: `${PREFIX}-root`,
  rootR: `${PREFIX}-rootR`,
  rootM: `${PREFIX}-rootM`,
  rootLE: `${PREFIX}-rootLE`,
  rootCrim: `${PREFIX}-rootCrim`,
  rootCorrection: `${PREFIX}-rootCorrection`,
  content: `${PREFIX}-content`,
  valignCenter: `${PREFIX}-valignCenter`,
  actions: `${PREFIX}-actions`,
  expand: `${PREFIX}-expand`,
  header: `${PREFIX}-header`,
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  star: `${PREFIX}-star`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    paddingBottom: "0px",
    borderRadius: "5px",
  },

  [`& .${classes.rootR}`]: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    borderRadius: "5px",
    paddingBottom: "0px",
    backgroundColor: "#b7cfff",
  },

  [`& .${classes.rootM}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "rgb(255, 252, 148)",
  },

  [`& .${classes.rootLE}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#CFFFAB",
  },

  [`& .${classes.rootCrim}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#FFDDC3",
  },

  [`& .${classes.rootCorrection}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#FF876F",
  },

  [`& .${classes.content}`]: {
    paddingTop: "10px",
    "&:last-child": {
      paddingBottom: "10px",
    },
  },

  [`& .${classes.valignCenter}`]: {
    display: "inline-flex",
    verticalAlign: "middle",
    alignItems: "center",
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    backgroundColor: "#fafafa",
  },

  [`& .${classes.expand}`]: {
    marginLeft: "auto",
    fontSize: "1.5rem",
    minWidth: "50px",
  },

  [`& .${classes.header}`]: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "navy",
    fontWeight: "bold",
  },

  [`& .${classes.modal}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.dialogTitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.star}`]: {
    "&:hover": {
      color: "#ffe07c",
    },
  },
}));

function InboxCard({
  error,
  article,
  sources,
  source_types,
  jurisdictions,
  languages,
  inboxPosition,
  loading,
  reassign,
  setReassign,
  addReassignChildren,
  toReassign,
  reassignChildren,
  removeReassignChildren,
  removeMultipleFromMother,
  deleteArticle,
  setUndoDeleteArticle,
  setPossibleLegalDevelopment,
  approveArticle,
  setUndoApproveArticle,
  removeFromMother,
  convertToMother,
  setSuccess,
  success,
  motherPosition,
  setArticleNewJur,
  setArticleNewTag,
  setCurrentArticle,
  setNewTrigger,
  multipleRemove,
  setMultipleRemove,
  mother,
  posfix,
  users,
  inbxHack,
  assignToMotherInboxDashboard,
  childIndex = 0,
  removeDashMother,
  articleCorrection,
  setArticleCorrection,
  setArticleCorrectionAction,
  childListRef,
  initialScroll,
}) {
  const [expanded, setExpanded] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [webview, setWebview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const childList = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteArticleJur, setDeleteArticleJur] = React.useState(null);
  const [deleteArticleTag, setDeleteArticleTag] = React.useState(null);
  const [addArticleJur, setAddArticleJur] = React.useState(null);
  const [timedFn, setTimedFn] = React.useState(() => {});
  const [selectedText, setSelectedText] = React.useState("");

  const [dashParentOpen, setDashParentOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const listRef = useRef();

  useEffect(() => {
    if (
      success &&
      success.data &&
      ((success.data.action === "newmother" &&
        success.data.article.id === article.id) ||
        (success.data.action === "editarticle" &&
          success.data.article.parent_article_id === article.id) ||
        (success.data.action === "removefrommother" &&
          success.data.parent_article_id === article.id) ||
        (success.data.action === "discardchild" &&
          success.data.parent_article_id === article.id))
    ) {
      setExpanded(true);
      if (success.data.childIndex) {
        setScrollTop(success.data.childIndex - 1);
        console.log(childListRef, "AAAA", success.data.childIndex);
      }
    }
  }, [success]);

  useEffect(() => {
    if (!article.parent_article_id)
      //Hack for react-virtualized
      setMultipleRemove([]);
  }, [expanded]);

  useEffect(() => {
    if (error) setUndoApproveArticle(null);
  }, [error]);

  useEffect(() => {
    if (article && article.id === 633568) {
      console.log("reloaddd!");
    }
  }, []);
  useEffect(() => {
    /*if (reassignChildren.length && listRef.current) {
      alert(listRef);
      listRef.current.forceUpdateGrid();
    }
    */
  }, [reassignChildren]);

  const emptySelectedText = () => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }

    setSelectedText();
  };

  const selectionAction = (event) => {
    let selText = window.getSelection().toString();

    clearTimeout(timedFn);
    setSelectedText(selText);
    //setTimedFn(setTimeout(() => emptySelectedText(), 10000));
  };

  const addTrigger = (type) => {
    let selected = selectedText;
    clearTimeout(timedFn);
    setNewTrigger({ type, text: selectedText });
    emptySelectedText();
  };

  const confirm = useConfirm();

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];
  let st;
  let jur;
  if (article.source) {
    st = source_types.find(
      (st) => st.id === article.source.source_type_id
    ).code;

    jur = jurisdictions.find(
      (jur) => jur.id === article.source.jurisdiction_id
    );
  }

  let articleJurisdictions;
  let global_jur = null;
  let con_jur = null;
  if (article.jurisdictions && article.jurisdictions.length) {
    if (article.jurisdictions.length > 3)
      global_jur = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur = jur;
  }

  let lang = article.g_detected_language
    ? article.g_detected_language
    : article.detected_language;

  if (lang) {
    let langTemp = languages.find((l) => l.id === lang);
    if (langTemp) lang = langTemp.name;
  }

  const isChosenReassign = reassign && reassign.id === article.id;

  let username;
  let user;
  if (users) {
    user = users.find((u) => u.id === article.user_id);
    if (user) username = user.name.split(" ")[0];
    //user.name.split(" ")[0][0] + ". " + user.name.split(" ")[1][0] + ". ";
    else username = "N/A";
  }
  const { height, width } = useWindowDimensions();

  const assignToMother = (convert) => {
    assignToMotherInboxDashboard(
      article.dash_parent_article_id,
      article.id,
      convert
    );
  };

  const approveProcess = (article) => {
    setUndoApproveArticle(null);
    if (inbxHack) {
      approveArticle({ ...article, inbxHack: true });
      setUndoApproveArticle({
        ...article,
        inbxHack: true,
        parent_article_id_old: article.parent_article_id,
      });
    } else {
      approveArticle(article);
      setUndoApproveArticle({
        ...article,
        parent_article_id_old: article.parent_article_id,
      });
    }
  };

  useEffect(() => {
    if (scrollTop && childList.current) childList.current.scroll(0, scrollTop);
  }, [scrollTop]);

  return (
    <Root>
      {article.article_content && article.article_content.content && (
        <Dialog
          contentStyle={{
            width: "80%",
            maxWidth: "none",
          }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open || false}
          onClose={handleClose}
          closeAfterTransition
          maxWidth="lg"
          title={
            <div>
              <img
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "5px",
                  width: "20px",
                }}
              />
            </div>
          }
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <h2>{article.article_content.title}</h2>
            <IconButton onClick={handleClose} size="large">
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Fade in={open}>
              <div className={classes.paper}>
                <p
                  id="transition-modal-description"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {article.article_content.content.replace(/<br \/>/g, "\n")}
                </p>
              </div>
            </Fade>
          </DialogContent>
        </Dialog>
      )}

      <Card
        className={
          !toReassign
            ? isChosenReassign
              ? classes.rootM
              : multipleRemove.length &&
                multipleRemove.find((a) => a.id === article.id)
              ? classes.rootR
              : posfix === "_DASHBOARD" && article.article_correction_id
              ? classes.rootCorrection
              : posfix === "_DASHBOARD" && article.possible_legal_development
              ? classes.rootLE
              : isCrimCountry(article, con_jur)
              ? classes.rootCrim
              : classes.root
            : classes.rootR
        }
        style={{ ...(article.parent_article_id && { minHeight: "120px" }) }}
        onMouseUp={selectionAction}
      >
        <CardContent
          className={classes.content}
          style={{ position: "relative" }}
        >
          {posfix === "_DASHBOARD" && article.articles && (
            <span
              style={{
                position: "absolute",
                right: "2px",
                bottom: "1px",
                fontSize: "10px",
              }}
            >
              {article.article_correction_id ? "Correction Needed - " : ""}
              {username}
            </span>
          )}
          <span
            style={{
              position: "absolute",
              right: "2px",
              top: "2px",
              fontSize: "14px",
              padding: "4px 4px 1px",
              backgroundColor: "lightyellow",
              border: "1px solid #ccc",
              borderRadius: "15px",
            }}
          >
            {article.parent_article_id && childIndex + 1}
          </span>
          <Grid container spacing={3} className={classes.valignCenter}>
            <Grid item md={2}>
              <Grid
                container
                spacing={2}
                className={classes.valignCenter}
                onMouseOver={(e) => setAddArticleJur(article)}
                onMouseOut={(e) => setAddArticleJur(null)}
              >
                <Grid item md={3} style={{ textAlign: "center" }}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="h6"
                    style={{ textAlign: "center" }}
                  >
                    {posfix !== "_DASHBOARD" ? (
                      <time datetime="2014-09-20" className="icon">
                        <strong>
                          {utc(article.digest_date).format("MMM")}
                        </strong>
                        <span>{utc(article.digest_date).format("DD")}</span>
                      </time>
                    ) : (
                      <time datetime="2014-09-20" className="icon">
                        <strong>
                          {utc(article.digest_date).format("MMM") +
                            " " +
                            utc(article.digest_date).format("DD")}
                        </strong>
                        <span style={{ fontSize: "15px", paddingTop: "22px" }}>
                          {utc(article.digest_date).format("YYYY")}
                        </span>
                      </time>
                    )}

                    {false && posfix === "_DASHBOARD" && article.articles && (
                      <Typography gutterBottom variant="body3">
                        {username}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    textAlign: "center",
                    ...(posfix === "_DASHBOARD" &&
                      !article.articles && { visibility: "hidden" }),
                  }}
                >
                  {global_jur && (
                    <Fragment>
                      <img
                        width="32px"
                        src={
                          "/img/country_flags/" +
                          global_jur.country_code +
                          "@3x.png"
                        }
                      />

                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {global_jur.country_code}
                      </span>
                    </Fragment>
                  )}

                  {!global_jur &&
                    !con_jur &&
                    article.jurisdictions &&
                    article.jurisdictions.length > 1 && (
                      <Fragment>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) =>
                            setDeleteArticleJur(article.jurisdictions[0].id)
                          }
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={
                              "/img/country_flags/" +
                              article.jurisdictions[0].jurisdiction
                                .country_code +
                              "@3x.png"
                            }
                          />
                          {!article.parent_article_id && (
                            <JurButtons
                              article={article}
                              posfix={posfix}
                              jur={article.jurisdictions[0].jurisdiction}
                              show={
                                article.jurisdictions[0].id === deleteArticleJur
                              }
                              setArticleNewJur={setArticleNewJur}
                            />
                          )}
                        </div>
                        <span style={{ fontWeight: "bold" }}>
                          {article.jurisdictions[0].jurisdiction.country_code}
                        </span>
                      </Fragment>
                    )}
                </Grid>
                <Grid
                  item
                  md={global_jur ? 6 : 3}
                  style={{
                    textAlign: "center",
                    ...(posfix === "_DASHBOARD" &&
                      !article.articles && { visibility: "hidden" }),
                  }}
                >
                  {global_jur && (
                    <Grid container>
                      {article.jurisdictions &&
                        article.jurisdictions.map((art, index) => (
                          <Grid item md={4} style={{ textAlign: "center" }}>
                            <div
                              style={{ position: "relative" }}
                              onMouseOver={(e) =>
                                setDeleteArticleJur(
                                  article.jurisdictions[index].id
                                )
                              }
                              onMouseOut={(e) => setDeleteArticleJur(null)}
                            >
                              <img
                                width="32px"
                                src={
                                  "/img/country_flags/" +
                                  article.jurisdictions[index].jurisdiction
                                    .country_code +
                                  "@3x.png"
                                }
                              />
                              {!article.parent_article_id && (
                                <JurButtons
                                  article={article}
                                  posfix={posfix}
                                  jur={
                                    article.jurisdictions[index].jurisdiction
                                  }
                                  show={
                                    article.jurisdictions[index].id ===
                                    deleteArticleJur
                                  }
                                  setArticleNewJur={setArticleNewJur}
                                />
                              )}
                            </div>
                            <span style={{ fontWeight: "bold" }}>
                              {
                                article.jurisdictions[index].jurisdiction
                                  .country_code
                              }
                            </span>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                  {con_jur && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(con_jur.id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            con_jur.country_code +
                            "@3x.png"
                          }
                        />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={con_jur}
                            show={con_jur.id === deleteArticleJur}
                            setArticleNewJur={setArticleNewJur}
                            con={true}
                          />
                        )}
                        <br />
                        <span style={{ color: "darkred", fontWeight: "bold" }}>
                          {con_jur.country_code}
                        </span>
                      </div>
                    </Fragment>
                  )}
                  {!global_jur &&
                    !con_jur &&
                    article.jurisdictions &&
                    article.jurisdictions.length === 1 && (
                      <Fragment>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) =>
                            setDeleteArticleJur(article.jurisdictions[0].id)
                          }
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={
                              "/img/country_flags/" +
                              article.jurisdictions[0].jurisdiction
                                .country_code +
                              "@3x.png"
                            }
                          />
                          {!article.parent_article_id && (
                            <JurButtons
                              article={article}
                              posfix={posfix}
                              jur={article.jurisdictions[0].jurisdiction}
                              show={
                                article.jurisdictions[0].id === deleteArticleJur
                              }
                              setArticleNewJur={setArticleNewJur}
                            />
                          )}
                        </div>
                        <span style={{ fontWeight: "bold" }}>
                          {article.jurisdictions[0].jurisdiction.country_code}
                        </span>
                      </Fragment>
                    )}
                  {!global_jur && !con_jur && article.jurisdictions[1] && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) =>
                          setDeleteArticleJur(article.jurisdictions[1].id)
                        }
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            article.jurisdictions[1].jurisdiction.country_code +
                            "@3x.png"
                          }
                        />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={article.jurisdictions[1].jurisdiction}
                            show={
                              article.jurisdictions[1].id === deleteArticleJur
                            }
                            setArticleNewJur={setArticleNewJur}
                          />
                        )}
                      </div>
                      <span style={{ fontWeight: "bold" }}>
                        {article.jurisdictions[1].jurisdiction.country_code}
                      </span>
                    </Fragment>
                  )}
                </Grid>
                {!global_jur && (
                  <Grid
                    item
                    md={3}
                    style={{
                      textAlign: "center",
                      ...(posfix === "_DASHBOARD" &&
                        !article.articles && { visibility: "hidden" }),
                    }}
                  >
                    {" "}
                    {!global_jur && !con_jur && article.jurisdictions[2] && (
                      <Fragment>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) =>
                            setDeleteArticleJur(article.jurisdictions[2].id)
                          }
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={
                              "/img/country_flags/" +
                              article.jurisdictions[2].jurisdiction
                                .country_code +
                              "@3x.png"
                            }
                          />
                          {!article.parent_article_id && (
                            <JurButtons
                              article={article}
                              posfix={posfix}
                              jur={article.jurisdictions[2].jurisdiction}
                              show={
                                article.jurisdictions[2].id === deleteArticleJur
                              }
                              setArticleNewJur={setArticleNewJur}
                            />
                          )}
                        </div>
                        <span style={{ fontWeight: "bold" }}>
                          {article.jurisdictions[2].jurisdiction.country_code}
                        </span>
                      </Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={0} className={classes.valignCenter}>
                <Grid
                  item
                  md={1}
                  style={{ textAlign: "center", maxWidth: "48px" }}
                >
                  <Fragment>
                    {" "}
                    {article.article_content &&
                      article.article_content.content && (
                        <IconButton size="small" onClick={(e) => setOpen(true)}>
                          <Icon>subject</Icon>
                        </IconButton>
                      )}
                  </Fragment>
                </Grid>
                <Grid item md={11}>
                  <Grid container spacing={3} className={classes.valignCenter}>
                    <Grid item md={7}>
                      <a
                        className={classes.header}
                        onClick={(e) => {
                          if (article.detected_language !== "en")
                            window.open(
                              "https://translate.google.com/translate?js=n&sl=auto&tl=en&u=" +
                                article.original_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          else {
                            window.open(
                              article.original_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                        >
                         {article.translated_header ||
                            article.original_header ||
                            article.original_url}{" "}
                        </Typography>
                      </a>
                      <Typography
                        gutterBottom
                        variant="body3"
                        style={{ fontStyle: "italic" }}
                        component="h6"
                      >
                        {article.translated_header && (
                          <a
                            className={classes.header}
                            onClick={(e) => {
                              window.open(
                                article.original_url,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {article.original_header}
                          </a>
                        )}
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{ fontSize: "0.6rem" }}
                        component="h6"
                      >
                        {article.original_description}
                      </Typography>
                      {selectedText && (
                        <Fragment>
                          {false && (
                            <Fragment>
                              <Button
                                color="primary"
                                size="small"
                                onClick={(e) => addTrigger("jur")}
                              >
                                + JUR TRIGGER
                              </Button>
                              <Button
                                style={{ color: "black" }}
                                size="small"
                                onClick={(e) => addTrigger("con")}
                              >
                                + CON TRIGGER
                              </Button>
                              <Button
                                color="secondary"
                                size="small"
                                onClick={(e) => addTrigger("tag")}
                              >
                                + TAG TRIGGER
                              </Button>
                            </Fragment>
                          )}

                          <Button
                            style={{ color: "green" }}
                            size="small"
                            onClick={(e) => addTrigger("tier")}
                          >
                            + TIER TRIGGER
                          </Button>
                        </Fragment>
                      )}
                    </Grid>
                    <Grid item md={5}>
                      <Grid container spacing={1}>
                        <Grid item md={5}>
                          {article.source ? (
                            <Fragment>
                              <Typography
                                gutterBottom
                                variant="body2"
                                style={{ fontStyle: "italic" }}
                                component="span"
                              >
                                {article.source.name}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body3"
                                component="h6"
                              >
                                {st} -{" "}
                                <img
                                  width="14px"
                                  style={{ verticalAlign: "middle" }}
                                  src={
                                    "/img/country_flags/" +
                                    jur.country_code +
                                    ".png"
                                  }
                                />{" "}
                                - {jur.name}
                              </Typography>
                              {article.source.media_types &&
                                article.source.media_types.length > 0 && (
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="h6"
                                  >
                                    {article.source.media_types
                                      .map((mt) => mt.name)
                                      .join(" - ")}
                                  </Typography>
                                )}
                              {article.source.ilga_m > 0 && (
                                <Typography
                                  gutterBottom
                                  variant="body3"
                                  component="h6"
                                >
                                  {article.source.ilga_m === 1
                                    ? "ILGA Member"
                                    : "NON-ILGA Member"}
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            ""
                          )}

                          <Typography
                            gutterBottom
                            variant="body3"
                            component="h6"
                          >
                            <Icon
                              onClick={() =>
                                setPossibleLegalDevelopment(
                                  article,
                                  article.possible_legal_development ? 0 : 1,
                                  posfix
                                )
                              }
                              className={classes.star}
                              style={{
                                ...(article.possible_legal_development
                                  ? {
                                      color: "#FFC300",
                                    }
                                  : {
                                      color: "#5D5D5D",
                                    }),
                                fontSize: "24px",
                                cursor: "pointer",
                              }}
                            >
                              star
                            </Icon>
                            {article.possible_legal_development
                              ? " Possible Legal Dev."
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={7}
                          style={{
                            ...(posfix === "_DASHBOARD" &&
                              !article.articles && { visibility: "hidden" }),
                          }}
                        >
                          <Grid container>
                            <Grid item md={12}>
                              <Typography
                                gutterBottom
                                variant="body2"
                                component="h6"
                              >
                                {article.tags && article.tags.length > 0 ? (
                                  article.tags.map((t) => (
                                    <Fragment>
                                      <div
                                        style={{
                                          border: "1px solid lightgray",
                                          backgroundColor: "#c5dce1",
                                          paddingTop: "2px",
                                          paddingBottom: "2px",
                                          fontSize: "0.775rem",
                                          margin: "1px",

                                          position: "relative",
                                          display: "inline-block",
                                          minWidth: "50px",
                                          textAlign: "center",
                                        }}
                                        onMouseOver={(e) =>
                                          setDeleteArticleTag(t.id)
                                        }
                                        onMouseOut={(e) =>
                                          setDeleteArticleTag(null)
                                        }
                                      >
                                        <TagButtons
                                          posfix={posfix}
                                          article={article}
                                          tag={t.tag}
                                          show={t.id === deleteArticleTag}
                                          setArticleNewTag={setArticleNewTag}
                                        />
                                        <span style={{ padding: "4px" }}>
                                          {t.tag.tag.toUpperCase()}
                                        </span>
                                      </div>
                                    </Fragment>
                                  ))
                                ) : (
                                  <Fragment>
                                    <div
                                      style={{
                                        margin: "1px",

                                        position: "relative",
                                        display: "inline-block",
                                        minWidth: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <TagButtons
                                        posfix={posfix}
                                        article={article}
                                        tag={null}
                                        show={true}
                                        setArticleNewTag={setArticleNewTag}
                                      />
                                    </div>
                                  </Fragment>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={1}>
              <Grid container>
                <Grid item md={6} style={{ marginTop: "8px" }}>
                  <Typography gutterBottom variant="body2" component="span">
                    {!sources.find((s) => s.id === article.source_id) ||
                    sources
                      .find((s) => s.id === article.source_id)
                      .languages.find((l) => l.name === lang) ? (
                      lang
                    ) : (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {lang}
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {article.articles && (
                    <Typography gutterBottom variant="body2" component="h6">
                      <div className={classes.valignCenter}>
                        <Button
                          style={{ color: "gray" }}
                          onClick={(e) => setExpanded(!expanded)}
                        >
                          <Icon>list_alt</Icon> {article.articles.length}
                        </Button>
                      </div>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              {!reassign && article.articles && (
                <Button
                  className={classes.expand}
                  style={{ color: "darkblue" }}
                  onClick={(e) => setReassign(article)}
                  title="Assign Children"
                >
                  <Icon fontSize="inherit">device_hub_rounded</Icon>
                </Button>
              )}

              {article.dash_mother &&
                article.dash_parent_article_id &&
                article.articles && (
                  <>
                    <Button
                      style={{ color: "darkgreen", marginLeft: "-8px" }}
                      onClick={(e) => setDashParentOpen(true)}
                    >
                      <Icon>account_balance_wallet</Icon>
                    </Button>
                    <DashParentConfirm
                      open={dashParentOpen}
                      setOpen={setDashParentOpen}
                      title="Potential mother detected in dashboard"
                      assignToMother={assignToMother}
                      article={article.dash_mother}
                      jurisdictions={jurisdictions}
                      removeDashMother={removeDashMother}
                      child={article}
                    />
                  </>
                )}

              {!article.dash_parent_article_id &&
                article.articles &&
                !isChosenReassign &&
                !toReassign &&
                (posfix !== "_DASHBOARD" ||
                  (posfix === "_DASHBOARD" && inbxHack)) && (
                  <Button
                    size="small"
                    style={{
                      color: "darkgreen",
                    }}
                    className={classes.expand}
                    title="Approve"
                    onClick={(e) => {
                      if (article.tags.length > 6)
                        confirm({
                          cancellationText: "",
                          title: "Too many tags",
                          description:
                            "Maximum tags allowed: 6. Please remove some tags before proceeding",
                        })
                          .then(() => {})
                          .catch(() => {});
                      else if (article.tags.length === 0)
                        confirm({
                          cancellationText: "",
                          title: "No tags",
                          description:
                            "Warning. You are trying to approve an entry without any tags. All entries need at least one tag in order to be approved.",
                        })
                          .then(() => {})
                          .catch(() => {});
                      else if (
                        article.tags
                          .map((t) => t.tag_id)
                          .indexOf(CRIM_ENFORCEMENT_TAG) !== -1
                      )
                        confirm({
                          cancellationText: "Let me review",
                          confirmationText: "Confirm",
                          title: "CRIM. ENFORCEMENT tag confirmation",
                          description: (
                            <Fragment>
                              You are about to accept an entry with the CRIM.
                              ENFORCEMENT tag. Please make sure that all
                              requirements for this tag are met namely: <br />
                              <br />
                              1) That all people involved in the act are adults{" "}
                              <br />
                              2) That acts in question were, beyond any doubt,
                              consensual in nature.
                            </Fragment>
                          ),
                        })
                          .then(() => {
                            approveProcess(article);
                          })
                          .catch(() => {});
                      else if (
                        article.jurisdictions.find(
                          (j) => jurWarnings[j.jurisdiction_id]
                        )
                      )
                        confirm({
                          cancellationText: "Let me review",
                          confirmationText: "Confirm",
                          title: "Jurisdiction confirmation",
                          description:
                            jurWarnings[
                              article.jurisdictions.find(
                                (j) => jurWarnings[j.jurisdiction_id]
                              ).jurisdiction_id
                            ],
                        })
                          .then(() => {
                            approveProcess(article);
                          })
                          .catch(() => {});
                      else {
                        approveProcess(article);
                      }
                    }}
                  >
                    <Icon fontSize="inherit">check_box_rounded</Icon>
                  </Button>
                )}

              {reassign && !isChosenReassign && !toReassign && (
                <Button
                  className={classes.expand}
                  onClick={(e) => addReassignChildren(article)}
                >
                  <Icon fontSize="inherit">merge_type</Icon>
                </Button>
              )}
              {reassign && !isChosenReassign && toReassign && (
                <Button
                  className={classes.expand}
                  onClick={(e) => removeReassignChildren(article.id)}
                >
                  <Icon fontSize="inherit">close</Icon>
                </Button>
              )}
              {!isChosenReassign && !toReassign && article.articles && (
                <Fragment>
                  <Button
                    size="small"
                    style={{ color: "darkgrey" }}
                    color="primary"
                    className={classes.expand}
                    title="More"
                    onClick={(event) => {
                      setCurrentArticle(article);
                      setAnchorEl(null);
                    }}
                  >
                    <Icon style={{ color: "darkgrey" }} fontSize="inherit">
                      build_rounded
                    </Icon>
                  </Button>
                </Fragment>
              )}
              {!isChosenReassign && !toReassign && !article.articles && (
                <Fragment>
                  <Button
                    size="small"
                    style={{
                      color: "darkgrey",
                      ...(multipleRemove.length
                        ? { visibility: "hidden" }
                        : {}),
                    }}
                    color="primary"
                    className={classes.expand}
                    onClick={(e) => setCurrentArticle(article)}
                    title="Fix"
                  >
                    <Icon style={{ color: "darkgrey" }} fontSize="22px">
                      build_rounded
                    </Icon>
                  </Button>
                </Fragment>
              )}
              {!isChosenReassign && !toReassign && (
                <Fragment>
                  {article.articles || !multipleRemove.length ? (
                    <Button
                      style={{ color: "darkred" }}
                      disabled={loading}
                      size="small"
                      className={classes.expand}
                      onClick={(e) => {
                        let currentScroll = null;
                        if (childListRef && childListRef.current)
                          currentScroll = childListRef.current.scrollTop;
                        setUndoDeleteArticle(null);
                        deleteArticle(article, posfix).then(() => {
                          setSuccess({
                            msg: "Successfully discarded",
                            data: {
                              action: "discardchild",
                              parent_article_id: article.parent_article_id,
                              childIndex: currentScroll, //scroll of parent div
                            },
                          });
                        });
                        setUndoDeleteArticle({
                          ...article,
                          parent_article_id_old: article.parent_article_id,
                        });
                      }}
                      title="Discard"
                    >
                      <Icon fontSize="inherit">delete_sweep_rounded</Icon>
                    </Button>
                  ) : (
                    <Button
                      style={{ color: "darkred" }}
                      disabled={loading}
                      size="small"
                      className={classes.expand}
                      onClick={(e) => {
                        setMultipleRemove([]);
                      }}
                      title="Cancel"
                    >
                      <Icon fontSize="inherit">close_rounded</Icon>
                    </Button>
                  )}
                </Fragment>
              )}

              {!article.articles && !toReassign && !reassign ? (
                <Fragment>
                  <Button
                    size="small"
                    style={{ color: "darkorange" }}
                    className={classes.expand}
                    disabled={loading}
                    onClick={(e) => {
                      let currentScroll = null;
                      if (childListRef && childListRef.current)
                        currentScroll = childListRef.current.scrollTop;
                      const justArticle = {
                        ...article,
                        position: motherPosition,
                      };
                      delete justArticle.articles;
                      if (!e.ctrlKey && !e.metaKey && !multipleRemove.length) {
                        removeFromMother(justArticle, posfix).then(() => {
                          console.log(currentScroll);
                          setSuccess({
                            msg: "Successfully removed from mother",
                            data: {
                              action: "removefrommother",
                              parent_article_id: article.parent_article_id,
                              childIndex: currentScroll, //scroll of parent div
                            },
                          });
                        });
                      } else {
                        if (multipleRemove.find((a) => a.id === article.id)) {
                          setMultipleRemove(
                            multipleRemove.filter((a) => a.id !== article.id)
                          );
                        } else {
                          setMultipleRemove(
                            multipleRemove.concat([justArticle])
                          );
                        }
                      }
                    }}
                    title="Remove from mother"
                  >
                    <Icon fontSize="inherit">layers_clear_rounded</Icon>
                  </Button>
                  {!multipleRemove.length ? (
                    <Button
                      size="small"
                      style={{ color: "darkgreen" }}
                      disabled={loading}
                      className={classes.expand}
                      onClick={(e) => {
                        let currentScroll = null;
                        if (childListRef && childListRef.current)
                          currentScroll = childListRef.current.scrollTop;
                        const justArticle = {
                          ...article,
                          position: inboxPosition,
                        };
                        delete justArticle.articles;
                        convertToMother(justArticle, posfix).then(() => {
                          setSuccess({
                            msg: "Successfully converted to mother",
                            data: {
                              action: "newmother",
                              article: justArticle,
                              childIndex: currentScroll, //scroll of parent div
                            },
                          });
                        });
                      }}
                      title="Convert to mother"
                    >
                      <Icon fontSize="inherit">publish_rounded</Icon>
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      style={{ color: "darkgreen" }}
                      disabled={loading}
                      className={classes.expand}
                      onClick={(e) => {
                        confirm({
                          title: "Confirm remove and group",
                          description:
                            "This will remove all these entries from this mother and assign it as a group for the selected entry",
                        })
                          .then(() => {
                            removeMultipleFromMother(
                              article,
                              multipleRemove,
                              posfix
                            ).then(() => {
                              setSuccess({
                                msg: "Successfully converted to mother",
                                data: {
                                  action: "newmother",
                                  article: mother,
                                },
                              });
                            });
                          })
                          .catch((e) => console.log(e));
                      }}
                      title="Remove and group to this mother"
                    >
                      <Icon fontSize="inherit">check_rounded</Icon>
                    </Button>
                  )}
                </Fragment>
              ) : (
                posfix === "_DASHBOARD" && (
                  <Button
                    style={{
                      color: !article.article_correction_id
                        ? "#F38C09"
                        : "#21A225",
                    }}
                    disabled={loading}
                    size="small"
                    className={classes.expand}
                    onClick={(e) => {
                      if (!article.article_correction_id)
                        setArticleCorrection(article);
                      else {
                        confirm({
                          description: `You are about to notify that the requested correction has been operated. Are you sure?`,
                          title: "ERROR CORRECTED?",
                          cancellationText: "Cancel",
                          confirmationText: "Confirm",
                        }).then(() =>
                          setArticleCorrectionAction(article, null)
                        );
                      }
                    }}
                    title={
                      !article.article_correction_id
                        ? "Request Correction"
                        : "Error Corrected"
                    }
                  >
                    <Icon fontSize="inherit">
                      {!article.article_correction_id
                        ? "report"
                        : "change_circle"}
                    </Icon>
                  </Button>
                )
              )}
            </Grid>
            {article.articles && article.articles.length !== 0 && (
              <Collapse
                in={expanded}
                mountOnEnter
                style={{ width: "100%" }}
                unmountOnExit
              >
                <Grid item md={12}>
                  <Fragment>
                    <div
                      ref={childList}
                      style={{
                        backgroundColor: "rgb(189, 189, 189)",
                        padding: "2px",
                        maxHeight: "800px",
                        overflow: "auto",
                      }}
                    >
                      {article.articles
                        .sort((a, b) =>
                          (a.translated_header || a.original_header) >
                          (b.translated_header || b.original_header)
                            ? 1
                            : -1
                        )
                        .map((art, index) => (
                          <InboxCard
                            data={reassignChildren.length}
                            key={index}
                            article={art}
                            childListRef={childList}
                            sources={sources}
                            source_types={source_types}
                            jurisdictions={jurisdictions}
                            languages={languages}
                            reassign={reassign}
                            setReassign={setReassign}
                            addReassignChildren={addReassignChildren}
                            reassignChildren={reassignChildren}
                            childIndex={index}
                            toReassign={
                              reassignChildren
                                .map((c) => c.id)
                                .indexOf(art.id) !== -1
                            }
                            initialScroll={scrollTop}
                            removeReassignChildren={removeReassignChildren}
                            setUndoDeleteArticle={setUndoDeleteArticle}
                            deleteArticle={deleteArticle}
                            setUndoApproveArticle={setUndoApproveArticle}
                            approveArticle={approveArticle}
                            removeFromMother={removeFromMother}
                            inboxPosition={inboxPosition}
                            motherPosition={article.position || inboxPosition}
                            convertToMother={convertToMother}
                            setSuccess={setSuccess}
                            setArticleNewJur={setArticleNewJur}
                            setArticleNewTag={setArticleNewTag}
                            setCurrentArticle={setCurrentArticle}
                            setNewTrigger={setNewTrigger}
                            multipleRemove={multipleRemove}
                            setMultipleRemove={setMultipleRemove}
                            removeMultipleFromMother={removeMultipleFromMother}
                            mother={mother}
                            posfix={posfix}
                          />
                        ))}
                    </div>
                  </Fragment>
                </Grid>
              </Collapse>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  error: state.articles.error,
});

const mapDispatchToProps = (dispatch) => ({
  deleteArticle: (art, posfix) => dispatch(deleteArticle(art, posfix)),
  approveArticle: (art) => dispatch(approveArticle(art)),
  removeFromMother: (art, posfix) => dispatch(removeFromMother(art, posfix)),
  convertToMother: (art, posfix) => dispatch(convertToMother(art, posfix)),
  setPossibleLegalDevelopment: (art, possible_legal_development, posfix) =>
    dispatch(
      setPossibleLegalDevelopment(art, possible_legal_development, posfix)
    ),

  removeMultipleFromMother: (mother, arts, posfix) =>
    dispatch(removeMultipleFromMother(mother, arts, posfix)),
  assignToMotherInboxDashboard: (mother, childe, convert) =>
    dispatch(assignToMotherInboxDashboard(mother, childe, convert)),
  removeDashMother: (art) => dispatch(removeDashMother(art)),
  setArticleCorrectionAction: (art, article_correction) =>
    dispatch(setArticleCorrection(art, article_correction)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxCard);
