import {
  Autocomplete,
  Button,
  DialogActions,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";

const PREFIX = "AddTag";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

function AddTag(props) {
  const { article, tags, setArticleNewTag, updateTags } = props;

  const [newTags, setNewTags] = useState(null);
  const [iniTags, setIniTags] = useState([]);

  useEffect(() => {
    if (article && tags && tags.length) {
      setIniTags(
        !article.tags
          ? []
          : article.tags.map((t) => ({ id: t.tag.id, tag: t.tag.tag }))
      );
    }
  }, [article, tags, newTags]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setArticleNewTag(null);
  }

  useEffect(() => {}, [tags]);

  const onChange = (val) => {
    setNewTags(val);
  };
  const handleSave = (article) => {
    console.log("Say whaaat?");
    if (newTags) {
      console.log(newTags);
      updateTags(article, newTags);
      setNewTags(null);
    } /*else {
      updateTags(article, iniTags);
    }*/
    handleClose();
  };
  return (
    <StyledDialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={article || false}
      onClick={(event) => event.stopPropagation()}
      onMouseDown={(event) => event.stopPropagation()}
    >
      {article && tags && tags.length > 0 && (
        <Paper style={{ padding: "10px" }}>
          <Autocomplete
            id="tag-select-popup"
            style={{ width: "100%" }}
            options={tags}
            disableClearable={false}
            clearOnBlur={true}
            classes={{
              option: classes.option,
            }}
            multiple
            autoSelect
            value={newTags ? newTags : iniTags}
            size="small"
            onChange={(e, newVal) => {
              onChange(newVal);
            }}
            getOptionLabel={(option) => option.tag}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.tag}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                variant="outlined"
                autoFocus
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>{" "}
        <Button
          onClick={(e) => handleSave(article)}
          variant="contained"
          color="primary"
        >
          SAVE
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default AddTag;
