import React, { useContext } from "react";
import { truncateName } from "../../../../../utils/functions";
import ViewFootnoteModal from "../../../ConfirmationModal/ViewFootnoteModal";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import EditIcon from "../../../Icons/EditIcon";
import DeleteFootnoteModal from "../../../ConfirmationModal/DeleteFootnoteModal";
import TipTapContext from "../../../../../shared/context/tipTap-context";

const FootnoteMark = ({
  index,
  theFootnote,
  lang,
  changeCurrentFootnote,
  section,
  columnName,
}) => {
  return (
    <div key={`what${index + 1}`} className="DrawerEntry">
      <strong>Footnote {index + 1}</strong>&nbsp; -{" "}
      {truncateName(theFootnote.text)}
      <div className="quickBtns">
        <ViewFootnoteModal data={theFootnote} lang={lang} section={section} />

        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            onClick={(evt) => {
              changeCurrentFootnote(theFootnote);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <DeleteFootnoteModal
          data={theFootnote}
          lang={lang}
          section={section}
          columnName={columnName}
        />
      </div>
    </div>
  );
};

export default FootnoteMark;
