import React from 'react'
import {Autocomplete, FormControl, TextField} from "@mui/material";

const CustomizedSelect = ({options, mutate, name, label, value= '', required = true}) => {
  return <FormControl fullWidth> {/* DONE */}
    <Autocomplete
      required={required}
      disablePortal
      disabled={options && !options.length}
      name={name}
      id={name}
      value={value || null}
      options={options || []}
      onChange={mutate}
      isOptionEqualToValue={(option, value) =>
        option.value === value.value
      }
      renderInput={(params) =>
        <TextField {...params} htmlFor={name} label={label + (required ? '*' : '')}/>}
    />
  </FormControl>
}

export default CustomizedSelect
