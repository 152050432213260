import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  getAlertSubaccounts,
  getJurisdictions,
  getLanguages,
  getSourceTypes,
  getTags,
} from "../actions/related";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import {
  Button,
  Grid,
  Icon,
  IconButton,
  Pagination,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useConfirm } from "material-ui-confirm";

import { useParams } from "react-router";

import "react-calendar/dist/Calendar.css";
import { getSources } from "../actions/sources";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import InboxArticle from "./InboxArticle";

import moment, { utc } from "moment-timezone";

import InboxCard from "./InboxCard";
import TriggerForm from "./TriggerForm";

import {
  addTagTriggers,
  addTierTriggers,
  clearCurrentArticle,
  clearErrorArticles,
  deleteMultipleArticles,
  getInboxArticles,
  reassignMother,
  setBackToInbox,
  setCurrentArticle,
  setFilters,
  updateJurs,
  updateTags,
} from "../actions/articles";

import AddJur from "./AddJur";
import AddTag from "./AddTag";
import InboxFilters from "./InboxFilters";

import AddArticleBtn from "../articles/AddArticleBtn";
import ArticleForm from "../articles/ArticleForm";
import { daysWithConsultantTiers } from "../utils/functions";
import { consultantTiers } from "../utils/functions";

const PREFIX = "Inbox";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  snack: `${PREFIX}-snack`,
  marginAutoContainer: `${PREFIX}-marginAutoContainer`,
  marginAutoSpaceBetween: `${PREFIX}-marginAutoSpaceBetween`,
  marginAutoItem: `${PREFIX}-marginAutoItem`,
  floatLeft: `${PREFIX}-floatLeft`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
  [`& .${classes.snack}`]: { backgroundColor: "#878bff" },
  [`& .${classes.marginAutoContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
  [`& .${classes.marginAutoSpaceBetween}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  [`& .${classes.marginAutoItem}`]: {
    //margin: "auto"
  },
  [`& .${classes.floatLeft}`]: {
    float: "left",
  },
});

const Inbox = ({
  error,
  clearErrorArticles,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
  getSourceTypes,
  source_types,
  getJurisdictions,
  jurisdictions,
  getTags,
  tags,
  languages,
  getLanguages,
  getInboxArticles,
  inbox_articles,
  current,
  setCurrentArticle,
  clearCurrentArticle,
  show_articles,
  loading,
  setBackToInbox,
  reassignMother,
  filter,
  updateJurs,
  updateTags,
  addTagTriggers,
  addTierTriggers,
  setFilters,
  filters,
  deleteMultipleArticles,
}) => {
  const [date, setDate] = useState(
    process.env.REACT_APP_DATE
      ? moment(process.env.REACT_APP_DATE).toDate()
      : utc(new Date()).subtract(3, "days").toDate()
  ); //new Date()

  let { id } = useParams();

  const [open, setOpen] = React.useState(false);

  const [articleNewJur, setArticleNewJur] = React.useState(null);
  const [articleNewTag, setArticleNewTag] = React.useState(null);

  const [page, setPage] = React.useState(1);

  const [reassign, setReassign] = React.useState(null);
  const [reassignChildren, setReassignChildren] = React.useState([]);

  const [selectedArticle, setSelectedArticle] = React.useState({});

  const [undoDeleteArticle, setUndoDeleteArticle] = React.useState(null);

  const [undoApproveArticle, setUndoApproveArticle] = React.useState(null);

  const [newTrigger, setNewTrigger] = React.useState({});

  const [success, setSuccess] = React.useState("");

  const [multipleRemove, setMultipleRemove] = React.useState([]);

  const [deleteMultipleReady, setDeleteMultipleReady] = React.useState(false);

  const handleCloseReassigned = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess("");
  };

  const confirm = useConfirm();
  const onClickCompleteTier = (confirm, tier) => {
    confirm({
      description: `You are about to mark this Tier as fully revised. This will reject all remaining entries in tier ${tier}. There is no undo for this. Are you sure?`,
      title: "Tier " + tier + " revision complete",
      cancellationText: "No",
      confirmationText: "Yes",
    }).then(() => {
      setFilters({ ...filter.filters, sou: null }).then(() => {
        setDeleteMultipleReady(true);
      });
    });
  };

  useEffect(() => {
    if (!filter.filters.sou && deleteMultipleReady) {
      deleteMultipleArticles(show_articles.filter((a) => a.tier >= 3));
      setDeleteMultipleReady(false);
    }
  }, [filter.filters.sou, deleteMultipleReady]);

  //recursive function that calls itself until a condition is met with a timeout
  const waitToDelete = () => {
    if (!filter.filters.sou) {
      deleteMultipleArticles(show_articles);
    } else {
      setTimeout(() => waitToDelete(), 500);
    }
  };

  const rowsPerPage = 30;

  const addReassignChildren = (child) => {
    setReassignChildren([...reassignChildren, child]);
  };

  const removeReassignChildren = (child_id) => {
    setReassignChildren(reassignChildren.filter((c) => c.id !== child_id));
  };

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!tags || !tags.length) getTags();

    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();
    if (id) {
      getInboxArticles(null, id).then(() => setFilters({ ...filters }));
    } else {
      getInboxArticles(date.toISOString().split("T")[0]).then(() =>
        setFilters({ ...filters })
      );
    }
  }, [date]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
    setSelectedArticle({});
  };

  const consultantDayFilter = (a) => {
    if (
      filter.filters.tier === 2 &&
      daysWithConsultantTiers.indexOf(utc(date).format("YYYY-MM-DD")) !== -1
    ) {
      if (!filter.filters.consultantTier) return false;
      else {
        const user = consultantTiers(utc(date).format("YYYY-MM-DD")).find(
          (u) => u.id === filter.filters.consultantTier
        );
        return (
          parseInt(String(a.consultant_original_id || a.id).slice(-2)) >=
            0 + user.pos * 25 &&
          parseInt(String(a.consultant_original_id || a.id).slice(-2)) <
            25 + user.pos * 25
        );
      }
    } else return a;
  };

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inbox - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorArticles} />
      <ArticleForm
        current={current}
        setSuccess={setSuccess}
        setCurrentArticle={setCurrentArticle}
        clearCurrentArticle={clearCurrentArticle}
      />
      <InboxArticle
        selectedArticle={selectedArticle}
        open={open || false}
        onClose={handleClose}
      />
      <TriggerForm
        newTrigger={newTrigger}
        setNewTrigger={setNewTrigger}
        addTierTriggers={addTierTriggers}
      />
      {jurisdictions && jurisdictions.length > 0 && (
        <AddJur
          article={articleNewJur}
          jurisdictions={jurisdictions}
          setArticleNewJur={setArticleNewJur}
          updateJurs={updateJurs}
        />
      )}
      {tags && tags.length > 0 && (
        <AddTag
          article={articleNewTag}
          tags={tags}
          setArticleNewTag={setArticleNewTag}
          updateTags={updateTags}
        />
      )}

      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {reassign && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ top: "64px" }}
              ContentProps={{
                classes: {
                  root: classes.snack,
                },
              }}
              open={reassign !== null}
              onClose={handleClose}
              message={
                'Assigning children to "' +
                (reassign.source ? reassign.source.name + "'s\"" : "") +
                ' article "' +
                (reassign.translated_header || reassign.original_header).slice(
                  0,
                  25
                ) +
                '..."'
              }
              action={
                <React.Fragment>
                  {reassignChildren.length > 0 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        const justArticle = {
                          ...reassign,
                        };
                        delete justArticle.articles;
                        reassignMother(justArticle, reassignChildren);
                        setReassign(null);
                        setReassignChildren([]);
                        setSuccess("Children reassigned successfully");
                      }}
                    >
                      REASSIGN {reassignChildren.length} ARTICLES
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => {
                      setReassign(null);
                      setReassignChildren([]);
                    }}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <Snackbar
            open={!loading && success ? true : false}
            autoHideDuration={3000}
            onClose={handleCloseReassigned}
            style={{ top: "64px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseReassigned}
              severity="success"
            >
              {success.msg ? success.msg : success}
            </MuiAlert>
          </Snackbar>
          {source_types && source_types.length > 0 && (
            <InboxFilters
              date={date}
              setDate={setDate}
              source_types={source_types}
              setPage={setPage}
              languages={languages}
              loading={loading}
            />
          )}
          <br />
          <br />
          <div className={classes.marginAutoSpaceBetween}>
            <div className={classes.floatLeft}>
              {filter.filters.tier &&
                filter.filters.tier >= 3 &&
                show_articles.filter(consultantDayFilter).length > 0 &&
                !loading && (
                  <Button
                    variant="contained"
                    disabled={filter.filters.tier < 3}
                    style={{
                      marginLeft: "10px",
                      marginTop: "2px",
                      fontWeight: "bold",
                      textTransform: "unset",
                      backgroundColor: "#3f51b5",
                      color: "white",
                    }}
                    onClick={(e) => {
                      onClickCompleteTier(confirm, filter.filters.tier);
                    }}
                  >
                    Tier {filter.filters.tier} revision complete
                  </Button>
                )}
            </div>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(
                  show_articles.filter(consultantDayFilter).length / rowsPerPage
                )}
                page={page}
                onChange={(e, value) => setPage(value)}
                boundaryCount={20}
              />
            </div>
          </div>
          {!loading && inbox_articles.length === 0 && (
            <center>
              <iframe
                src="https://giphy.com/embed/3oz8xAFtqoOUUrsh7W"
                width="480"
                height="320"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              />
            </center>
          )}
          <Grid container>
            {show_articles
              .filter((a) => consultantDayFilter(a))
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((article, index) => (
                <Grid item md={12} padding={"0px"} key={index}>
                  <InboxCard
                    key={article.id}
                    article={article}
                    sources={sources}
                    inboxPosition={index}
                    source_types={source_types}
                    jurisdictions={jurisdictions}
                    languages={languages}
                    reassign={reassign}
                    setReassign={setReassign}
                    addReassignChildren={addReassignChildren}
                    reassignChildren={reassignChildren}
                    toReassign={
                      reassignChildren.map((c) => c.id).indexOf(article.id) !==
                      -1
                    }
                    removeReassignChildren={removeReassignChildren}
                    setUndoDeleteArticle={setUndoDeleteArticle}
                    setUndoApproveArticle={setUndoApproveArticle}
                    setSuccess={setSuccess}
                    success={success}
                    mother={article}
                    setArticleNewJur={setArticleNewJur}
                    setArticleNewTag={setArticleNewTag}
                    setCurrentArticle={setCurrentArticle}
                    setNewTrigger={setNewTrigger}
                    multipleRemove={multipleRemove}
                    setMultipleRemove={setMultipleRemove}
                  />
                </Grid>
              ))}
          </Grid>
          {show_articles.filter(consultantDayFilter).length > 0 && (
            <div className={classes.marginAutoContainer}>
              <div className={classes.marginAutoItem}>
                <Pagination
                  count={Math.ceil(
                    show_articles.filter(consultantDayFilter).length /
                      rowsPerPage
                  )}
                  page={page}
                  onChange={(e, value) => {
                    setPage(value);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  boundaryCount={20}
                />
              </div>
            </div>
          )}
          {undoDeleteArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoDeleteArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoDeleteArticle(null);
              }}
              message={
                (undoDeleteArticle.source
                  ? undoDeleteArticle.source.name + "'s \""
                  : '"') +
                (
                  undoDeleteArticle.translated_header ||
                  undoDeleteArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article moved to bin"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoDeleteArticle);
                      setUndoDeleteArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoDeleteArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          {undoApproveArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!loading && undoApproveArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoApproveArticle(null);
              }}
              message={
                (undoApproveArticle.source
                  ? undoApproveArticle.source.name + "'s \""
                  : '"') +
                (
                  undoApproveArticle.translated_header ||
                  undoApproveArticle.original_header
                ).slice(0, 25) +
                '..." ' +
                "article approved"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoApproveArticle);
                      setUndoApproveArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoApproveArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

          <AddArticleBtn />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts,
  source_types: state.related.source_types,
  jurisdictions: state.related.jurisdictions,
  tags: state.related.tags,
  languages: state.related.languages,
  inbox_articles: state.articles.inbox,
  filter: state.articles.filter,
  loading: state.articles.loading,
  show_articles: state.articles.filter.active
    ? state.articles.filter.result
    : state.articles.inbox,
  current: state.articles.current,
  filters: state.articles.filter,
  error: state.articles.error,
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getTags: () => dispatch(getTags()),
  getLanguages: () => dispatch(getLanguages()),
  getInboxArticles: (date, id = null) => dispatch(getInboxArticles(date, id)),
  setBackToInbox: (article) => dispatch(setBackToInbox(article)),
  reassignMother: (mother, children) =>
    dispatch(reassignMother(mother, children)),
  updateJurs: (art, jurs) => dispatch(updateJurs(art, jurs)),
  updateTags: (art, tags) => dispatch(updateTags(art, tags)),
  addTagTriggers: (tag, triggers) => dispatch(addTagTriggers(tag, triggers)),
  addTierTriggers: (tier, triggers) =>
    dispatch(addTierTriggers(tier, triggers)),
  setCurrentArticle: (art) => dispatch(setCurrentArticle(art)),
  clearCurrentArticle: () => dispatch(clearCurrentArticle()),
  clearErrorArticles: () => dispatch(clearErrorArticles()),
  setFilters: (filters) => dispatch(setFilters(filters)),
  deleteMultipleArticles: (articles) =>
    dispatch(deleteMultipleArticles(articles)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
