import { Card } from "@mui/material";
import React from "react";

const TabPanel = (props) => {
  const { children, value, index, enabled, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={!enabled ? { pointerEvents: "none", opacity: "0.65" } : {}}
      {...other}
    >
      <Card sx={{ p: 3 }}>{children}</Card>
    </div>
  );
};

export default TabPanel;
