import { Button, DialogActions, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { Fragment } from "react";

function InboxArticle(props) {
  const { onClose, selectedArticle, open } = props;

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose(selectedArticle);
  };

  return (
    <Dialog
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open || false}
    >
      {selectedArticle && selectedArticle.article_content && (
        <Paper style={{ padding: "10px" }}>
          {selectedArticle &&
            selectedArticle.article_content &&
            selectedArticle.article_content.date && (
              <Fragment>
                <h2>Date:</h2>{" "}
                {selectedArticle.article_content.date.split("T")[0]}
              </Fragment>
            )}
          <br />
          <br />
          <h2>Content:</h2>{" "}
          {selectedArticle && selectedArticle.article_content && (
            <p
              dangerouslySetInnerHTML={{
                __html: selectedArticle.article_content.content.replace(
                  /(<br\s*\/?>){2,}/gi,
                  "<br>"
                )
              }}
            />
          )}
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InboxArticle;
