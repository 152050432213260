import { styled } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";

import {
  clearCurrentJurTag,
  clearErrorTags,
  getJurTags,
  setCurrentJurTag,
  setOpenJurTag,
} from "../actions/tags";

import { getLanguages } from "../actions/related";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import { AuthContext } from "../shared/context/auth-context";
import JurTagForm from "./JurTagForm";

import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

const PREFIX = "JurTags";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const JurTags = ({
  jur_tags,
  loading,
  error,
  current,
  bypassIo,
  clearErrorTags,
  setCurrentJurTag,
  setOpenJurTag,
  clearCurrentJurTag,
  getJurTags,
  dispatch,
  getLanguages,
  allLanguages,
}) => {
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if ((!jur_tags || jur_tags.length === 0) && !error) getJurTags();
  }, [loading, bypassIo]);

  const onClickEdit = (lang) => {
    setCurrentJurTag(lang);
  };

  const columns = [
    {
      id: "country",
      numeric: false,
      disablePadding: false,
      label: "Country",

      width: "15%",
    },
    {
      id: "accepted",
      numeric: false,
      disablePadding: false,
      label: "Accepted Entries",

      width: "5%",
    },
    {
      id: "triggers",
      numeric: false,
      disablePadding: false,
      label: "Triggers",
      noSelect: true,
      transform: function (row) {
        return row.jur_triggers ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.jur_triggers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    {t.t.name}
                    {t.t.con !== 0 && "*"}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jurisdictions - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorTags} />
      <React.Fragment>
        <CssBaseline />
        <JurTagForm current={current} clearCurrentJurTag={clearCurrentJurTag} />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {jur_tags && jur_tags.length !== 0 && (
            <EnhancedTable
              rows={jur_tags.sort((a, b) => a.country.localeCompare(b.country))}
              columns={columns}
              options={{
                title: "Jurisdiction Manager",
                multiple_select: false,
                hide_pagination: true,
              }}
              actions={{ edit: onClickEdit }}
            ></EnhancedTable>
          )}
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  jur_tags: state.tags.jur_tags,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_jur_tag,

  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  getJurTags: () => dispatch(getJurTags()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentJurTag: (jur_tag) => dispatch(setCurrentJurTag(jur_tag)),
  clearCurrentJurTag: () => dispatch(clearCurrentJurTag()),
  setOpenJurTag: () => dispatch(setOpenJurTag()),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(JurTags);
