import React, { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress, IconButton } from "@mui/material";
import { updateEntryFile, updateEntrySource } from "../Api/EntryApi";
import SaveIcon from "./Icons/SaveIcon";
import RemoveLinkIcon from "./Icons/RemoveLinkIcon";
import RadioButtonsGroup from "./RadioGroup/RadioGroup";
import FileUpload from "./TipTap/FileUpload";
import { useHistory, useLocation } from "react-router-dom";
import useSpinner from "../../hooks/useSpinner";
import TipTapContext from "../../shared/context/tipTap-context";
import { useSelector } from "react-redux";

const SetSourceForm = ({
  currentSource,
  currentFileOr,
  currentFileTr,
  setCurrentSource,
  editor,
  hasSources,
  setValidation,
  lang,
  section,
  columnName,
  handleReload,
  setError,
  setCurrentFileOr,
  setCurrentFileTr,
  setCurrentFileOrOff,
  newLanguageOrOff,
  newLanguageOrOff2,
  newLanguageOr,
  newLanguageOr2,
  setNewLanguageOrOff,
  setNewLanguageOrOff2,
  currentFileOrOff,
  currentFileOrOffRef,
  currentFileOrRef,
  currentFileTrRef,
  addingNewSource,
  newSourceToAdd,
  setNewLanguageOr,
  setNewLanguageOr2,
  extractFormData,
  saveEsSource,
  year,
  radio,
  setRadioValue,
  handleYearChange,
}) => {
  const { ctxEntry, ctxSource, showErrorSnack, setSnackErrorMessage } =
    useContext(TipTapContext);
  const sourcesValidation = useSelector((state) => state.sourcesValidation);
  const location = useLocation();
  const history = useHistory();
  const showSpinner = useSpinner();

  const handleSaveClick = async () => {
    if (lang !== "es") {
      try {
        const formData = extractFormData();
        
        // First, run updateEntrySource
        const resSource = await updateEntrySource(formData);

        if (editor && hasSources) {
          const asteriskRegex = /((?:\*)((?:[^*]+))(?:\*))/g;
          const match = resSource.match(asteriskRegex);
          if (match) {
            setValidation(lang, match.length);
          }
        }

        // Now, run updateEntryFile after updateEntrySource has completed
        await updateEntryFile(formData);
        handleReload(location, history);
      } catch (e) {
        setError(e.message);
        console.log("An error occurred:", e);
      }
    } else {
      if (
        sourcesValidation[columnName + "_en"] >
        (sourcesValidation[columnName + "_es"] || 0)
      ) {
        saveEsSource();
      } else {
        showErrorSnack();
        setSnackErrorMessage(
          "Add the source in the English editor first! " + columnName
        );
        console.log(sourcesValidation);
        setTimeout(() => {
          //handleReload(location, history);
        }, 3000);
      }
    }
  };

  const handleCloseClick = () => {
    setCurrentFileOrOff(null);
    setCurrentFileOr(null);
    setCurrentFileTr(null);
    setCurrentSource(null);
  };

  let containingSource = null;

  if (lang === "es" && currentSource)
    containingSource = ctxEntry.entries.sources.filter(
      (source) => source.order === currentSource.order
    )[0];

  return (
    <div className="CurrentSourceForm">
      {showSpinner && (
        <div className="DrawerCover">
          <CircularProgress color="inherit" />
        </div>
      )}

      <div className="Drawer__header">
        {addingNewSource ? (
          <h4>
            Adding Source{" "}
            {currentSource &&
              (currentSource.order
                ? currentSource.order
                : currentSource["data-original-source"]
                ? currentSource["data-original-source"]
                : "NEW ONE!")}{" "}
            - {newSourceToAdd.text}{" "}
            {containingSource && (
              <span style={{ fontStyle: "italic" }}>
                [{containingSource.text}]
              </span>
            )}
          </h4>
        ) : (
          <h4>
            Editing Source{" "}
            {currentSource &&
              (currentSource.order
                ? currentSource.order
                : currentSource["data-original-source"]
                ? currentSource["data-original-source"]
                : "NEW ONE!")}
          </h4>
        )}

        {!addingNewSource && (
          <button className="Link__btn" onClick={() => setCurrentSource(null)}>
            {currentSource && currentSource.text}
          </button>
        )}
      </div>

      <div className="Drawer__header-actions">
        <Tooltip title="Save source">
          <span>
            <IconButton
              variant="contained"
              size="small"
              aria-label="close"
              color="primary"
              disabled={lang === "es" && !addingNewSource}
              className="Drawer__save"
              onClick={handleSaveClick}
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Close source edit">
          <span>
            <IconButton
              variant="contained"
              size="small"
              aria-label="close"
              color="primary"
              className="Drawer__close"
              onClick={() => {
                if (addingNewSource) {
                  handleReload(location, history);
                } else {
                  handleCloseClick();
                }
              }}
            >
              <RemoveLinkIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      {currentSource ? (
        <div className="SourceDownloadUpload">
          {lang !== "es" ? (
            <RadioButtonsGroup
              selected={radio}
              setSelectedValue={setRadioValue}
            />
          ) : (
            ""
          )}

          <FileUpload
            key={"12"}
            lang={lang}
            currentSource={currentSource}
            addingNewSource={addingNewSource}
            ref={currentFileOrOffRef}
            currentFile={currentFileOrOff}
            setCurrentFile={setCurrentFileOrOff}
            sourceType={"oroff"}
            language={
              newLanguageOrOff
                ? newLanguageOrOff
                : ctxEntry &&
                  !addingNewSource &&
                  ctxEntry.entries.sources[currentSource["order"] - 1] &&
                  (currentSource["original-official-language-id"] ||
                    ctxEntry.entries.sources[currentSource["order"] - 1]
                      .original_official_language_id) &&
                  ctxEntry.entries.sources[currentSource["order"] - 1]
                    .original_official_language_id
                ? ctxEntry &&
                  ctxEntry.entries.sources[currentSource["order"] - 1] &&
                  (currentSource["original-official-language-id"] ||
                    ctxEntry.entries.sources[currentSource["order"] - 1]
                      .original_official_language_id) &&
                  ctxEntry.entries.sources[currentSource["order"] - 1]
                    .original_official_language_id
                : null
            }
            language2={
              newLanguageOrOff2 ||
              (ctxEntry &&
                !addingNewSource &&
                ctxEntry.entries.sources[currentSource["order"] - 1] &&
                ctxEntry.entries.sources[currentSource["order"] - 1]
                  .original_official_language_id2 &&
                ctxEntry.entries.sources[currentSource["order"] - 1]
                  .original_official_language_id2)
            }
            setLanguage={setNewLanguageOrOff}
            setLanguage2={setNewLanguageOrOff2}
            fileName={
              currentSource && currentSource["data-original-official-filename"]
            }
            langCalled={lang}
            year={year}
            handleYearChange={handleYearChange}
            handleReload={handleReload}
            location={location}
            history={history}
            section={section}
            columnName={columnName}
          />

          <FileUpload
            currentSource={currentSource}
            addingNewSource={addingNewSource}
            key={"123"}
            lang={lang}
            ref={currentFileOrRef}
            currentFile={currentFileOr}
            setCurrentFile={setCurrentFileOr}
            sourceType={"or"}
            language={
              newLanguageOr
                ? newLanguageOr
                : ctxEntry &&
                  !addingNewSource &&
                  ctxEntry.entries.sources[currentSource["order"] - 1] &&
                  (currentSource["original-language-id"] ||
                    ctxEntry.entries.sources[currentSource["order"] - 1]
                      .original_language_id) &&
                  ctxEntry.entries.sources[currentSource["order"] - 1]
                    .original_language_id
                ? ctxEntry &&
                  ctxEntry.entries.sources[currentSource["order"] - 1] &&
                  (currentSource["original-language-id"] ||
                    ctxEntry.entries.sources[currentSource["order"] - 1]
                      .original_language_id) &&
                  ctxEntry.entries.sources[currentSource["order"] - 1]
                    .original_language_id
                : null
            }
            language2={
              newLanguageOr2 ||
              (ctxEntry &&
                !addingNewSource &&
                ctxEntry.entries.sources[
                  currentSource["data-original-source"] - 1
                ] &&
                (ctxEntry.entries.sources[
                  currentSource["data-original-source"] - 1
                ].original_language_id2 ||
                  ctxEntry.entries.sources[
                    currentSource["data-original-source"] - 1
                  ].original_language_id2))
            }
            setLanguage={setNewLanguageOr}
            setLanguage2={setNewLanguageOr2}
            fileName={currentSource && currentSource["data-original-filename"]}
            langCalled={lang}
            year={year}
            handleYearChange={handleYearChange}
            handleReload={handleReload}
            location={location}
            history={history}
            section={section}
            columnName={columnName}
          />

          <FileUpload
            currentSource={currentSource}
            key={"1234"}
            lang={lang}
            ref={currentFileTrRef}
            currentFile={currentFileTr}
            setCurrentFile={setCurrentFileTr}
            sourceType={"tr"}
            fileName={
              currentSource && currentSource["data-translated-filename"]
            }
            langCalled={lang}
            year={year}
            handleYearChange={handleYearChange}
            handleReload={handleReload}
            location={location}
            history={history}
            section={section}
            columnName={columnName}
          />
        </div>
      ) : (
        `Saving this will add a reference to source ${ctxSource.order}`
      )}
    </div>
  );
};

export default SetSourceForm;
