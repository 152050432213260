import React, {useState} from 'react'
import Tooltip from "@mui/material/Tooltip";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";

const DeleteFileModal = ({deleteAction, data}) => {
  const [open, setOpen] = useState(false)
  const {
    currentSource,
    fileName,
    fileType
  } = data

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    //console.log("Delete is confirmed")
    deleteAction()
    setOpen(false)
  }

  return <>

    <Tooltip title="Delete">
      <span>
        <IconButton aria-label="delete"
                    onClick={handleOpen}
        >
          <svg xmlns="http://www.w3.org/2000/svg"
               height="24"
               width="24"
               viewBox="0 -960 960 960"><path d="M262.5-125q-23.719 0-40.609-16.891Q205-158.781 205-182.5v-566h-40.5v-57.333H349V-835h262.5v29H796v57.5h-40.5v566q0 22.969-17.266 40.234Q720.969-125 698-125H262.5ZM698-748.5H262.5v566H698v-566ZM369.5-268H427v-396.5h-57.5V-268Zm164 0H591v-396.5h-57.5V-268Zm-271-480.5v566-566Z"/></svg>
        </IconButton>
      </span>
    </Tooltip>

    <Dialog
      className="deleteConfirmation__dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle id="alert-dialog-title">
        You are about to delete a file.
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deleting {fileType} file in Source {currentSource ? currentSource.order : ''} named "{fileName}"
          <br />
          This action will delete file from both english and spanish contents
          <br />
          Are you sure?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button style={{ marginRight: 'auto' }} onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Yes, delete it please
        </Button>
      </DialogActions>

    </Dialog>

  </>
}

export default DeleteFileModal