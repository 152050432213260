import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { utc } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveArticle,
  convertToMother,
  deleteArticle,
  removeFromMother,
} from "../actions/articles";
import "./UntrackedInboxCard.css";

const PREFIX = 'UntrackedInboxCard';

const classes = {
  root: `${PREFIX}-root`,
  rootR: `${PREFIX}-rootR`,
  rootM: `${PREFIX}-rootM`,
  content: `${PREFIX}-content`,
  valignCenter: `${PREFIX}-valignCenter`,
  actions: `${PREFIX}-actions`,
  expand: `${PREFIX}-expand`,
  header: `${PREFIX}-header`,
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  dialogTitle: `${PREFIX}-dialogTitle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    paddingBottom: "0px",
    borderRadius: "5px",
  },

  [`& .${classes.rootR}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#b7cfff",
  },

  [`& .${classes.rootM}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "rgb(255, 252, 148)",
  },

  [`& .${classes.content}`]: {
    paddingTop: "10px",
    "&:last-child": {
      paddingBottom: "10px",
    },
  },

  [`& .${classes.valignCenter}`]: {
    display: "inline-flex",
    verticalAlign: "middle",
    alignItems: "center",
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    backgroundColor: "#fafafa",
  },

  [`& .${classes.expand}`]: {
    marginLeft: "auto",
    fontSize: "1.5rem",
    minWidth: "50px",
  },

  [`& .${classes.header}`]: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "navy",
    fontWeight: "bold",
  },

  [`& .${classes.modal}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.dialogTitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}));

function UntrackedInboxCard({
  article,
  sources,
  source_types,
  jurisdictions,
  languages,
  inboxPosition,
  loading,
  reassign,
  setReassign,
  addReassignChildren,
  toReassign,
  reassignChildren,
  removeReassignChildren,
  deleteArticle,
  setUndoDeleteArticle,
  approveArticle,
  setUndoApproveArticle,
  removeFromMother,
  convertToMother,
  setSuccess,
  success,
  multipleRemove,
  motherPosition,
  setArticleNewJur,
  setArticleNewTag,
  setCurrentArticle,
  setCurrentSource,
  handleClickBlock,
  handleClickAdd,
}) {

  const [expanded, setExpanded] = useState(false);
  const [webview, setWebview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteArticleJur, setDeleteArticleJur] = React.useState(null);
  const [deleteArticleTag, setDeleteArticleTag] = React.useState(null);
  const [addArticleJur, setAddArticleJur] = React.useState(null);
  const [selectedText, setSelectedText] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  useEffect(() => {
    if (
      success &&
      success.data &&
      success.data.action === "newmother" &&
      success.data.article.id === article.id
    ) {
      setExpanded(true);
    }
  }, [success]);

  const selectionAction = (event) => {
    let selText = window.getSelection().toString();
    console.log(window.getSelection());
    setSelectedText(selText);
  };

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];
  let st;
  let jur;
  if (article.source) {
    st = source_types.find(
      (st) => st.id === article.source.source_type_id
    ).code;
    jur = jurisdictions.find(
      (jur) => jur.id === article.source.jurisdiction_id
    );
  }

  let articleJurisdictions;
  let global_jur = null;
  let con_jur = null;
  if (article.jurisdictions && article.jurisdictions.length) {
    if (article.jurisdictions.length > 3)
      global_jur = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur = jur;
  }

  let lang = article.g_detected_language
    ? article.g_detected_language
    : article.detected_language;

  if (lang) {
    let langTemp = languages.find((l) => l.id === lang);
    if (langTemp) lang = langTemp.name;
  }

  const isChosenReassign = reassign && reassign.id === article.id;

  return (
    <Root>
      {article.article_content && article.article_content.content && (
        <Dialog
          contentStyle={{
            width: "80%",
            maxWidth: "none",
          }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open || false}
          onClose={handleClose}
          closeAfterTransition
          maxWidth="lg"
          title={
            <div>
              <img
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "5px",
                  width: "20px",
                }}
              />
            </div>
          }
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <h2>{article.article_content.title}</h2>
            <IconButton onClick={handleClose} size="large">
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Fade in={open}>
              <div className={classes.paper}>
                <p
                  id="transition-modal-description"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {article.article_content.content.replace(/<br \/>/g, "\n")}
                </p>
              </div>
            </Fade>
          </DialogContent>
        </Dialog>
      )}

      <Card
        className={
          !toReassign
            ? isChosenReassign
              ? classes.rootM
              : multipleRemove &&
                multipleRemove.length &&
                multipleRemove.find((a) => a.id === article.id)
              ? classes.rootR
              : classes.root
            : classes.rootR
        }
        onMouseUp={selectionAction}
      >
        <CardContent className={classes.content}>
          <Grid container spacing={3} className={classes.valignCenter}>
            <Grid item md={2} >
              <Grid
                container
                spacing={2}
                className={classes.valignCenter}
                onMouseOver={(e) => setAddArticleJur(article)}
                onMouseOut={(e) => setAddArticleJur(null)}
              >
                <Grid item md={3}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="h6"
                    style={{ textAlign: "center" }}
                  >
                    <time datetime="2014-09-20" className="icon">
                      <strong>{utc(article.digest_date).format("MMM")}</strong>
                      <span>{utc(article.digest_date).format("DD")}</span>
                    </time>
                  </Typography>
                </Grid>
                <Grid item md={3} style={{ textAlign: "center" }}>
                  {global_jur && (
                    <div>
                      <img
                        width="32px"
                        src={
                          "/img/country_flags/" +
                          global_jur.country_code +
                          "@3x.png"
                        }
                      />

                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {global_jur.country_code}
                      </span>
                    </div>
                  )}

                  {!global_jur &&
                    !con_jur &&
                    article.jurisdictions.length > 1 && (
                      <div>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) =>
                            setDeleteArticleJur(article.jurisdictions[0].id)
                          }
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={
                              "/img/country_flags/" +
                              article.jurisdictions[0].jurisdiction
                                .country_code +
                              "@3x.png"
                            }
                          />
                        </div>
                        <span style={{ fontWeight: "bold" }}>
                          {article.jurisdictions[0].jurisdiction.country_code}
                        </span>
                      </div>
                    )}
                </Grid>
                <Grid
                  item
                  md={global_jur ? 6 : 3}
                  style={{ textAlign: "center" }}
                >
                  {global_jur && (
                    <Grid container>
                      {article.jurisdictions &&
                        article.jurisdictions.map((art, index) => (
                          <Grid item md={4} style={{ textAlign: "center" }}>
                            <div
                              style={{ position: "relative" }}
                              onMouseOver={(e) =>
                                setDeleteArticleJur(
                                  article.jurisdictions[index].id
                                )
                              }
                              onMouseOut={(e) => setDeleteArticleJur(null)}
                            >
                              <img
                                width="32px"
                                src={
                                  "/img/country_flags/" +
                                  article.jurisdictions[index].jurisdiction
                                    .country_code +
                                  "@3x.png"
                                }
                              />
                            </div>
                            <span style={{ fontWeight: "bold" }}>
                              {
                                article.jurisdictions[index].jurisdiction
                                  .country_code
                              }
                            </span>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                  {con_jur && (
                    <div>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(con_jur.id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            con_jur.country_code +
                            "@3x.png"
                          }
                        />

                        <br />
                        <span style={{ color: "darkred", fontWeight: "bold" }}>
                          {con_jur.country_code}
                        </span>
                      </div>
                    </div>
                  )}
                  {!global_jur &&
                    !con_jur &&
                    article.jurisdictions.length === 1 && (
                      <div>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) =>
                            setDeleteArticleJur(article.jurisdictions[0].id)
                          }
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={
                              "/img/country_flags/" +
                              article.jurisdictions[0].jurisdiction
                                .country_code +
                              "@3x.png"
                            }
                          />
                        </div>
                        <span style={{ fontWeight: "bold" }}>
                          {article.jurisdictions[0].jurisdiction.country_code}
                        </span>
                      </div>
                    )}
                  {!global_jur && !con_jur && article.jurisdictions[1] && (
                    <div>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) =>
                          setDeleteArticleJur(article.jurisdictions[1].id)
                        }
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            article.jurisdictions[1].jurisdiction.country_code +
                            "@3x.png"
                          }
                        />
                      </div>
                      <span style={{ fontWeight: "bold" }}>
                        {article.jurisdictions[1].jurisdiction.country_code}
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={0} className={classes.valignCenter}>
                <Grid
                  item
                  md={1}
                  style={{ textAlign: "center", maxWidth: "48px" }}
                >
                  <div>
                    {" "}
                    {article.article_content &&
                      article.article_content.content && (
                        <IconButton size="small" onClick={(e) => setOpen(true)}>
                          <Icon>subject</Icon>
                        </IconButton>
                      )}
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Grid container spacing={3} className={classes.valignCenter}>
                    <Grid item md={9}>
                      <a
                        className={classes.header}
                        onClick={(e) => {
                          if (article.detected_language !== "en")
                            window.open(
                              article.original_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          else {
                            window.open(
                              article.original_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                        >
                          {article.translated_header || article.original_header}{" "}
                        </Typography>
                      </a>
                      <Typography
                        gutterBottom
                        variant="body3"
                        style={{ fontStyle: "italic" }}
                        component="h6"
                      >
                        {article.translated_header && (
                          <a
                            className={classes.header}
                            onClick={(e) => {
                              window.open(
                                article.original_url,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {article.original_header}
                          </a>
                        )}
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{ fontSize: "0.6rem" }}
                        component="h6"
                      >
                        {article.original_description}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          {article.tld &&
                            jurisdictions.find((j) =>
                              j.tlds
                                ? j.tlds.indexOf(article.tld) !== -1
                                : false
                            ) && (
                              <img
                                width="24px"
                                src={
                                  "/img/country_flags/" +
                                  jurisdictions.find((j) =>
                                    j.tlds
                                      ? j.tlds.indexOf(article.tld) !== -1
                                      : false
                                  ).country_code +
                                  "@3x.png"
                                }
                              />
                            )}{" "}
                          {article.publisher}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={1}>
              <Grid container>
                <Grid item md={6} style={{ marginTop: "8px" }}>
                  <Typography gutterBottom variant="body2" component="span">
                    {lang}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {" "}
                  {article.articles &&
                    article.articles.length > 0 &&
                    !multipleRemove.length && (
                      <Typography gutterBottom variant="body2" component="h6">
                        <div className={classes.valignCenter}>
                          <Button
                            style={{ color: "gray" }}
                            onClick={(e) => setExpanded(!expanded)}
                          >
                            <Icon>add_box_outlined</Icon>{" "}
                            {article.articles.length}
                          </Button>
                        </div>
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              <div style={{ float: "right" }}>
                {!article.parent_article_id && (
                  <div>
                    <Button
                      className={classes.expand}
                      style={{ color: "darkred" }}
                      onClick={(e) => handleClickBlock(article, e)}
                      title="Block Website"
                    >
                      <Icon fontSize="inherit">remove_circle_rounded</Icon>
                    </Button>
                    {!multipleRemove.length && (
                      <Button
                        size="small"
                        style={{
                          color: "darkgreen",
                        }}
                        className={classes.expand}
                        onClick={(e) => handleClickAdd(article)}
                        title="Add Source"
                      >
                        <Icon fontSize="inherit">add_box_rounded</Icon>
                      </Button>
                    )}
                  </div>
                )}
                <Button disabled className={classes.expand}></Button>
              </div>
            </Grid>
            {article.articles && article.articles.length !== 0 && (
              <Collapse
                in={expanded}
                mountOnEnter
                style={{ width: "100%" }}
                unmountOnExit
              >
                <Grid item md={12}>
                  <div>
                    <Paper
                      style={{
                        backgroundColor: "rgb(189, 189, 189)",
                        padding: "2px",
                        maxHeight: "600px",
                        overflow: "auto",
                      }}
                    >
                      {article.articles.map((art, index) => (
                        <UntrackedInboxCard
                          key={index}
                          article={art}
                          sources={sources}
                          source_types={source_types}
                          jurisdictions={jurisdictions}
                          languages={languages}
                          reassign={reassign}
                          setReassign={setReassign}
                          addReassignChildren={addReassignChildren}
                          reassignChildren={reassignChildren}
                          toReassign={
                            reassignChildren
                              .map((c) => c.id)
                              .indexOf(art.id) !== -1
                          }
                          removeReassignChildren={removeReassignChildren}
                          setUndoDeleteArticle={setUndoDeleteArticle}
                          deleteArticle={deleteArticle}
                          setUndoApproveArticle={setUndoApproveArticle}
                          approveArticle={approveArticle}
                          removeFromMother={removeFromMother}
                          inboxPosition={inboxPosition}
                          motherPosition={article.position}
                          convertToMother={convertToMother}
                          setSuccess={setSuccess}
                          setArticleNewJur={setArticleNewJur}
                          setArticleNewTag={setArticleNewTag}
                          setCurrentArticle={setCurrentArticle}
                        />
                      ))}
                    </Paper>
                  </div>
                </Grid>
              </Collapse>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteArticle: (art) => dispatch(deleteArticle(art, "_USI")),
  approveArticle: (art) => dispatch(approveArticle(art)),
  removeFromMother: (art) => dispatch(removeFromMother(art)),
  convertToMother: (art) => dispatch(convertToMother(art)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UntrackedInboxCard);
