import React from 'react'

const SaveIcon = () => (<svg xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            width="24"
                            viewBox="0 -960 960 960">
  <path
    d="M835.5-680.5V-182q0 22.969-17.266 40.234Q800.969-124.5 778-124.5H182q-22.969 0-40.234-17.266Q124.5-159.031 124.5-182v-596q0-22.969 17.266-40.234Q159.031-835.5 182-835.5h498.5l155 155ZM778-655.186 655.186-778H182v596h596v-473.186ZM479.765-248.5q41.985 0 71.61-29.39Q581-307.279 581-349.265q0-41.985-29.39-71.61-29.389-29.625-71.375-29.625-41.985 0-71.61 29.39Q379-391.721 379-349.735q0 41.985 29.39 71.61 29.389 29.625 71.375 29.625ZM235.5-584.5H589v-140H235.5v140ZM182-655.186V-182v-596 122.814Z"/>
</svg>)

export default SaveIcon
