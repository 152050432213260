import { styled } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";

import {
  clearCurrentDeleteSegment,
  clearErrorDeleteSegments,
  deleteDeleteSegment,
  getDeleteSegments,
  setBypassIoDeleteSegments,
  setCurrentDeleteSegment,
} from "../actions/delete_segments";

import { getLanguages } from "../actions/related";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import { AuthContext } from "../shared/context/auth-context";
import subscribeTo from "../utils/subscribeTo";
import AddDeleteSegmentBtn from "./AddDeleteSegmentBtn";
import DeleteSegmentForm from "./DeleteSegmentForm";

import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

const PREFIX = "DeleteSegments";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const DeleteSegments = ({
  delete_segments,
  loading,
  error,
  current,
  bypassIo,
  clearErrorDeleteSegments,
  setCurrentDeleteSegment,
  clearCurrentDeleteSegment,
  setBypassIoDeleteSegments,
  deleteDeleteSegment,
  getDeleteSegments,
  dispatch,
  getLanguages,
  allLanguages,
}) => {
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if ((!delete_segments || delete_segments.length === 0) && !error)
      getDeleteSegments();
    if (!loading) {
      subscribeTo("delete_segmentsChanged", (err, data) => {
        if (!bypassIo) getDeleteSegments();
        else {
          getDeleteSegments();
          setBypassIoDeleteSegments(false);
        }
      });
      return () => {
        subscribeTo("delete_segmentsChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, delete_segment) => {
    confirm({
      description: `Are you sure you want to delete the text "${delete_segment}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => deleteDeleteSegment(delete_segment));
  };

  const onClickEdit = (lang) => {
    setCurrentDeleteSegment(lang);
  };

  const columns = [
    {
      id: "position",
      numeric: false,
      disablePadding: false,
      label: "Pos",
      width: "5%",
    },
    {
      id: "rules",
      numeric: false,
      disablePadding: false,
      label: "Rules",
      noSelect: true,
      transform: function (row) {
        return row.rules ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.rules
                .sort((a, b) => a.localeCompare(b))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => onClickDelete(confirm, t.t)}
                      style={{ color: "gray", textDecoration: "unset" }}
                    >
                      x
                    </a>{" "}
                    {t.t}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Segments to Delete - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorDeleteSegments} />
      <React.Fragment>
        <CssBaseline />
        <DeleteSegmentForm
          current={current}
          clearCurrentDeleteSegment={clearCurrentDeleteSegment}
        />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {delete_segments.length !== 0 && allLanguages.length !== 0 && (
            <EnhancedTable
              rows={delete_segments}
              columns={columns}
              options={{
                title: "Segments to Delete Manager",
                multiple_select: false,
                hide_pagination: true,
              }}
              actions={null}
            ></EnhancedTable>
          )}
          <AddDeleteSegmentBtn />
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  delete_segments: state.delete_segments.all,
  loading: state.delete_segments.loading,
  error: state.delete_segments.error,
  current: state.delete_segments.current,
  bypassIo: state.delete_segments.bypassIo,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDeleteSegment: (id) => dispatch(deleteDeleteSegment(id)),
  getDeleteSegments: () => dispatch(getDeleteSegments()),
  clearErrorDeleteSegments: () => dispatch(clearErrorDeleteSegments()),
  setCurrentDeleteSegment: (delete_segment) =>
    dispatch(setCurrentDeleteSegment(delete_segment)),
  clearCurrentDeleteSegment: () => dispatch(clearCurrentDeleteSegment()),
  setBypassIoDeleteSegments: (bypass) =>
    dispatch(setBypassIoDeleteSegments(bypass)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegments);
