//AREA 1-1

export const entry_csssa_legal_values = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const entry_csssa_death_penalty_values = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
  {
    value: 3,
    label: "No legal certainty",
  },
];

export const entry_csssa_max_prison_values = [
  { value: 1, label: "No" },
  { value: 2, label: "N/A" },
  { value: 3, label: "Varies" },
  { value: 4, label: "Unknown" },
  {
    value: 5,
    label: "Life in prison",
  },
  { value: 6, label: "Years" },
];

export const entry_csssa_other_punishment_values = [
  { value: 1, label: "No" },
  {
    value: 2,
    label: "Forced labour",
  },
  {
    value: 3,
    label: "Forced Psychiatric Treatment",
  },
  { value: 4, label: "Flogging" },
  { value: 5, label: "Other" },
];

export const entry_csssa_penalties = [
  {
    value: 1,
    label: "No criminalisation",

    map_color: "#FFF",
  },
  {
    value: 2,
    label: "Criminalisation Varies",

    map_color: "#D1D1D1",
  },
  {
    value: 3,
    label: "De facto criminalisation",

    map_color: "#F0DACA",
  },
  {
    value: 4,
    label: "Up to 8 years imprisonment",

    map_color: "#EDB795",
  },
  {
    value: 5,
    label: "10 years to life in prison",

    map_color: "#F5934B",
  },
  {
    value: 6,
    label: "Death Penalty (possible)",

    map_color: "#D6643A",
  },
  {
    value: 7,
    label: "Death Penalty",

    map_color: "#A03E13",
  },
];

export const legal_framework_decrim_mechanisms = [
  { value: 1, label: "Judicial" },
  { value: 2, label: "Legislative" },
  { value: 3, label: "Executive" },
  { value: 4, label: "N/A" },
  {
    value: 5,
    label: "Human Rights Commission",
  },
  { value: 6, label: "Varies" },
  { value: 7, label: "Popular Vote" },
];

//AREA 1-3
export const entry_foe_barrier_education_values = [
  {
    value: 1,
    label: "Legal Barriers in Education (Non-Explicit)",
  },
  {
    value: 2,
    label: "Legal Barriers in Education (Explicit)",
  },
  {
    value: 3,
    label: "No Known Legal Barriers in Education",
  },
  {
    value: 5,
    label: "Legal Barriers in Education (Unclear)",
  },
  {
    value: 6,
    label: "Legal Barriers in Education (Varies)",
  },
];

export const entry_foe_barrier_general_values = [
  {
    value: 1,
    label: "General Legal Barriers (Non-Explicit)",
  },
  {
    value: 2,
    label: "General Legal Barriers (Explicit)",
  },
  {
    value: 3,
    label: "No Known Legal Barriers",
  },
  { value: 4, label: "N/A" },
  { value: 5, label: "Unclear" },
  { value: 6, label: "Varies" },
];

export const entry_foe_barrier_media_values = [
  {
    value: 1,
    label: "Legal Barriers in the Media (Non-Explicit)",
  },
  {
    value: 2,
    label: "Legal Barriers in the Media (Explicit)",
  },
  {
    value: 3,
    label: "No Known Legal Barriers in the Media",
  },
  {
    value: 5,
    label: "Legal Barriers in the Media (Unclear)",
  },
  {
    value: 6,
    label: "Legal Barriers in the Media (Varies)",
  },
];

export const entry_foe_barrier_summary_values = [
  {
    value: 1,
    label: "Non-Explicit Legal Barriers",
  },
  {
    value: 2,
    label: "Explicit Legal Barriers",
  },
  {
    value: 3,
    label: "No Known Legal Barriers",
  },
  { value: 4, label: "N/A" },
  { value: 5, label: "Unclear" },
  { value: 6, label: "Varies" },
];

export const entry_foa_barrier_values = [
  {
    value: 1,
    label: "Explicit Legal Barriers",
  },
  {
    value: 2,
    label: "Non-Explicit Legal Barriers",
  },
  {
    value: 3,
    label: "Legal Barriers Likely to Exist",
  },
  {
    value: 4,
    label: "No Known Legal Barriers",
  },
  { value: 5, label: "Unclear" },
  { value: 6, label: "N/A" },
];

export const entry_protection_types = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
  { value: 5, label: "Unclear" },
  { value: 6, label: "Varies" },
  { value: 7, label: "N/A" },
];

export const entry_ct_banned_types = [
  { value: 1, label: "Yes", name_es: "Sí" },
  { value: 2, label: "No", name_es: "No" },
  { value: 3, label: "Indirect", name_es: "Indirecto" },
  { value: 4, label: "Limited", name_es: "Limitado" },
  { value: 5, label: "Varies", name_es: "Varía" },
  { value: 6, label: "Unclear", name_es: "Poco claro" },
  { value: 7, label: "N/A", name_es: "N/A" },
];

export const entry_ct_values = [
  { value: 1, label: "Yes", name_es: "Sí" },
  { value: 2, label: "No", name_es: "No" },
  { value: 3, label: "Varies", name_es: "Varía" },
  { value: 4, label: "Unclear", name_es: "Poco claro" },
  { value: 5, label: "N/A", name_es: "N/A" },
];

export const entry_ssu_civil_types = [
  { value: 1, label: "Yes" },
  { value: 2, label: "Varies" },
  { value: 3, label: "Repealed or Discontinued" },
  { value: 4, label: "Unclear" },
  { value: 5, label: "N/A" },
  { value: 6, label: "No" },
];

export const entry_ssu_summary_types = [
  { value: 1, label: "Marriage" },
  { value: 2, label: "Marriage & Civil Union" },
  { value: 3, label: "Civil Union Only" },
  { value: 4, label: "Some Rights" },
  { value: 5, label: "Varies" },
  { value: 6, label: "Unclear" },
  { value: 7, label: "N/A" },
  { value: 8, label: "No" },
];

export const entry_ssu_marriage_types = [
  { value: 1, label: "Yes" },
  { value: 2, label: "Varies" },
  { value: 3, label: "Recognised if performed abroad" },
  { value: 4, label: "Unclear" },
  { value: 5, label: "N/A" },
  { value: 6, label: "No" },
];

export const entry_ado_adoption_types = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
  { value: 3, label: "Varies" },
  { value: 4, label: "Unclear" },
  { value: 5, label: "N/A" },
];

export const entry_ado_map_types = [
  { value: 1, label: "Joint & Second Parent Adoption" },
  { value: 2, label: "Second Parent Adoption Only" },
  { value: 3, label: "Varies" },
  { value: 4, label: "No adoption possible" },
  { value: 5, label: "Unclear" },
  { value: 6, label: "N/A" },
];

export const entry_pnc_types = [
  {value: 1, label: 'No'},
  {value: 2, label: 'Yes'},
  {value: 3, label: 'Varies'},
  {value: 4, label: 'Regressive measure in force'},
  {value: 5, label: 'Regressive development'}
];

export const entry_lgr_established_procedures = [
  {value: 1, label: 'Yes'},
  {value: 2, label: 'No'},
  {value: 3, label: 'Unclear'},
  {value: 4, label: 'N/A'}
];

export const entry_lgr_gm_avail_values = [
  {value: 1, label: 'Available'},
  {value: 2, label: 'Not available'},
  {value: 3, label: 'Unclear'},
  {value: 4, label: 'N/A'},
  {value: 5, label: 'Varies'},
  {value: 6, label: 'Only for Intersex'},
  {value: 7, label: 'No data'}
];

export const entry_lgr_gm_values = [
  {value: 1, label: 'Required'},
  {value: 2, label: 'Not required'},
  {value: 3, label: 'Unclear'},
  {value: 4, label: 'N/A'},
  {value: 5, label: 'Varies'},
  {value: 6, label: 'No data'}
];

export const entry_lgr_selfid_values = [
  {value: 1, label: 'Yes'},
  {value: 2, label: 'No'},
  {value: 3, label: 'Unclear'},
  {value: 4, label: 'N/A'},
  {value: 5, label: 'Varies'},
  {value: 6, label: 'No data'},
  {value: 7, label: 'Yes (for NB marker only)'}
];

export const entry_lgr_types = [
  {value: 1, label: 'Possible'},
  {value: 2, label: 'Not Possible'},
  {value: 3, label: 'Nominally Possible'},
  {value: 4, label: 'Unclear'},
  {value: 5, label: 'N/A'},
  {value: 6, label: 'No data'},
  {value: 7, label: 'Varies'},
  {value: 8, label: 'Not Possible (exceptions documented)'}
];