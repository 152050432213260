import React, { useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import EyeIcon from "../Icons/EyeIcon";
import TipTapContext from "../../../shared/context/tipTap-context";

const ViewFootnoteModal = ({ data, lang, section }) => {
  const { ctxEntry } = useContext(TipTapContext);

  const [open, setOpen] = useState(false);
  const [viewFootnote, setViewFootnote] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("ViewFootnote modal data: ", data);
  }, [data]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Tooltip title="View">
        <IconButton
          className={"what"}
          aria-label="view"
          onClick={() => {
            const order = data["data-index"];
            const language = lang;
            const footnotes = ctxEntry.entries.footnotes;
            const footnoteToView = footnotes.filter((footnote) => {
              return (
                footnote.lang === language &&
                footnote.order === order &&
                (!section || footnote.section === section)
              );
            });

            if (footnoteToView.length > 0) setViewFootnote(footnoteToView[0]);

            handleOpen();
          }}
        >
          <EyeIcon />
        </IconButton>
      </Tooltip>

      {viewFootnote && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Viewing footnote {viewFootnote.order} - {viewFootnote.text}
            </Typography>
            <div
              className="modal-tiptap-content"
              dangerouslySetInnerHTML={{ __html: viewFootnote.content }}
            />

          </Box>
        </Modal>
      )}
    </>
  );
};

export default ViewFootnoteModal;
