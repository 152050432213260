import React, { useEffect, useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import { getTiers, setCurrentTier, clearErrorTags } from "../actions/tags";
import { AuthContext } from "../shared/context/auth-context";
import TierForm from "./TierForm";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";


import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {Grid} from "@mui/material";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import { Helmet } from "react-helmet";

const PREFIX = "TierManager";

const classes = {
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: "#eee",
  },
  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },
});

const TierManager = ({
  tiers,
  loading,
  error,
  current,
  bypassIo,
  clearErrorTags,
  setCurrentTier,
  clearCurrentTier,
  getTiers,
  setBypassIoTags,
}) => {
  const auth = useContext(AuthContext);

  const columns = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Tier",
      width: "15%",
    },
    {
      id: "triggers",
      numeric: false,
      disablePadding: false,
      label: "Triggers",
      noSelect: true,
      transform: function (row) {
        return row.triggers ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.triggers
                .split(";")
                .sort((a, b) => a.localeCompare(b))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    {t.t}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      },
    },
  ];

  useEffect(() => {
    if ((!tiers || tiers.length === 0) && !error) getTiers();
  }, [loading, bypassIo]);

  const onClickEdit = (tier) => {
    setCurrentTier(tier);
  };

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tier Triggers - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorTags} />
      <React.Fragment>
        <CssBaseline />
        <TierForm
          prefilled={false}
          current={current}
          clearCurrentTier={clearCurrentTier}
        />
        <Container
          maxWidth={false}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {tiers.length !== 0 && (
            <Fragment>
              <EnhancedTable
                rows={tiers}
                columns={columns}
                options={{
                  title: "Tier Manager",
                  multiple_select: false,
                  hide_pagination: true,
                }}
                actions={{ edit: onClickEdit }}
              ></EnhancedTable>
            </Fragment>
          )}
        </Container>
      </React.Fragment>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  tiers: state.tags.tiers,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_tier,
});

const mapDispatchToProps = (dispatch) => ({
  getTiers: () => dispatch(getTiers()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTier: (tier) => dispatch(setCurrentTier(tier)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TierManager);
