import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import App from "./App";
import "./index.css";
import rootReducer from "./reducers/index";

const theme = createTheme({
  palette: {
    background: {
      default: "#65849e",
    },
    primary: {
      main: "#3f51b5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E33E7F",
    },
  },
  typography: {
    fontFamily: "Roboto Condensed",
    h1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "navy",
    },
    body1: {
      fontFamily: "Roboto Condensed",
    },
  },
  components: {
    MuiButton: {
      raisedPrimary: {
        color: "white",
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: "#3f51b5",
            color: "white",
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: "#f50057",
            color: "white",
          },
        },
      ],

      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.87)",
        },
      },
    },
  },
  custom: {
    myOwnComponent: {
      margin: "10px 10px",
      backgroundColor: "lightgreen",
    },
  },
});

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);
