import { useState, useEffect } from 'react';

const useSpinner = (delay = 2000) => {
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return showSpinner;
};

export default useSpinner;