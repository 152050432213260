import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { updateEntryFootnote } from "../../Api/EntryApi";
import RichTextTap from "./RichTextTap";
import RemoveLinkIcon from "../Icons/RemoveLinkIcon";
import SaveIcon from "../Icons/SaveIcon";
import useSpinner from "../../../hooks/useSpinner";
import TipTapContext from "../../../shared/context/tipTap-context";

const FootnoteTab = ({
  footnote,
  addingNewFootnote,
  setError,
  lang,
  editor,
  handleReload,
  columnName,
  section
}) => {
  const { ctxEntry } = useContext(TipTapContext);
  const location = useLocation();
  const history = useHistory();

  const initialFormState = {
    content: footnote && footnote.content ? footnote.content : "",
    monitor_id: footnote && footnote.monitor_id ? footnote.monitor_id : "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const extractFormData = () => {
    const formDataExtract = new FormData();

    formDataExtract.append("entryId", ctxEntry.entry_id);
    formDataExtract.append("lang", lang);
    formDataExtract.append("columnName", columnName);
    formDataExtract.append("section", section);
    formDataExtract.append("content", editor.getHTML());
    
    formDataExtract.append(
      `footnote_explanation_${lang}`,
      formData[`footnote_explanation_${lang}`]
    );
    formDataExtract.append("entry", JSON.stringify(ctxEntry.entries));
    formDataExtract.append("footnote", JSON.stringify(footnote));

    if (footnote.id) {
      formDataExtract.append("footnote_id", footnote.id);
    }

    return formDataExtract;
  };



  const saveFootnote = async () => {
    try {
      const formData = extractFormData();
      updateEntryFootnote(formData)
        .then((res) => {
          //console.log("res in footnote tab: ", footnote)
        })
        .catch((err) => console.log("error1: ", err));
      handleReload(location, history);
    } catch (e) {
      setError(e.message);
    }
  };
  const showSpinner = useSpinner();

  useEffect(() => {
    if (!addingNewFootnote) {
      const newFormState = {
        content: footnote && footnote.content ? footnote.content : "",
        monitor_id: footnote && footnote.monitor_id ? footnote.monitor_id : "",
      };

      setFormData(newFormState);
    }
  }, [footnote]);

  return (
    <div className="CurrentSourceForm">
      {showSpinner && (
        <div className="DrawerCover">
          <CircularProgress color="inherit" />
        </div>
      )}
      {footnote ? (
        <div className="DrawerEntry">Footnote on: "{footnote.text}"</div>
      ) : (
        "no footnote"
      )}

      <div className="form__container">
        <RichTextTap
          content={formData.content}
          update={(content) => {
            setFormData({
              ...formData,
              [`footnote_explanation_${lang}`]: content,
            });
          }}
        />
      </div>

      <div className="Drawer__header-actions">
        <Tooltip title="Save Footnote">
          <span>
            <IconButton
              variant="contained"
              size="small"
              aria-label="close"
              color="primary"
              className="Drawer__save"
              onClick={saveFootnote}
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip
          title={
            addingNewFootnote ? "Cancel new footnote" : "Close footnotes edit"
          }
        >
          <span>
            <IconButton
              variant="contained"
              size="small"
              aria-label="close"
              color="primary"
              className="Drawer__close"
              onClick={() => {
                handleReload(location, history);
              }}
            >
              <RemoveLinkIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default FootnoteTab;
