import React from "react";
import StarterKit from "@tiptap/starter-kit";
import { useEditor, EditorContent } from "@tiptap/react";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import Link from "@tiptap/extension-link";
import Footnote from "./Extensions/Footnote";
import MenuBar from "./MenuBar";

import "./styles.css";

const RichTextTap = ({ content = "", update }) => {
  const editor = useEditor(
    {
      extensions: [
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Link.configure({
          openOnClick: false,
        }),
        Footnote,
      ],
      content,
      enableInputRules: false,
      enablePasteRules: false,
      onBlur: ({ editor }) => {
        const htmlString = editor.getHTML();

        update(htmlString);
      },
    },
    [content]
  );

  return (
    <div>
      <div className={`editorMain tiptap-no-sources`}>
        <div className="TipTapContainer">
          <MenuBar editor={editor} noSource={true} />

          <EditorContent editor={editor} disabled={true} />
        </div>

        <div style={{ display: "none" }}>{editor && editor.getHTML()}</div>
      </div>
    </div>
  );
};

export default RichTextTap;
