import {Mark, mergeAttributes, Node} from "@tiptap/core";

export const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/;
export const starPasteRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))/g;
export const underscoreInputRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))$/;
export const underscorePasteRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g;

const replace = (editor, me) => {
  const footnoteRegex= /((?:\_)((?:[^_]+))(?:\_))/g;

  let i = 0;
  editor.state.doc.descendants((node, pos) => {
    if (node.isText) {
      let match;

      while ((match = footnoteRegex.exec(node.text)) !== null) {
        //debugger;

        const startPos = pos + match.index - i * 2;
        const endPos = startPos + match[0].length;

        const from = startPos; // Exclude starting asterisk
        const to = endPos; // Exclude ending asterisk

        editor.commands.deleteRange({ from: from, to: to });
        editor.commands.insertContentAt(
          from,
          `<custom-footnote data-index="${i+1}" class="footnote">${match[2]
            .replace(/\*/gi, "")}</custom-footnote>`
        );

        editor.commands.toggleFootnote();

        i++;
      }
    }
  });
};

export const Footnote = Mark.create({
  name: 'customFootnote',
  exitable: true,
  spanning: true,

  onCreate({ editor }) {
    replace(editor, this);

    this.editor.on("transaction", ({ transaction, editor }) => {
      return replace(editor, this);
    });

    this.editor.on("selectionUpdate", ({ transaction, editor }) => {
      if (
        editor.state.selection.$anchor === editor.state.selection.$head && //fix for when selecting multiple to not dissappear
        !editor.state.selection.$anchor
          .marks()
          .find((m) => m.type.name === "customFootnote")
      ) {
        editor.commands.unsetFootnote();
      }
    });
  },

  addOptions() {
    return {
      HTMLAttributes: { class: "customFootnote" },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'custom-footnote',
        attrs: {
          class: 'footnote'
        }
      },
    ]
  },

  addAttributes() {
    // Return an object with attribute configuration
    return {
      "data-index": {
        default: "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'custom-footnote',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ]
  },

  addCommands() {
    return {
      setFootnote:
        () =>
          ({ commands }) => {
            return commands.setMark(this.name);
          },
      toggleFootnote: () => ({ commands }) => {
        return commands.toggleMark(this.name)
      },
      unsetFootnote:
        () =>
          ({ commands }) => {
            return commands.unsetMark(this.name);
          },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-f': () => this.editor.commands.toggleFootnote(),
      'Mod-F': () => this.editor.commands.toggleFootnote()
    }
  },
})