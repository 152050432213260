import React from 'react'

const EditIcon = () => {
  return   <svg className="editBtn__fix"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 -960 960 960" ><path d="M182-16.5q-22.969 0-40.234-17.266Q124.5-51.03 124.5-74v-596q0-22.969 17.266-40.234Q159.031-727.5 182-727.5h403.5L528-670H182v596h596v-349.5l57.5-57.5v407q0 22.969-17.266 40.234Q800.969-16.5 778-16.5H182ZM480-372Zm184-352.5 41.5 40.5L419-399v88h87l287-287.5 40.5 40.5-304 304.5h-168v-168l302.5-303ZM833.5-558 664-724.5l100-100q16.5-16 40.561-16t40.939 17l85.5 87q16.5 17.646 16.5 41.006 0 23.359-17.5 39.494l-96.5 98Z"/></svg>
}

export default EditIcon
