import "./styles.css";

import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { PluginKey } from "@tiptap/pm/state";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Drawer from "./Drawer";
import Footnote from "./Extensions/Footnote";
import Source from "./Extensions/Source";
import SourceReference from "./Extensions/SourceReference";
import MenuBar from "./MenuBar";
import { useDispatch, useSelector } from "react-redux";
import { setEn, setEs } from "../../../reducers/sources_validation";
import TipTapContext from "../../../shared/context/tipTap-context";
import SuccessSnack from "../SuccessSnack";
import ErrorSnack from "../ErrorSnack";

export const key = new PluginKey("Source");

export const getMarks = (editor, markName) => {
  if (editor) {
    return editor.getJSON().content?.flatMap((paragraph) =>
      paragraph.content
        ?.filter(
          (textObj) =>
            textObj.marks &&
            textObj.marks.some((mark) => mark.type === markName)
        )
        .map((textObj) => {
          return {
            text: textObj.text,
            ...textObj.marks.find((mark) => mark.type === markName).attrs, //add attributes
          };
        })
    );
  }
};

const TipTap = ({
  content,
  lang,
  columnName,
  entry,
  hasSources = false,
  activeInDrawer,
  setActiveInDrawer,
  section,
  setBlockedByTipTap,
}) => {
  const {
    ctxEntry,
    addingNewFootnote,
    setAddingNewFootnote,
    changeCtxSource,
    openSuccessSnack,
    closeSuccessSnack,
    snackSuccessMessage,
    showSuccessSnack,
    setSnackSuccessMessage,
    openErrorSnack,
    closeErrorSnack,
    snackErrorMessage,
    setAddingNewSource,
    changeColumn,
  } = useContext(TipTapContext);
  const [files, setFiles] = React.useState("");
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const dispatch = useDispatch();
  const sourcesValidation = useSelector((state) => state.sourcesValidation);

  const setValidation = (language, number, columnName = null) => {
    if (language === "en") {
      dispatch(setEn(number, columnName));
    } else if (language === "es") {
      dispatch(setEs(number, columnName));
    }
  };

  useEffect(() => {
    changeColumn(columnName);
  }, [columnName]);

  useEffect(() => {
    console.log("VALIDATION");
    if (content && hasSources) {
      const asteriskRegex = /((?:\*)((?:[^*]+))(?:\*))/g;
      const match = content.match(asteriskRegex);
      if (match) setValidation(lang, match.length, columnName);
    }
  }, [content]);

  const editor = useEditor(
    {
      extensions: useMemo(() => [
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Link.configure({
          openOnClick: true,
        }),
        Footnote,
        Source.configure({
          files: files,
          setFiles: setFiles,
        }),
        SourceReference,
      ]),
      content,
      enableInputRules: false,
      enablePasteRules: false,
    },
    [content]
  );

  useEffect(() => {
    if (editor) editor.setEditable(!openDrawer);
  }, [openDrawer]);

  const [sourceAdded, setSourceAdded] = useState(false);

  useEffect(() => {
    if (editor) {
      // ignore asterisk and underscore in user input
      const handleKeydown = (event) => {
        if (event.key === "*" || event.key === "_") {
          //TODO! - could use a notification for user like toast
          console.log("Ignored event with forbidden characters!");
          event.preventDefault();
          event.stopPropagation();
        }
      };

      const transactionHandler = ({ editor, transaction }) => {
        if (
          transaction.steps.some(
            (step) => step.mark && step.mark.type.name === "source"
          )
        ) {
          const { from, to } = editor.state.selection;
          const { state } = editor;
          const newMarkText = editor.state.doc.textBetween(from, to);

          let countTextOccurrences = 0;
          state.doc.descendants((node, pos) => {
            if (node.isText) {
              if (node.text === newMarkText) {
                countTextOccurrences++;
              }
            }
          });

          if (lang === "en") {
            if (countTextOccurrences > 1) {
              const matchingSource = ctxEntry.entries.sources.find(
                (source) => source.text === newMarkText
              );
              if (matchingSource) {
                console.log(
                  "matching source in english reference: ",
                  matchingSource
                );
                changeCtxSource(matchingSource);
                let newTransaction = editor.state.tr;
                const sourceMark = state.schema.marks.source.create();
                newTransaction = newTransaction.removeMark(
                  from,
                  to,
                  sourceMark
                );
                const sourceReferenceMark =
                  state.schema.marks.sourceReference.create({
                    "data-order": matchingSource.order,
                    "data-ref-id": matchingSource.id,
                  });
                newTransaction = newTransaction.addMark(
                  from,
                  to,
                  sourceReferenceMark
                );
                setSnackSuccessMessage("Adding a source reference!");
                editor.view.dispatch(newTransaction);
              }
            }
          } else {
            console.log("countTextOccurences: ", countTextOccurrences);
            if (countTextOccurrences > 1) {
              const findCustomSourceElement = (editor, content) => {
                let foundDataOriginalSource = null;

                // Traverse the document model
                editor.state.doc.descendants((node, pos) => {
                  // Check if the node is text and contains the content we are looking for
                  if (node.isText && node.text.includes(content)) {
                    // Loop through the marks applied to this text node
                    node.marks.forEach((mark) => {
                      // Check if this mark is of type "customSource"
                      if (
                        mark.type.name === "source" &&
                        mark.attrs["data-original-source"]
                      ) {
                        // We found it! Store the data-original-source attribute
                        foundDataOriginalSource =
                          mark.attrs["data-original-source"];
                      }
                    });
                  }
                });

                return foundDataOriginalSource;
              };

              const content = newMarkText;
              let dataOriginalSource = findCustomSourceElement(editor, content);

              if (dataOriginalSource) {
                showSuccessSnack();
                setSnackSuccessMessage("Adding a source reference!!!!!");
                console.log(
                  `Found the custom-source with content "${content}" and data-original-source is ${dataOriginalSource}.`
                );
              } else {
                console.log(
                  `Did not find any custom-source with content "${content}".`
                );
              }
              console.log(
                "dataOriginalSource in TipTap thing !!!! - > ",
                dataOriginalSource
              );

              dataOriginalSource++;
              const matchingSource = ctxEntry.entries.sources.find(
                (source) => source.order === dataOriginalSource
              );
              if (matchingSource) {
                console.log(
                  "matchingSource in TipTap thing ES!!!!!: ",
                  matchingSource
                );
                changeCtxSource(matchingSource);
                let newTransaction = editor.state.tr;
                const sourceMark = state.schema.marks.source.create();
                newTransaction = newTransaction.removeMark(
                  from,
                  to,
                  sourceMark
                );
                const sourceReferenceMark =
                  state.schema.marks.sourceReference.create({
                    "data-order":
                      lang === "es"
                        ? matchingSource.order
                        : matchingSource.order + 1,
                    "data-ref-id": matchingSource.id,
                  });
                newTransaction = newTransaction.addMark(
                  from,
                  to,
                  sourceReferenceMark
                );

                editor.view.dispatch(newTransaction);
              } else {
                console.log(
                  "SHOULD ADD AN ACTUAL SOURCE HERE but not for espanish"
                );

                if (lang === "es") return;
                setAddingNewSource(true);
              }
            }
          }
          setOpenDrawer(true);
          setSourceAdded((prevState) => !prevState);
        }

        if (
          transaction.steps.some(
            (step) => step.mark && step.mark.type.name === "customFootnote"
          )
        ) {
          setOpenDrawer(true);
          setSourceAdded((prevState) => !prevState);
        }

        if (
          transaction.steps.some(
            (step) => step.mark && step.mark.type.name === "sourceReference"
          )
        ) {
          setOpenDrawer(true);
          setSourceAdded((prevState) => !prevState);
        }
      };

      editor.view.dom.addEventListener("keydown", handleKeydown);
      editor.on("transaction", transactionHandler);

      return () => {
        editor.view.dom.removeEventListener("keydown", handleKeydown);
        editor.off("transaction", transactionHandler);
      };
    }
  }, [editor]);

  const [hasActiveSelection, setHasActiveSelection] = useState(false);

  useEffect(() => {
    if (editor) {
      editor.on("transaction", ({ transaction }) => {
        const selectionIsEmpty = transaction.selection.empty;
        setHasActiveSelection(!selectionIsEmpty);
      });
    }

    return () => {
      // Clean up the event listener when the component is unmounted
      if (editor) {
        editor.off("transaction");
      }
    };
  }, [editor]);

  return (
    <>
      <div
        className={
          sourcesValidation[columnName + "_en"] !==
          sourcesValidation[columnName + "_es"]
            ? "showInvalid"
            : ""
        }
      >
        {sourcesValidation[columnName + "_en"] !==
          sourcesValidation[columnName + "_es"] && lang === "es" ? (
          <div>
            <p className="form__errors-TipTap">
              Please fill out source field in both languages
            </p>
          </div>
        ) : (
          <div>
            <p className="form__errors-TipTap"></p>
          </div>
        )}
        {ctxEntry.entries.footnotes &&
          ctxEntry.entries.footnotes.length > 0 &&
          ctxEntry.entries.footnotes.filter(
            (f) => f.lang === "en" && (!section || f.section === section)
          ).length !==
            ctxEntry.entries.footnotes.filter(
              (f) => f.lang === "es" && (!section || f.section === section)
            ).length && (
            <div>
              <p className="form__errors-TipTap">
                Warning: The number of footnotes in English and Spanish do not match
              </p>
            </div>
          )}
        <div className={`editorMain ${!hasSources ? "tiptap-no-sources" : ""}`}>
          <div className="TipTapContainer" lang={lang}>
            <MenuBar
              editor={editor}
              hasActiveSelection={hasActiveSelection}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              lang={lang}
              columnName={columnName}
              activeInDrawer={activeInDrawer}
              setActiveInDrawer={setActiveInDrawer}
              addingNewFootnote={addingNewFootnote}
              setAddingNewFootnote={setAddingNewFootnote}
              section={section}
            />

            <div className="TipTapEditorContainer">
              {(openDrawer ||
                (sourcesValidation[columnName + "_en"] !==
                  sourcesValidation[columnName + "_es"] &&
                  lang === "es")) &&
                activeInDrawer &&
                setActiveInDrawer && (
                  <Drawer
                    key={sourceAdded}
                    hasSources={hasSources}
                    content={content}
                    editor={editor}
                    entry={entry}
                    lang={lang}
                    section={section}
                    columnName={columnName}
                    activeInDrawer={activeInDrawer}
                    setActiveInDrawer={setActiveInDrawer}
                    isInvalid={
                      sourcesValidation[columnName + "_en"] !==
                      sourcesValidation[columnName + "_es"]
                    }
                  />
                )}
              <EditorContent editor={editor} disabled={true} />
            </div>
          </div>
          <div style={{ display: "none" }}>
            Files:{" "}
            {editor &&
              editor
                .getJSON()
                .content?.flatMap((paragraph) =>
                  paragraph.content
                    ?.filter(
                      (textObj) =>
                        textObj.marks &&
                        textObj.marks.some((mark) => mark.type === "source")
                    )
                    .map((textObj) => textObj.text)
                )
                .join(", ")}
          </div>
          <div style={{ display: "none" }}>
            Footnotes:{" "}
            {editor &&
              editor
                .getJSON()
                .content?.flatMap((paragraph) =>
                  paragraph.content
                    ?.filter(
                      (textObj) =>
                        textObj.marks &&
                        textObj.marks.some((mark) => mark.type === "footnote")
                    )
                    .map((textObj) => textObj.text)
                )
                .join(", ")}
          </div>
        </div>
      </div>

      <SuccessSnack
        open={openSuccessSnack}
        handleClose={closeSuccessSnack}
        textContent={snackSuccessMessage}
      />

      <ErrorSnack
        open={openErrorSnack}
        handleClose={closeErrorSnack}
        textContent={snackErrorMessage}
      />
    </>
  );
};

export default TipTap;
