import React, { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CssBaseline from "@mui/material/CssBaseline";
import {
  addDeleteSegment,
  clearCurrentDeleteSegment,
  clearErrorDeleteSegments,
  deleteDeleteSegment,
  getDeleteSegments,
  setCurrentDeleteSegment,
  setOpenDeleteSegment,
  updateDeleteSegment,
} from "../actions/delete_segments";

import { getLanguages } from "../actions/related";

const PREFIX = 'DeleteSegmentForm';

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  }
}));

const emptyDeleteSegment = {
  rules: "",
  position: "end",
};

const DeleteSegmentForm = ({
  current,
  open,
  clearCurrentDeleteSegment,
  updateDeleteSegment,
  setOpenDeleteSegment,
  addDeleteSegment,
  loading,
  allLanguages,
  getLanguages,
}) => {

  const [delete_segment, setDeleteSegment] = useState(emptyDeleteSegment);

  const { position, rules } = delete_segment;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theRoles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Editor" },
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setDeleteSegment(current);
    } else {
      setDeleteSegment(emptyDeleteSegment);
    }
  }, [current, open]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenDeleteSegment(false);
    clearCurrentDeleteSegment();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addDeleteSegment(delete_segment);
      } else {
        updateDeleteSegment(delete_segment);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenDeleteSegment(false);
    clearCurrentDeleteSegment();
  }

  const onChange = (e, newVal = null) => {
    console.log(newVal);
    if (e === "rules") {
      setDeleteSegment({
        ...delete_segment,
        rules: newVal ? newVal : "",
      });
    } else if (e === "position") {
      setDeleteSegment({
        ...delete_segment,
        position: newVal.id,
      });
    }
  };

  const handleChange = (chips) => {
    setDeleteSegment({ ...delete_segment, rules: chips });
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down('lg'))
            ? { width: "60%", height: "60%", margin: "auto" }
            : { width: "95%", height: "95%", margin: "auto" }
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Entry"
            : current && !open
            ? "Edit Entry: " + current.original_header
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <input type="hidden" autocomplete="false" />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  fullWidth
                  placeholder="Input segments to delete for the position selected. One per line."
                  multiline
                  rows={20}
                  maxRows={20}
                  name="rules"
                  onChange={(e) => onChange("rules", e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={12} md={2}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={[
                      { id: "start", name: "Start" },
                      { value: "end", name: "End" },
                    ]}
                    disableClearable={true}
                    autoComplete="off"
                    classes={{
                      option: classes.option,
                    }}
                    size="small"
                    onChange={(e, newVal) => onChange("position", newVal)}
                    autoHighlight
                    defaultValue={{ value: "end", name: "End" }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Position"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>{" "}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  delete_segments: state.delete_segments.all,
  loading: state.delete_segments.loading,
  error: state.delete_segments.error,
  current: state.delete_segments.current,
  open: state.delete_segments.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDeleteSegment: (id) => dispatch(deleteDeleteSegment(id)),
  getDeleteSegments: () => dispatch(getDeleteSegments()),
  clearErrorDeleteSegments: () => dispatch(clearErrorDeleteSegments()),
  setCurrentDeleteSegment: (delete_segment) =>
    dispatch(setCurrentDeleteSegment(delete_segment)),
  setOpenDeleteSegment: (should) => dispatch(setOpenDeleteSegment(should)),
  clearCurrentDeleteSegment: () => dispatch(clearCurrentDeleteSegment()),
  addDeleteSegment: (delete_segment) =>
    dispatch(addDeleteSegment(delete_segment)),
  updateDeleteSegment: (delete_segment) =>
    dispatch(updateDeleteSegment(delete_segment)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegmentForm);
