import axios from "axios";

export const updateEntrySource = async (formData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/updateEntrySource",
      formData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateEntrySourceReference = async (formData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/updateEntrySourceReference",
      formData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateEntryFile = async (formData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/upload", // update entry source is all here...
      formData
    );
    return responseData.data;
  } catch (err) {
    console.log("err222: ", err)
  }
}

export const deleteEntrySource = async (postData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/deleteEntrySource",
      postData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteEntryFile = async (postData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/deleteEntryFile",
      postData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateEntryFootnote = async (formData) => {

  console.log("formDataFOOTNOTE: ", formData)

  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/updateEntryFootnote",
      formData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};


export const deleteEntryFootnote = async (postData) => {
  try {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/database/deleteEntryFootnote",
      postData
    );
    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};
