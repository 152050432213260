import axios from "axios";

export const getLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/languages"
      );

      dispatch({
        type: "GET_LANGUAGES",
        payload: {
          languages: responseData.data.languages,
          all_languages: responseData.data.all_languages
        }
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSourceTypes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/source_types"
      );

      dispatch({
        type: "GET_SOURCE_TYPES",
        payload: responseData.data.source_types
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSourceStatuses = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/source_statuses"
      );

      dispatch({
        type: "GET_SOURCE_STATUSES",
        payload: responseData.data.source_statuses
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getJurisdictions = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/jurisdictions"
      );

      dispatch({
        type: "GET_JURISDICTIONS",
        payload: responseData.data.jurisdictions
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const updateJurisdictionsTlds = (jur_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/jurisdictions_n_usi_minus_1/" + jur_id
      );

      dispatch({
        type: "N_USI_MINUS_1",
        payload: jur_id
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};



export const getTags = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/tags"
      );

      dispatch({
        type: "GET_TAGS",
        payload: responseData.data.tags
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSyntaxTypes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/syntax_types"
      );

      dispatch({
        type: "GET_SYNTAX_TYPES",
        payload: responseData.data.syntax_types
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getAlertSubaccounts = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/alert_subaccounts"
      );

      dispatch({
        type: "GET_ALERT_SUBACCOUNTS",
        payload: responseData.data.alert_subaccounts
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getLanguageSyntaxes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/language_syntaxes"
      );

      dispatch({
        type: "GET_LANGUAGE_SYNTAXES",
        payload: responseData.data.language_syntaxes
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};
