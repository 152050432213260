import { CircularProgress, IconButton } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getMarks } from "./TipTap";

import ErrorSnack from "../../../shared/components/UIElements/ErrorSnack";
import { updateEntrySource } from "../../Api/EntryApi";
import { setEn, setEs } from "../../../reducers/sources_validation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SourceMarksRenderer from "./Extensions/Source/SourceMarksRenderer";
import FootnoteTab from "./FootnoteTab";
import SourceIcon from "../Icons/SourceIcon";
import useLengthUpdater from "../../../hooks/useLengthUpdater";
import OListIcon from "../Icons/OListIcon";
import Tooltip from "@mui/material/Tooltip";
import SetSourceForm from "../SetSourceForm";
import FootnoteMarksRenderer from "./Extensions/Footnote/FootnoteMarksRenderer";
import TipTapContext from "../../../shared/context/tipTap-context";
import SaveIcon from "../Icons/SaveIcon";
import RemoveLinkIcon from "../Icons/RemoveLinkIcon";

export default function Drawer({
  editor,
  lang = "",
  columnName = null,
  hasSources = false,
  activeInDrawer,
  setActiveInDrawer,
  section = null,
  isInvalid,
}) {
  const {
    ctxEntry,
    ctxCountryCode,
    addingNewSource,
    setAddingNewSource,
    addingNewFootnote,
    setAddingNewFootnote,
    ctxColumn,
  } = useContext(TipTapContext);

  const location = useLocation();
  const history = useHistory();

  const [currentSource, setCurrentSource] = useState(null);
  const [currentFileOrOff, setCurrentFileOrOff] = useState(null);
  const [newLanguageOrOff, setNewLanguageOrOff] = useState(null);
  const [newLanguageOrOff2, setNewLanguageOrOff2] = useState(null);
  const [currentFileOr, setCurrentFileOr] = useState(null);
  const [newLanguageOr, setNewLanguageOr] = useState(null);
  const [newLanguageOr2, setNewLanguageOr2] = useState(null);
  const [currentFileTr, setCurrentFileTr] = useState(null);
  const [error, setError] = useState(null);
  const currentFileOrOffRef = useRef();
  const currentFileOrRef = useRef();
  const currentFileTrRef = useRef();

  const cleanSourcesDrawerState = () => {
    setCurrentFileOrOff(null);
    setCurrentFileOr(null);
    setCurrentFileTr(null);
    setNewLanguageOrOff(null);
    setNewLanguageOrOff2(null);
    setNewLanguageOr(null);
    setNewLanguageOr2(null);
  };

  useEffect(() => {
    cleanSourcesDrawerState();
  }, []);

  useEffect(() => {
    console.log("addingNewSource: ", addingNewSource);
  }, [addingNewSource]);

  useEffect(() => {
    console.log("currentSource: ", currentSource);
    console.log("ctxEntry: ", ctxEntry);
    console.log("addingNewSource: ", addingNewSource);

    if (currentSource && ctxEntry && !addingNewSource) {
      const containingSource = ctxEntry.entries.sources
        .filter((s) => !section || s.section === section)
        .filter((source) => source.order === currentSource.order)[0];

      setNewLanguageOr(containingSource.original_language_id);
      setNewLanguageOrOff(containingSource.original_official_language_id);

      if (containingSource.original_language_id2)
        setNewLanguageOr2(containingSource.original_language_id2);
      if (containingSource.original_official_language_id2)
        setNewLanguageOrOff2(containingSource.original_official_language_id2);
    } else {
      cleanSourcesDrawerState();
    }
  }, [currentSource]);

  const [newSourceToAdd, setNewSourceToAdd] = useState({});

  const getTheMarks = useCallback((editor, markName) => {
    return editor.getJSON().content?.flatMap((paragraph) =>
      paragraph.content
        ?.filter(
          (textObj) =>
            textObj.marks &&
            textObj.marks.some((mark) => mark.type === markName)
        )
        .map((textObj) => {
          return {
            text: textObj.text,
            ...textObj.marks.find((mark) => mark.type === markName).attrs, //add attributes
          };
        })
    );
  }, []);

  const [sources, setSources] = useState(
    ctxEntry.entries.sources.sort((a, b) => a.order - b.order)
  );
  const [year, setYearValue] = useState({
    oroff: "",
    or: "",
    tr: "",
  });
  const handleYearChange = (key, value) => {
    setYearValue((previousData) => ({ ...previousData, [key]: value }));
  };
  const [radio, setRadioValue] = useState("");

  useEffect(() => {
    if (currentSource && !addingNewSource) {
      if (currentSource["data-original-official-filename"]) {
        const radioValue =
          currentSource["data-original-official-filename"].split(" - ")[1];
        setRadioValue(radioValue);
        return;
      }
      if (currentSource["data-original-filename"]) {
        const radioValue =
          currentSource["data-original-filename"].split(" - ")[1];
        setRadioValue(radioValue);
        return;
      }
      if (currentSource["data-translated-filename"]) {
        const radioValue =
          currentSource["data-translated-filename"].split(" - ")[1];
        setRadioValue(radioValue);
        return;
      }
    }
  }, [currentSource]);

  useEffect(() => {
    if (section)
      setSources(
        ctxEntry.entries.sources
          .filter((s) => !section || s.section === section)
          .sort((a, b) => a.order - b.order)
      );
  }, [section]);
  const extractFormData = () => {
    if (!ctxCountryCode && lang !== "es") {
      // I think this bug is from react dev tools and only happens when I change code
      throw new Error("Something went wrong!!!: ", ctxCountryCode);
    }
    const hasOrOffFile =
      currentFileOrOffRef.current &&
      currentFileOrOffRef.current.files &&
      currentFileOrOffRef.current.files[0];
    const hasOrFile =
      currentFileOrRef.current &&
      currentFileOrRef.current.files &&
      currentFileOrRef.current.files.length;
    const hasTrFile =
      currentFileTrRef.current &&
      currentFileTrRef.current.files &&
      currentFileTrRef.current.files[0];

    if (!hasOrOffFile && !hasOrFile && !hasTrFile && lang !== "es") {
      throw new Error("Nothing to save! Please upload a file");
    }

    const formData = new FormData();
    let oneSource = false;

    formData.append("entryId", ctxEntry.entry_id);
    formData.append("columnName", columnName);
    formData.append("section", section);
    formData.append("country_code", ctxCountryCode);

    if (hasOrOffFile && lang !== "es") {
      console.log("currentFileOrOffRef: ", currentFileOrOffRef);
      if (!radio) {
        throw new Error("Please Make sure you pick source type!");
      }
      if (!year.oroff) {
        throw new Error(
          "Please Make sure you select year for the OROFF file!!"
        );
      }
      oneSource = true;
      formData.append("fileOrOff", currentFileOrOffRef.current.files[0]);
      if (currentFileOrOffRef.current.files[0].name)
        formData.append(
          "fileOrOffName",
          currentFileOrOffRef.current.files[0].name
        );
    }
    if (hasOrFile && lang !== "es") {
      if (!radio) {
        throw new Error("Please Make sure you pick source type!");
      }
      if (!year.or) {
        throw new Error("Please Make sure you select year for the OR file!!");
      }
      oneSource = true;
      formData.append("fileOr", currentFileOrRef.current.files[0]);
      if (currentFileOrRef.current.files[0].name)
        formData.append("fileOrName", currentFileOrRef.current.files[0].name);
    }
    if (hasTrFile && lang !== "es") {
      if (!radio) {
        throw new Error(
          "Please Make sure you pick source type for translated file!"
        );
      }
      if (!year.tr) {
        throw new Error(
          "Please Make sure you select year for the translated file!"
        );
      }
      formData.append("fileTr", currentFileTrRef.current.files[0]);
      if (currentFileTrRef.current.files[0].name)
        formData.append("fileTrName", currentFileTrRef.current.files[0].name);
    }

    if (newLanguageOrOff && lang !== "es") {
      formData.append("languageOrOff", newLanguageOrOff);
      if (newLanguageOrOff2) {
        formData.append("languageOrOff2", newLanguageOrOff2);
      }
    } else if (
      currentFileOrOffRef.current &&
      currentFileOrOffRef.current.files &&
      currentFileOrOffRef.current.files[0]
    ) {
      throw new Error(
        "Please select a language for the original official file"
      );
    }

    if (newLanguageOr && lang !== "es") {
      formData.append("languageOr", newLanguageOr);
      if (currentFileOrRef.current && newLanguageOr2) {
        formData.append("languageOr2", newLanguageOr2);
      }
    } else if (
      currentFileOrRef.current &&
      currentFileOrRef.current.files &&
      currentFileOrRef.current.files[0]
    ) {
      throw new Error("Please select a language for the original file");
    }

    formData.append("lang", lang);
    if (lang === "en") {
      formData.append("order", currentSource.order + 1);
      formData.append("text", currentSource.text);
      formData.append("currentSource", JSON.stringify(currentSource));
    } else {
      formData.append("order", currentSource.order);
    }

    formData.append("entry", JSON.stringify(ctxEntry.entries));
    formData.append("content", editor.getHTML());
    formData.append("year", JSON.stringify(year));
    formData.append("radio", radio);

    return formData;
  };

  const dispatch = useDispatch();
  const setValidation = (language, number, columnName = null) => {
    console.log("VALIDATION");
    if (language === "en") {
      dispatch(setEn(number, columnName));
    } else if (language === "es") {
      dispatch(setEs(number, columnName));
    }
  };

  //current source useEffect
  useEffect(() => {
    if (lang === "en") {
      if (currentSource && currentSource.stop) {
        return;
      }

      if (!currentSource) {
        cleanSourcesDrawerState();
      }

      sources.forEach((src, srcIndex) => {
        if (addingNewSource) {
          setCurrentSource({
            stop: true,
            addingNewSource: true,
            order: currentSource.order,
            text: currentSource.text,
          });
          return;
        }
        if (
          currentSource &&
          srcIndex === currentSource.order - 1 &&
          !currentSource.stop &&
          !addingNewSource
        ) {
          let newCurrentSource = {
            ...currentSource,
          };

          if (
            currentSource &&
            currentSource["data-original-official-filename"] !==
              src.original_official_filename &&
            src.original_official_filename
          ) {
            newCurrentSource["data-original-official-filename"] =
              src.original_official_filename
                ? src.original_official_filename
                : undefined;
          } else if (!src.original_official_filename) {
            newCurrentSource["data-original-official-filename"] = null;
          }
          if (
            currentSource &&
            currentSource["original-official-language-id"] !==
              src.original_official_language_id &&
            src.original_official_language_id
          ) {
            newCurrentSource["original-official-language-id"] =
              src.original_official_language_id
                ? src.original_official_language_id
                : undefined;
          } else if (!src.original_official_language_id) {
            newCurrentSource["original-official-language-id"] = null;
          }

          if (
            currentSource &&
            currentSource["data-original-filename"] !== src.original_filename &&
            src.original_filename
          ) {
            newCurrentSource["data-original-filename"] = src.original_filename
              ? src.original_filename
              : undefined;
          } else if (!src.original_filename) {
            newCurrentSource["data-original-filename"] = null;
          }

          if (
            currentSource &&
            currentSource["original-language-id"] !==
              src.original_language_id &&
            src.original_language_id
          ) {
            newCurrentSource["original-language-id"] = src.original_language_id
              ? src.original_language_id
              : undefined;
          } else if (!src.original_language_id) {
            newCurrentSource["original-language-id"] = null;
          }

          if (!addingNewSource) {
            newCurrentSource["source-id"] = src.id;
          } else {
            newCurrentSource["adding-new"] = addingNewSource;
          }

          newCurrentSource.stop = true;
          setCurrentSource(newCurrentSource);
        }
      });
    } else {
      console.log("this is espanish sources!!!");
      if (addingNewSource) {
        console.log("current source in espanish -> ", currentSource);
      }
    }
  }, [currentSource]);

  const saveEsSource = async () => {
    // TODO!!! - THIS IS SPANISH SAVE SOURCE
    if (addingNewSource) {
      try {
        const formData = extractFormData();
        updateEntrySource(formData)
          .then((res) => {
            if (editor && hasSources) {
              const asteriskRegex = /((?:\*)((?:[^*]+))(?:\*))/g;
              const match = res.match(asteriskRegex);
              if (match) setValidation(lang, match.length, columnName);
            }
            handleReload(location, history);
          })
          .catch((err) => console.log("error133333: ", err));
      } catch (e) {
        setError(e.message);
      }
    } else {
      console.log("cant do this right now!");
      // can't do this right now
    }
  };

  const changeNewSource = (source) => {
    setCurrentSource(source);
  };
  const changeAddingState = (adding) => {
    setAddingNewSource(adding);
  };

  const changeNewSourceToAdd = (source) => {
    setNewSourceToAdd(source);
  };
  // SOURCES ABOVE

  // FOOTNOTES BELLOW
  const [currentFootnote, setCurrentFootnote] = useState(null);
  const [showProgress, setShowProgress] = useState(true);
  useEffect(() => {
    const getFootnotesNumber = () => {
      const footnotes = getMarks(editor, "customFootnote");
      if (footnotes && footnotes.length) {
        updateFootnotesNumber(footnotes.length);
      }
    };
    getFootnotesNumber();
    setTimeout(() => {
      setShowProgress(false);
    }, 1000);
  }, []);

  const changeCurrentFootnote = (footnote) => {
    setCurrentFootnote(footnote);
  };

  const {
    sourcesLength,
    footnotesLength,
    updateSourcesNumber,
    updateFootnotesNumber,
  } = useLengthUpdater();

  const handleReload = (location, history) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tab", columnName);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    window.location.reload();
  };

  // currentFootnote use effect
  useEffect(() => {
    const footnoteMarks = getMarks(editor, "customFootnote");
    if (currentFootnote && currentFootnote.stop) {
      return;
    }

    let lastOrder = 0;
    if (footnoteMarks) {
      footnoteMarks.forEach((footnote, footnoteIndexIndex) => {
        if (addingNewFootnote) {
          lastOrder++;

          if (footnote && !footnote["data-index"]) {
            changeCurrentFootnote({
              stop: true,
              addingNewFootnote: true,
              text: footnote.text,
              order: lastOrder,
            });
          }
          return;
        }
        if (
          currentFootnote &&
          footnoteIndexIndex === currentFootnote.order - 1 &&
          !currentFootnote.stop &&
          !addingNewFootnote
        ) {
          let newCurrentFootnote = {
            ...currentFootnote,
          };

          newCurrentFootnote.stop = true;
          setCurrentFootnote(newCurrentFootnote);
        }

        if (currentFootnote) {
          const footnoteToEdit = ctxEntry.entries.footnotes.filter(
            (footnote) =>
              footnote.order === currentFootnote["data-index"] &&
              footnote.lang === lang &&
              (!section || footnote.section === section)
          );

          const currentFootnoteEdit = {
            ...currentFootnote,
            ...footnoteToEdit[0],
            stop: true,
          };
          setCurrentFootnote(currentFootnoteEdit);
        }
      });
    }
  }, [currentFootnote]);

  return (
    <div className="TipTapSourcePanel">
      {showProgress && (
        <div className="DrawerCover">
          <CircularProgress color="inherit" />
        </div>
      )}

      {error && (
        <ErrorSnack error={{ msg: error }} clearError={() => setError("")} />
      )}

      <div className="TipTapSourcePanelMenu">
        {!addingNewFootnote && (
          <Tooltip title="Sources Tab">
            <span>
              <IconButton
                size="small"
                aria-label="sources"
                color="primary"
                onClick={() => setActiveInDrawer("source")}
                className={
                  activeInDrawer === "source" ? "is-active editor" : "editor"
                }
              >
                <SourceIcon />
                Sources (<b>{sourcesLength}</b>)
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!addingNewSource && (
          <Tooltip title="Footnotes Tab">
            <span>
              <IconButton
                size="small"
                aria-label="footnotes"
                color="primary"
                onClick={() => setActiveInDrawer("footnote")}
                className={
                  activeInDrawer === "footnote" ? "is-active editor" : "editor"
                }
              >
                <OListIcon /> Footnotes (<b>{footnotesLength}</b>)
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>

      {activeInDrawer === "source" && (
        <div
          className={`TipTapSources__list ${
            addingNewSource ? "addingNewSource" : ""
          }`}
        >
          <SourceMarksRenderer
            editor={editor}
            lang={lang}
            getMarks={getTheMarks}
            columnName={columnName}
            currentSource={currentSource}
            updateSourcesNumber={updateSourcesNumber}
            newSourceToAdd={newSourceToAdd}
            setAddingNewSource={changeAddingState}
            setCurrentSource={changeNewSource}
            addingNewSource={addingNewSource}
            setNewSourceToAdd={changeNewSourceToAdd}
            section={section}
            isInvalid={isInvalid}
          />

          {(currentSource !== null ||
            addingNewSource ||
            (error && lang === "es")) && (
            <>
              <SetSourceForm
                currentSource={currentSource}
                currentFileOr={currentFileOr}
                currentFileTr={currentFileTr}
                setCurrentSource={setCurrentSource}
                editor={editor}
                hasSources={hasSources}
                setValidation={setValidation}
                lang={lang}
                columnName={columnName}
                handleReload={handleReload}
                setError={setError}
                setCurrentFileOr={setCurrentFileOr}
                setCurrentFileTr={setCurrentFileTr}
                setCurrentFileOrOff={setCurrentFileOrOff}
                newLanguageOrOff2={newLanguageOrOff2}
                newLanguageOrOff={newLanguageOrOff}
                newLanguageOr={newLanguageOr}
                newLanguageOr2={newLanguageOr2}
                setNewLanguageOrOff={setNewLanguageOrOff}
                setNewLanguageOrOff2={setNewLanguageOrOff2}
                currentFileOrOff={currentFileOrOff}
                currentFileOrOffRef={currentFileOrOffRef}
                currentFileOrRef={currentFileOrRef}
                currentFileTrRef={currentFileTrRef}
                addingNewSource={addingNewSource}
                newSourceToAdd={newSourceToAdd}
                setNewLanguageOr={setNewLanguageOr}
                setNewLanguageOr2={setNewLanguageOr2}
                extractFormData={extractFormData}
                saveEsSource={saveEsSource}
                year={year}
                setYearValue={setYearValue}
                radio={radio}
                setRadioValue={setRadioValue}
                handleYearChange={handleYearChange}
                section={section}
              />
            </>
          )}
        </div>
      )}

      {activeInDrawer === "footnote" && (
        <div>
          {currentFootnote || addingNewFootnote ? (
            <FootnoteTab
              changeCurrentFootnote={changeCurrentFootnote}
              footnote={currentFootnote}
              lang={lang}
              editor={editor}
              columnName={columnName}
              setError={setError}
              addingNewFootnote={addingNewFootnote}
              handleReload={handleReload}
              section={section}
            />
          ) : (
            <FootnoteMarksRenderer
              editor={editor}
              getMarks={getMarks}
              columnName={columnName}
              updateFootnotesNumber={updateFootnotesNumber}
              setAddingNewFootnote={setAddingNewFootnote}
              changeCurrentFootnote={changeCurrentFootnote}
              footnote={currentFootnote}
              lang={lang}
              section={section}
            />
          )}
        </div>
      )}
    </div>
  );
}
