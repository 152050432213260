import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import TipTapProvider from "./shared/providers/TipTapProvider";
// Icons
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 10000,
    // Use color provided by Material-UI's palette for consistency or a custom color
    backgroundColor: "#a6a6a6 !important", // Replace with your color
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "fixed",
  },
  drawerPaper: {
    width: drawerWidth,
    // Use color provided by Material-UI's palette for consistency or a custom color
    backgroundColor: "#ffffff", // Replace with your color
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "white !important",
  },
}));

function AppBranch({ routes, token, props }) {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  return (
    <>
      <TipTapProvider>
        {token ? (
          <>
            {!["db-dashboard", "db-revisions", "db-my-revisions", "db-revisions-rejected", "db-revisions-admin-rejected", "db-revisions-approved", "db-revisions-to-approve", "db-entry", "db-drafts", "db-drafts-all"].includes(
              location.pathname.split("/")[1]
            ) ? (
              <>
                <MainNavigation logged_user={props.logged_user || {}} />
                <main>{routes}</main>
              </>
            ) : (
              <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <img
                      src="/img/dblogo.png"
                      style={{ paddingBottom: "0px", cursor: "pointer" }}
                      onClick={() => {
                        history.push(`/db-dashboard`);
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        paddingLeft: "10px",
                        borderLeft: "1px solid #fff",
                        color: "#fff",
                        width: "100%",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(`/db-dashboard`);
                      }}
                    >
                      Intake Wizard
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: "bold",
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push(`/`);
                        }}
                      >
                        Go back to ASPU
                      </span>
                    </div>
                  </Toolbar>
                </AppBar>

                <main className={classes.content}>{routes}</main>
              </div>
            )}
          </>
        ) : (
          <>
            <main>{routes}</main>
          </>
        )}
      </TipTapProvider>
    </>
  );
}

export default AppBranch;
