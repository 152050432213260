// ActionTriggerContext.js
import React, { createContext, useContext, useState, useRef } from 'react';

const ActionTriggerContext = createContext();

export const ActionTriggerProvider = ({ children }) => {
  const [triggerCounter, setTriggerCounter] = useState(0);
  const [acknowledgedBy, setAcknowledgedBy] = useState([]);
  const callbacksRef = useRef([]);

  const registerCallback = (callback) => {
    callbacksRef.current.push(callback);
  };

  const triggerAction = () => {
    setTriggerCounter(prevCounter => prevCounter + 1);
    setAcknowledgedBy([]); // Reset acknowledgments for the new trigger
  };

  const acknowledgeAction = (id) => {
    setAcknowledgedBy(prev => [...prev, id]);
  };

  const clearCallbacks = () => {
    callbacksRef.current = [];
  };

  return (
    <ActionTriggerContext.Provider value={{ triggerCounter, triggerAction, acknowledgeAction, acknowledgedBy, registerCallback, clearCallbacks }}>
      {children}
    </ActionTriggerContext.Provider>
  );
};

export const useActionTrigger = () => useContext(ActionTriggerContext);
