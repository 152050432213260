import { useState } from 'react';

const useLengthUpdater = () => {
  const [sourcesLength, setSourcesLength] = useState(0);
  const [footnotesLength, setFootnotesLength] = useState(0);

  const updateSourcesNumber = (number) => {
    setSourcesLength(number);
  };

  const updateFootnotesNumber = (number) => {
    setFootnotesLength(number);
  };

  return { sourcesLength, footnotesLength, updateSourcesNumber, updateFootnotesNumber };
}

export default useLengthUpdater;