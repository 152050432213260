import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import { setOpenArticle } from "../actions/articles";

const PREFIX = "AddArticleBtn";

const classes = {
  fab: `${PREFIX}-fab`,
  extendedIcon: `${PREFIX}-extendedIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.fab}`]: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },

  [`& .${classes.extendedIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

const AddArticleBtn = ({ dispatch }) => {
  return (
    <Root className="fixed-action-btn">
      <Fab
        onClick={() => dispatch(setOpenArticle(true))}
        color="primary"
        aria-label="Add"
        className={classes.fab}
      >
        <Icon>add</Icon>
      </Fab>
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    articles: state.articles.articles,
    current: state.articles.current,
  };
};

export default connect(mapStateToProps)(AddArticleBtn);
