import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
} from "@mui/material";
import { utc } from "moment-timezone";

import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

import {
  approveArticle,
  convertToMother,
  deleteArticle,
  removeFromMother,
} from "../actions/articles";

const PREFIX = "InboxCard";

const classes = {
  root: `${PREFIX}-root`,
  rootR: `${PREFIX}-rootR`,
  rootM: `${PREFIX}-rootM`,
  content: `${PREFIX}-content`,
  valignCenter: `${PREFIX}-valignCenter`,
  actions: `${PREFIX}-actions`,
  expand: `${PREFIX}-expand`,
  header: `${PREFIX}-header`,
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  buttonAddJur: `${PREFIX}-buttonAddJur`,
  buttonRemoveJur: `${PREFIX}-buttonRemoveJur`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    marginBottom: "20px",
    paddingBottom: "0px",
    borderRadius: "5px",
  },

  [`& .${classes.rootR}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#b7cfff",
  },

  [`& .${classes.rootM}`]: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "rgb(255, 252, 148)",
  },

  [`& .${classes.content}`]: {
    paddingTop: "10px",
    "&:last-child": {
      paddingBottom: "10px",
    },
  },

  [`& .${classes.valignCenter}`]: {
    display: "inline-flex",
    verticalAlign: "middle",
    alignItems: "center",
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    backgroundColor: "#fafafa",
  },

  [`& .${classes.expand}`]: {
    marginLeft: "auto",
    fontSize: "1.5rem",
    minWidth: "50px",
  },

  [`& .${classes.header}`]: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "navy",
    fontWeight: "bold",
  },

  [`& .${classes.modal}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.dialogTitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.buttonAddJur}`]: {
    backgroundColor: "rgba(0,0,256,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,0,256,0.8)",
    },
  },

  [`& .${classes.buttonRemoveJur}`]: {
    backgroundColor: "rgba(256,0,0,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(256,0,0,0.8)",
    },
    marginTop: "-10px",
  },
}));

function InboxCard({
  article,
  sources,
  source_types,
  jurisdictions,
  languages,
  inboxPosition,
  loading,
  reassign,
  setReassign,
  addReassignChildren,
  toReassign,
  users,
  reassignChildren,
  removeReassignChildren,
  deleteArticle,
  setUndoDeleteArticle,
  approveArticle,
  setUndoApproveArticle,
  setBackToInbox,
  removeFromMother,
  convertToMother,
  setSuccess,
  motherPosition,
}) {
  const [expanded, setExpanded] = useState(false);
  const [webview, setWebview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteArticleJur, setDeleteArticleJur] = React.useState(null);
  const [addArticleJur, setAddArticleJur] = React.useState(null);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  }

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher",
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header",
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header",
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      },
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      },
    },
  ];
  let st;
  let jur;
  if (article.source) {
    st = source_types.find(
      (st) => st.id === article.source.source_type_id
    ).code;

    jur = jurisdictions.find(
      (jur) => jur.id === article.source.jurisdiction_id
    );
  }

  let articleJurisdictions;
  let global_jur = null;
  let con_jur = null;
  if (article.jurisdictions && article.jurisdictions.length) {
    if (article.jurisdictions.length > 3)
      global_jur = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur = jur;
  }

  let lang = article.g_detected_language
    ? article.g_detected_language
    : article.detected_language;

  if (lang) {
    let langTemp = languages.find((l) => l.id === lang);
    if (langTemp) lang = langTemp.name;
  }

  const isChosenReassign = reassign && reassign.id === article.id;

  return (
    <Root>
      {article.article_content && article.article_content.content && (
        <Dialog
          contentStyle={{
            width: "80%",
            maxWidth: "none",
          }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open || false}
          onClose={handleClose}
          closeAfterTransition
          maxWidth="lg"
          title={
            <div>
              <img
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "5px",
                  width: "20px",
                }}
              />
            </div>
          }
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <h2>{article.article_content.title}</h2>
            <IconButton onClick={handleClose} size="large">
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Fade in={open}>
              <div className={classes.paper}>
                <p
                  id="transition-modal-description"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {article.article_content.content.replace(/<br \/>/g, "\n")}
                </p>
              </div>
            </Fade>
          </DialogContent>
        </Dialog>
      )}

      <Card
        className={
          !toReassign
            ? isChosenReassign
              ? classes.rootM
              : classes.root
            : classes.rootR
        }
      >
        <CardContent className={classes.content}>
          <Grid container spacing={3} className={classes.valignCenter}>
            <Grid item md={1}>
              <Typography
                gutterBottom
                variant="body2"
                component="h6"
                style={{ textAlign: "center" }}
              >
                {utc(article.digest_date).format("DD MMM")}
              </Typography>
            </Grid>

            <Grid item md={1}>
              <Grid
                container
                spacing={3}
                className={classes.valignCenter}
                onMouseOver={(e) => setAddArticleJur(article)}
                onMouseOut={(e) => setAddArticleJur(null)}
              >
                <Grid item md={3} style={{ textAlign: "center" }}>
                  {global_jur && (
                    <Fragment>
                      <img
                        width="32px"
                        src={
                          "/img/country_flags/" +
                          global_jur.country_code +
                          "@3x.png"
                        }
                      />

                      <br />
                      {global_jur.country_code}
                    </Fragment>
                  )}
                  {con_jur && (
                    <Fragment>
                      <img
                        width="32px"
                        src={
                          "/img/country_flags/" +
                          con_jur.country_code +
                          "@3x.png"
                        }
                      />
                      <br />
                      {con_jur.country_code} (C)
                    </Fragment>
                  )}
                  {!global_jur && !con_jur && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) =>
                          setDeleteArticleJur(article.jurisdictions[0].id)
                        }
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            article.jurisdictions[0].jurisdiction.country_code +
                            "@3x.png"
                          }
                        />
                      </div>
                      {article.jurisdictions[0].jurisdiction.country_code}
                    </Fragment>
                  )}
                </Grid>
                <Grid item md={3} style={{ textAlign: "center" }}>
                  {!global_jur && !con_jur && article.jurisdictions[1] && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) =>
                          setDeleteArticleJur(article.jurisdictions[1].id)
                        }
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            article.jurisdictions[1].jurisdiction.country_code +
                            "@3x.png"
                          }
                        />
                      </div>
                      {article.jurisdictions[1].jurisdiction.country_code}
                    </Fragment>
                  )}
                </Grid>
                <Grid item md={3} style={{ textAlign: "center" }}>
                  {" "}
                  {!global_jur && !con_jur && article.jurisdictions[2] && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) =>
                          setDeleteArticleJur(article.jurisdictions[2].id)
                        }
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            article.jurisdictions[2].jurisdiction.country_code +
                            "@3x.png"
                          }
                        />
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: "0px",
                            backgroundColor: "rgba(256,256,256,0.8)",
                            color: "black",
                            display:
                              article.jurisdictions[2].id === deleteArticleJur
                                ? "block"
                                : "none",
                          }}
                        >
                          <Icon style={{ paddingTop: "1px" }} fontSize="small">
                            highlight_off
                          </Icon>
                        </div>
                      </div>
                      {article.jurisdictions[2].jurisdiction.country_code}
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container spacing={3} className={classes.valignCenter}>
                <Grid item md={12}>
                  <a
                    className={classes.header}
                    onClick={(e) => {
                      if (article.detected_language !== "en")
                        window.open(
                          "https://translate.google.com/translate?js=n&sl=auto&tl=en&u=" +
                            article.original_url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      else {
                        window.open(
                          article.original_url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }
                    }}
                  >
                    <Typography gutterBottom variant="body2" component="span">
                      {article.translated_header || article.original_header}{" "}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
              <Typography
                gutterBottom
                variant="body3"
                style={{ fontStyle: "italic" }}
                component="h6"
              >
                {article.translated_header && (
                  <a
                    className={classes.header}
                    onClick={(e) => {
                      window.open(
                        article.original_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    {article.original_header}
                  </a>
                )}
              </Typography>
              <Typography
                gutterBottom
                style={{ fontSize: "0.6rem" }}
                component="h6"
              >
                {article.original_description}
              </Typography>
            </Grid>
            <Grid item md={2}>
              {article.source ? (
                <Fragment>
                  <Typography
                    gutterBottom
                    variant="body2"
                    style={{ fontStyle: "italic" }}
                    component="span"
                  >
                    {article.source.name}
                  </Typography>
                  <Typography gutterBottom variant="body3" component="h6">
                    {st} -{" "}
                    <img
                      width="14px"
                      style={{ verticalAlign: "middle" }}
                      src={"/img/country_flags/" + jur.country_code + ".png"}
                    />{" "}
                    - {jur.name}
                  </Typography>
                </Fragment>
              ) : (
                ""
              )}
            </Grid>

            <Grid item md={1}>
              <Typography gutterBottom variant="body2" component="h6">
                {lang}
              </Typography>
            </Grid>
            <Grid item md={1}>
              {article.articles && (
                <Typography gutterBottom variant="body2" component="h6">
                  <div className={classes.valignCenter}>
                    <Button
                      style={{ color: "gray" }}
                      onClick={(e) => setExpanded(!expanded)}
                    >
                      <Icon>list_alt</Icon> {article.articles.length}
                    </Button>
                  </div>
                </Typography>
              )}
            </Grid>
            <Grid item md={1}>
              {article.article_status_id === 2
                ? "Excluded"
                : "Deleted by " +
                  users
                    .find((u) => u.id === article.user_id)
                    ?.name.split(" ")[0]}
              <br />
              On: {utc(article.updated_at).format("DD MMM YYYY")}
            </Grid>
            <Grid item md={1}>
              <Button
                onClick={(e) =>
                  setBackToInbox(article).then(() => {
                    setSuccess(
                      article.source.name + "'s article restored successfully"
                    );
                  })
                }
                variant="contained"
                color="primary"
              >
                Restore
              </Button>
            </Grid>
            {article.articles && article.articles.length !== 0 && (
              <Collapse
                in={expanded}
                mountOnEnter
                style={{ width: "100%" }}
                unmountOnExit
              >
                <Grid item md={12}>
                  <Fragment>
                    <Paper
                      style={{
                        backgroundColor: "rgb(189, 189, 189)",
                        padding: "10px",
                        maxHeight: "600px",
                        overflow: "auto",
                      }}
                    >
                      {article.articles.map((art, index) => (
                        <InboxCard
                          key={index}
                          article={art}
                          sources={sources}
                          source_types={source_types}
                          jurisdictions={jurisdictions}
                          languages={languages}
                          reassign={reassign}
                          setReassign={setReassign}
                          addReassignChildren={addReassignChildren}
                          reassignChildren={reassignChildren}
                          toReassign={
                            reassignChildren
                              .map((c) => c.id)
                              .indexOf(art.id) !== -1
                          }
                          removeReassignChildren={removeReassignChildren}
                          setUndoDeleteArticle={setUndoDeleteArticle}
                          setBackToInbox={setBackToInbox}
                          deleteArticle={deleteArticle}
                          setUndoApproveArticle={setUndoApproveArticle}
                          approveArticle={approveArticle}
                          removeFromMother={removeFromMother}
                          inboxPosition={inboxPosition}
                          motherPosition={article.position}
                          convertToMother={convertToMother}
                          setSuccess={setSuccess}
                        />
                      ))}
                    </Paper>
                  </Fragment>
                </Grid>
              </Collapse>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteArticle: (art) => dispatch(deleteArticle(art)),
  approveArticle: (art) => dispatch(approveArticle(art)),
  removeFromMother: (art) => dispatch(removeFromMother(art)),
  convertToMother: (art) => dispatch(convertToMother(art)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxCard);
