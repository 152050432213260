import {createContext} from 'react'

const TipTapContext = createContext({
  ctxData: null,
  changeData: () => {},
  ctxEntry: null,
  changeEntry: () => {},
  ctxLang: null,
  changeLang: () => {},
  ctxCountryCode: null,
  ctxSource: null,
  ctxColumn: null,
  changeColumn: () => {},
  openSourceReferenceDialog: null,
  pendingTransactionData: null,
  setPendingTransactionData: () => {},
  setOpenSourceReferenceDialog: () => {},
  changeCtxSource: () => {},
  deleteFootnoteAction: () => {},
  deleteSourceAction: () => {},
  changeCtxJurisdictions: () => {},
  clearCtxEntryLanguage2: () => {},
  prepContextState: () => {},
  setSaveChanges: () => {},
  saveChanges: {},
})

export default TipTapContext
