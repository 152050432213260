import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { TextField } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { setCurrentAreaJur } from "../../actions/database";
import { areas, fetcher } from "../../utils/functions";

function TreeMenu({ currentAreaJur, setCurrentAreaJur }) {
  const {
    data: dataJurs,
    error: errorJurs,
    isLoading: isLoadingJurs,
  } = useSWR(
    process.env.REACT_APP_BACKEND_URL + "/database/jurisdictions",
    fetcher
  );
  const [search, setSearch] = React.useState("");
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const history = useHistory();
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => {
      let a = areas.map((area, index) => index);
      areas.forEach((area, index) => {
        if (area.subareas)
          a = a.concat(
            area.subareas.map((subarea, index2) => index + "-" + index2)
          );
      });
      console.log(a);
      return a;
    });
  };

  function sumRevisions(obj) {
    let sum = 0;

    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (Array.isArray(obj[key]) && key === "revisions") {
          sum += obj[key].length;
        } else {
          sum += sumRevisions(obj[key]);
        }
      }
    }

    return sum;
  }
  const { jurisdictionId, entryTypeId } = useParams();

  useEffect(() => {
    console.log("jurisdictionId: ", jurisdictionId);
    console.log("entryTypeId: ", entryTypeId);
  }, [jurisdictionId]);

  useEffect(() => {
    console.log("expanded: ", expanded);
  }, [expanded]);

  if (errorJurs) return <div>failed to load</div>;
  if (isLoadingJurs) return <div>loading...</div>;

  return (
    <>
      <TextField
        value={search}
        variant="outlined"
        placeholder="Search Jur..."
        InputProps={{
          style: { height: "32px", backgroundColor: "white" },
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <TreeView
        aria-label="file system navigator"
        className="treeMenu"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        sx={{ flexGrow: 1, overflowY: "auto" }}
      >
        {areas[0].subareas &&
          areas[0].subareas.map((subarea, index2) => (
            <TreeItem
              className="treeItem"
              nodeId={0 + "-" + index2}
              key={0 + "-" + index2}
              label={subarea.name}
              style={{ marginLeft: "0px" }}
            >
              {dataJurs &&
                dataJurs.jurisdictions &&
                dataJurs.revisions &&
                dataJurs.jurisdictions.length > 0 &&
                dataJurs.jurisdictions
                  .filter(
                    (j) =>
                      j.name_en !== "Global" &&
                      (!search ||
                        j.name_en
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1)
                  )
                  .map((jurisdiction, index3) => (
                    <TreeItem
                      className="treeItem"
                      nodeId={0 + "-" + index2 + "-" + index3}
                      key={0 + "-" + index2 + "-" + index3}
                      style={{ marginLeft: "-10px" }}
                      label={
                        <>
                          <img
                            width="16px"
                            src={
                              "/img/country_flags/" +
                              jurisdiction.a2_code +
                              "@3x.png"
                            }
                            style={{ marginRight: "5px" }}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {jurisdiction.name_en +
                              (dataJurs.revisions &&
                              dataJurs.revisions[subarea.id] &&
                              dataJurs.revisions[subarea.id][jurisdiction.id] &&
                              dataJurs.revisions[subarea.id][jurisdiction.id]
                                .draft
                                ? "*"
                                : "")}
                          </span>
                        </>
                      }
                      onClick={() => {
                        history.push(
                          `/db-entry/${jurisdiction.id}/${subarea.id}/0`
                        );
                        setCurrentAreaJur(subarea.id, jurisdiction.id);
                      }}
                      sx={{
                        height: 20,
                        flexGrow: 1,
                        overflowX: "true",
                      }}
                    />
                  ))}
            </TreeItem>
          ))}

        <hr />
        <TreeItem
          onClick={() => {
            history.push(`/db-revisions`);
          }}
          className={`treeItemOne`}
          label={
            "Pending Review (" +
            (dataJurs.revisions ? sumRevisions(dataJurs.revisions) : 0) +
            ")"
          }
        />
      </TreeView>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentAreaJur: state.database,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentAreaJur: (areaId, jurId) =>
    dispatch(setCurrentAreaJur(areaId, jurId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TreeMenu);
