import axios from "axios";

export const GET_ALL_JURISDICTIONS = 'GET_ALL_JURISDICTIONS'
export const SET_ALL_JURISDICTIONS = 'SET_ALL_JURISDICTIONS'

export const GET_ALL_SUB_JURISDICTIONS = 'GET_ALL_SUB_JURISDICTIONS'
export const SET_ALL_SUB_JURISDICTIONS = 'SET_ALL_SUB_JURISDICTIONS'

export const GET_ALL_DECRIM_MECHANISMS = 'GET_ALL_DECRIM_MECHANISMS'
export const SET_ALL_DECRIM_MECHANISMS = 'SET_ALL_DECRIM_MECHANISMS'

export const GET_PENALTIES = 'GET_PENALTIES'
export const SET_PENALTIES = 'SET_PENALTIES'

export const GET_OTHER_PENALTIES = 'GET_OTHER_PENALTIES'
export const SET_OTHER_PENALTIES = 'SET_OTHER_PENALTIES'

export const GET_MAX_PRISON_VALUES = 'GET_MAX_PRISON_VALUES'
export const SET_MAX_PRISON_VALUES = 'SET_MAX_PRISON_VALUES'

export const GET_DEATH_PENALTY_VALUES = 'GET_DEATH_PENALTY_VALUES'
export const SET_DEATH_PENALTY_VALUES = 'SET_DEATH_PENALTY_VALUES'

export const getDeathPenaltyValues = () => {
  return async (dispatch) => {
    dispatch({ type: GET_DEATH_PENALTY_VALUES });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/database/death-penalty-values");
      const deathPenValues = response.data

      dispatch(setDeathPenaltyValues(deathPenValues));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getMaxPrisonValues = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MAX_PRISON_VALUES });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/database/max-prison-values");
      const allMaxPrisonData = response.data

      dispatch(setMaxPrisonValues(allMaxPrisonData));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getOtherPenalties = () => {
  return async (dispatch) => {
    dispatch({ type: GET_OTHER_PENALTIES });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/database/other-penalties");
      const allOtherPenaltiesData = response.data

      dispatch(setOtherPenalties(allOtherPenaltiesData));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getPenalties = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PENALTIES });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/database/penalties");
      const allPenaltiesData = response.data

      dispatch(setPenalties(allPenaltiesData));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getAllJurisdictions = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_JURISDICTIONS });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/database/jurisdictions");
      const allJurisdictionsData = response.data
      dispatch(setAllJurisdictions(allJurisdictionsData));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getAllSubJurisdictions = (jurisdictionId) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_SUB_JURISDICTIONS }); // CREATE TYPE

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/database/subjurisdictions/${jurisdictionId}`);
      const allSubjurisdictionsData = response.data

      dispatch(setAllSubJurisdictions(allSubjurisdictionsData));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const getAllDecrimMechanisms = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_DECRIM_MECHANISMS });

    // do async fetch here
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/database/decrim-mechanisms`);
      const allDecrimMechanisms = response.data

      dispatch(setAllDecrimMechanisms(allDecrimMechanisms));
    } catch (error) {
      console.error("Action error: ", error)
    }
  }
}

export const setDeathPenaltyValues = (values) => {
  return {
    type: SET_DEATH_PENALTY_VALUES,
    payload: values
  }
}

export const setMaxPrisonValues = (values) => {
  return {
    type: SET_MAX_PRISON_VALUES,
    payload: values
  }
}
export const setPenalties = (allPenalties) => {
  return {
    type: SET_PENALTIES,
    payload: allPenalties
  }
}

export const setOtherPenalties = (allOtherPunishments) => {
  return {
    type: SET_OTHER_PENALTIES,
    payload: allOtherPunishments
  }
}

export const setAllJurisdictions = (allJurisdictions) => {
  return {
    type: SET_ALL_JURISDICTIONS,
    payload: allJurisdictions
  }
}

export const setAllSubJurisdictions = (allSubJurisdictions) => {
  return {
    type: SET_ALL_SUB_JURISDICTIONS,
    payload: allSubJurisdictions
  }
}

export const setAllDecrimMechanisms = (allDecrimMechanisms) => {
  return {
    type: SET_ALL_DECRIM_MECHANISMS,
    payload: allDecrimMechanisms
  }
}
