import { Grid, Icon, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export default function DashParentConfirm({
  open,
  setOpen,
  title,
  article,
  assignToMother,
  jurisdictions,
  removeDashMother,
  child
}) {
  const [sure, setSure] = React.useState(false);

  let jur_child;
  if (child.source) {
    jur_child = jurisdictions.find((jur) => jur.id === child.source.jurisdiction_id);
  }
  let global_jur_child = null;
  let con_jur_child = null;
  if (child.jurisdictions && child.jurisdictions.length) {
    if (child.jurisdictions.length > 3) global_jur_child = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur_child = jur_child;
  }

  let jur = {};
  if (article.source) {
    jur = jurisdictions.find((jur) => jur.id === article.source.jurisdiction_id);
  }
  let global_jur = null;
  let con_jur = null;
  if (article.jurisdictions && article.jurisdictions.length) {
    if (article.jurisdictions.length > 3) global_jur = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur = jur;
  }

  return (
    <Dialog open={open} maxWidth={sure ? "sm" : "md"} fullWidth aria-labelledby="draggable-dialog-title">
      {!sure ? (
        <>
          <DialogTitle id="draggable-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ marginTop: "5px", marginBottom: "10px" }}>This is the entry you are about to process</div>
              <Grid container>
                <Grid md={1} style={{ textAlign: "center" }}>
                  {global_jur_child && (
                    <>
                      <img width="32px" src={"/img/country_flags/" + global_jur_child.country_code + "@3x.png"} />

                      <br />
                      <span style={{ fontWeight: "bold" }}>{global_jur_child.country_code}</span>
                    </>
                  )}
                  {!global_jur_child &&
                    !con_jur_child &&
                    child.jurisdictions &&
                    child.jurisdictions.length > 0 &&
                    child.jurisdictions.map((aj) => (
                      <>
                        <div style={{ position: "relative" }}>
                          <img width="32px" src={"/img/country_flags/" + aj.jurisdiction.country_code + "@3x.png"} />
                        </div>
                        <span style={{ fontWeight: "bold" }}>{aj.jurisdiction.country_code}</span>
                      </>
                    ))}
                  {con_jur_child && (
                    <>
                      <div style={{ position: "relative" }}>
                        <img width="32px" src={"/img/country_flags/" + con_jur_child.country_code + "@3x.png"} />

                        <span style={{ color: "darkred", fontWeight: "bold" }}>{con_jur_child.country_code}</span>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid md={8}>
                  <a
                    style={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline"
                      },
                      color: "navy"
                    }}
                    onClick={(e) => {
                      if (child.detected_language !== "en")
                        window.open(
                          "https://translate.google.com/translate?js=n&sl=auto&tl=en&u=" + child.original_url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      else {
                        window.open(child.original_url, "_blank", "noopener,noreferrer");
                      }
                    }}
                  >
                    <Typography gutterBottom variant="body2" component="span">
                      {child.translated_header || child.original_header}{" "}
                    </Typography>
                  </a>
                  <Typography gutterBottom variant="body3" style={{ fontStyle: "italic" }} component="h6">
                    {child.translated_header && (
                      <a
                        style={{
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline"
                          },
                          color: "navy"
                        }}
                        onClick={(e) => {
                          window.open(child.original_url, "_blank", "noopener,noreferrer");
                        }}
                      >
                        {child.original_header}
                      </a>
                    )}
                  </Typography>
                  <Typography gutterBottom style={{ fontSize: "0.6rem" }} component="h6">
                    {child.original_description}
                  </Typography>
                </Grid>
                <Grid item md={2} style={{ textAlign: "center" }}>
                  {child.source ? (
                    <>
                      <Typography gutterBottom variant="body2" style={{ fontStyle: "italic" }} component="span">
                        {child.source.name}
                      </Typography>
                      <Typography gutterBottom variant="body3" component="h6">
                        <img
                          width="14px"
                          style={{ verticalAlign: "middle" }}
                          src={"/img/country_flags/" + jur.country_code + ".png"}
                        />{" "}
                        - {jur.name}
                      </Typography>
                      {child.source.media_types && child.source.media_types.length > 0 && (
                        <Typography gutterBottom variant="body3" component="h6">
                          {child.source.media_types.map((mt) => mt.name).join(" - ")}
                        </Typography>
                      )}
                      {child.source.ilga_m > 0 && (
                        <Typography gutterBottom variant="body3" component="h6">
                          {child.source.ilga_m === 1 ? "ILGA Member" : "NON-ILGA Member"}
                        </Typography>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={1} style={{ textAlign: "center" }}></Grid>
              </Grid>
              <div style={{ marginTop: "25px", marginBottom: "10px" }}>
                This is the entry pontentially related to your entry
              </div>
              <Grid container>
                <Grid md={1} style={{ textAlign: "center" }}>
                  {global_jur && (
                    <>
                      <img width="32px" src={"/img/country_flags/" + global_jur.country_code + "@3x.png"} />

                      <br />
                      <span style={{ fontWeight: "bold" }}>{global_jur.country_code}</span>
                    </>
                  )}
                  {!global_jur &&
                    !con_jur &&
                    article.jurisdictions &&
                    article.jurisdictions.length > 0 &&
                    article.jurisdictions.map((aj) => (
                      <>
                        <div style={{ position: "relative" }}>
                          <img width="32px" src={"/img/country_flags/" + aj.jurisdiction.country_code + "@3x.png"} />
                        </div>
                        <span style={{ fontWeight: "bold" }}>{aj.jurisdiction.country_code}</span>
                      </>
                    ))}
                  {con_jur && (
                    <>
                      <div style={{ position: "relative" }}>
                        <img width="32px" src={"/img/country_flags/" + con_jur.country_code + "@3x.png"} />

                        <span style={{ color: "darkred", fontWeight: "bold" }}>{con_jur.country_code}</span>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid md={8}>
                  <a
                    style={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline"
                      },
                      color: "navy"
                    }}
                    onClick={(e) => {
                      if (article.detected_language !== "en")
                        window.open(
                          "https://translate.google.com/translate?js=n&sl=auto&tl=en&u=" + article.original_url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      else {
                        window.open(article.original_url, "_blank", "noopener,noreferrer");
                      }
                    }}
                  >
                    <Typography gutterBottom variant="body2" component="span">
                      {article.translated_header || article.original_header}{" "}
                    </Typography>
                  </a>
                  <Typography gutterBottom variant="body3" style={{ fontStyle: "italic" }} component="h6">
                    {article.translated_header && (
                      <a
                        style={{
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline"
                          },
                          color: "navy"
                        }}
                        onClick={(e) => {
                          window.open(article.original_url, "_blank", "noopener,noreferrer");
                        }}
                      >
                        {article.original_header}
                      </a>
                    )}
                  </Typography>
                  <Typography gutterBottom style={{ fontSize: "0.6rem" }} component="h6">
                    {article.original_description}
                  </Typography>
                </Grid>
                <Grid item md={2} style={{ textAlign: "center" }}>
                  {article.source ? (
                    <>
                      <Typography gutterBottom variant="body2" style={{ fontStyle: "italic" }} component="span">
                        {article.source.name}
                      </Typography>
                      <Typography gutterBottom variant="body3" component="h6">
                        <img
                          width="14px"
                          style={{ verticalAlign: "middle" }}
                          src={"/img/country_flags/" + jur.country_code + ".png"}
                        />{" "}
                        - {jur.name}
                      </Typography>
                      {article.source.media_types && article.source.media_types.length > 0 && (
                        <Typography gutterBottom variant="body3" component="h6">
                          {article.source.media_types.map((mt) => mt.name).join(" - ")}
                        </Typography>
                      )}
                      {article.source.ilga_m > 0 && (
                        <Typography gutterBottom variant="body3" component="h6">
                          {article.source.ilga_m === 1 ? "ILGA Member" : "NON-ILGA Member"}
                        </Typography>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={1} style={{ textAlign: "center" }}>
                  <a
                    target="_blank"
                    title="Open dashboard entry"
                    href={"/dashboard/" + article.id}
                    style={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline"
                      },
                      color: "navy"
                    }}
                  >
                    <Icon>network_check_rounded</Icon>
                  </a>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">ATTENTION</DialogTitle>
          <DialogContent>
            {sure === 2 ? (
              <DialogContentText id="alert-dialog-description">
                You are about to convert this entry into a mother. Make sure that the entry has the appropiate values
                for JUR and TAGS
              </DialogContentText>
            ) : (
              <DialogContentText id="alert-dialog-description">
                You are about to add this entry to a dashboard entry
              </DialogContentText>
            )}
          </DialogContent>
        </>
      )}
      <DialogActions>
        {!sure ? (
          <>
            <span style={{ textAlign: "right", fontWeight: "bold" }}>
              Is this the mother entry of the one you are trying to approve?
            </span>
            <Button
              variant="contained"
              onClick={(e) => setSure(1)}
              color="primary"
              style={{ backgroundColor: "darkgreen" }}
              autoFocus
            >
              Yes, approve as Child
            </Button>
            <Button variant="contained" onClick={(e) => setSure(2)} color="primary" autoFocus>
              Yes, but make mother
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                removeDashMother(child.id);
                setSure(false);
                setOpen(false);
              }}
              color="secondary"
            >
              No, ignore
            </Button>
            <Button
              onClick={(e) => {
                setSure(false);
                setOpen(false);
              }}
              color="secondary"
            >
              Let me check
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={(e) => {
                setSure(false);
                setOpen(false);
              }}
              color="secondary"
            >
              Edit Entry
            </Button>

            <Button
              onClick={(e) => {
                assignToMother(sure === 2);
                setOpen(false);
                setSure(false);
              }}
              color="primary"
              autoFocus
            >
              CONFIRM?
            </Button>

            <Typography gutterBottom variant="body3" component="h6" style={{ marginLeft: "0px", marginTop: "5px" }}>
              (this action cannot be undone)
            </Typography>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
