import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Paper,
  DialogActions,
  Button,
  IconButton,
  Icon
} from "@mui/material";
import { connect } from "react-redux";

import { removeJur } from "../actions/articles";

const PREFIX = 'JurButtons';

const classes = {
  buttonAddJur: `${PREFIX}-buttonAddJur`,
  buttonRemoveJur: `${PREFIX}-buttonRemoveJur`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.buttonAddJur}`]: {
    backgroundColor: "rgba(0,32,96,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,32,96,0.8)"
    }
  },

  [`& .${classes.buttonRemoveJur}`]: {
    backgroundColor: "rgba(139,0,0,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(139,0,0,0.8)"
    },
    marginTop: "-10px"
  }
}));

function JurButtons(props) {
  const {
    show,
    removeJur,
    article,
    jur,
    setArticleNewJur,
    con,
    posfix
  } = props;



  return (
    <Root
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",

        top: "0px",
        backgroundColor: "rgba(256,256,256,0.8)",
        color: "black",
        display: show ? "block" : "none"
      }}
    >
      <center>
        {!con && (
          <IconButton
            size="small"
            onClick={(e) =>
              removeJur(article, jur, posfix).then(() => {
                if (!article.jurisdictions.length) alert("WARNING!");
                console.log(article);
              })
            }
            variant="outlined"
            className={classes.buttonRemoveJur}
          >
            <Icon style={{ paddingTop: "1px" }} fontSize="small">
              close
            </Icon>
          </IconButton>
        )}
        <IconButton
          size="small"
          onClick={(e) => setArticleNewJur(article)}
          className={classes.buttonAddJur}
        >
          <Icon fontSize="small">add</Icon>
        </IconButton>
      </center>
    </Root>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  removeJur: (article, jur, posfix) => dispatch(removeJur(article, jur, posfix))
});
export default connect(mapStateToProps, mapDispatchToProps)(JurButtons);
