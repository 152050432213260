import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";

import { MuiChipsInput } from "mui-chips-input";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  clearCurrentTier,
  clearErrorTags,
  getTiers,
  setCurrentTier,
  setOpenTier,
  updateTier,
} from "../actions/tags";
import CustomChipInput from "../shared/components/FormElements/CustomChipInput";

const PREFIX = "TierForm";

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const emptyTag = {
  tag: "",
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const TagForm = ({
  current,
  prefilled,
  clearPrefilled,
  open,
  clearCurrentTier,
  updateTier,
  setOpenTier,
  loading,
}) => {
  const [tier, setTier] = useState({});

  const { id, triggers } = tier;

  const handleChange = (chips) => {
    setTier({ ...tier, triggers: chips.join(";") });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (current !== null) {
      setTier({ ...current });
    }
  }, [current, open, prefilled]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenTier(false);
    clearCurrentTier();
    if (clearPrefilled) clearPrefilled();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      updateTier(tier);
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setTier({});
    clearCurrentTier();
  };

  const onChange = (e, newVal = null) => {
    let val = e.target.value;
    setTier({ ...tier, [e.target.name]: val });
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("md"))
            ? { width: "90%", height: "90%", margin: "auto" }
            : {}
        }
        open={
          (current && !(!current.id && current.tag_status_id === 5)) || open
            ? true
            : false
        }
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Tag"
            : current && !open
            ? "Edit Tier " + tier.id + " Triggers"
            : "Add Tag"}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomChipInput
                  label={"Triggers"}
                  defaultValue={
                    tier.triggers
                      ? tier.triggers
                          .split(";")
                          .sort((a, b) => a.localeCompare(b))
                      : []
                  }
                  fullWidth
                  onChange={(chips) => handleChange(chips)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  tiers: state.tags.tiers,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_tier,
  open: state.tags.open_tier,
});

const mapDispatchToProps = (dispatch) => ({
  getTiers: () => dispatch(getTiers()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTier: (tag) => dispatch(setCurrentTier(tag)),
  setOpenTier: (should) => dispatch(setOpenTier(should)),
  clearCurrentTier: () => dispatch(clearCurrentTier()),
  updateTier: (tag) => dispatch(updateTier(tag)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
