import {
  SET_ALL_JURISDICTIONS,
  SET_ALL_SUB_JURISDICTIONS,
  SET_ALL_DECRIM_MECHANISMS,
  SET_PENALTIES,
  SET_OTHER_PENALTIES,
  SET_MAX_PRISON_VALUES,
  SET_DEATH_PENALTY_VALUES,
} from "../actions/selectOptions";

const initialState = {
  allJurisdictions: [],
  allSubJurisdictions: [],
  allDecrimMechanisms: [],
  penalties: [],
  otherPenalties: [],
  maxPrisonValues: [],
  deathPenaltyValues: [],
}

const selectOptions = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_JURISDICTIONS:
      return {
        ...state,
        allJurisdictions: action.payload
      }
    case SET_ALL_SUB_JURISDICTIONS:
      return {
        ...state,
        allSubJurisdictions: action.payload
      }
    case SET_ALL_DECRIM_MECHANISMS:
      return {
        ...state,
        allDecrimMechanisms: action.payload
      }
    case SET_PENALTIES:
      return {
        ...state,
        penalties: action.payload
      }
    case SET_OTHER_PENALTIES:
      return {
        ...state,
        otherPenalties: action.payload
      }
    case SET_MAX_PRISON_VALUES:
      return {
        ...state,
        maxPrisonValues: action.payload
      }
    case SET_DEATH_PENALTY_VALUES:
      return {
        ...state,
        deathPenaltyValues: action.payload
      }

      default:
          return state
  }
}

export default selectOptions
