import React from 'react'
import { Alert, Snackbar } from '@mui/material';

const SuccessSnack = ({ open, handleClose, textContent }) => {
  return <Snackbar open={open} autoHideDuration={3000}
                   onClose={handleClose}
                   anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
    <Alert elevation={6} variant={"filled"}
           severity="success" onClose={handleClose}>
      {textContent}
    </Alert>
  </Snackbar>
}

export default SuccessSnack
