import React, { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CssBaseline from "@mui/material/CssBaseline";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";

import {
  addSource,
  clearCurrentSource,
  clearErrorSources,
  deleteSource,
  getSources,
  setCurrentSource,
  setOpenSource,
  updateSource,
} from "../actions/sources";

import {
  getJurisdictions,
  getLanguages,
  getSourceStatuses,
  getSourceTypes,
} from "../actions/related";

const PREFIX = 'SourceForm';

const classes = {
  table: `${PREFIX}-table`,
  progress: `${PREFIX}-progress`,
  modalPaper: `${PREFIX}-modalPaper`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  formHeader: `${PREFIX}-formHeader`,
  formFooter: `${PREFIX}-formFooter`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    minWidth: 650,
  },

  [`& .${classes.progress}`]: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },

  [`& .${classes.modalPaper}`]: {
    width: "10%",
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.formHeader}`]: {
    backgroundColor: "#002060",
    color: "white",
  },

  [`& .${classes.formFooter}`]: {
    backgroundColor: "gainsboro",
    padding: "10px",
  }
}));

const emptySource = {
  name: "",
  url: "",
  source_type_id: 1,
  source_status_id: 1,
  jurisdiction_id: "UN-185",
  languages: [{ name: "English", id: "en" }],
  is_relevant: 0,
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const SourceForm = ({
  current,
  prefilled,
  clearPrefilled,
  open,
  clearCurrentSource,
  updateSource,
  setOpenSource,
  addSource,
  allLanguages,
  getLanguages,
  source_types,
  getSourceTypes,
  source_statuses,
  getSourceStatuses,
  jurisdictions,
  getJurisdictions,
  loading,
  syntax_created,
}) => {

  const [source, setSource] = useState(emptySource);
  const [statusValue, setStatusValue] = React.useState("Active");
  const [typeValue, setTypeValue] = React.useState("Media Outlet");

  const {
    name,
    url,
    source_type_id,
    languages,
    source_status_id,
    jurisdiction_id,
    is_relevant,
  } = source;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!source_statuses || !source_statuses.length) getSourceStatuses();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (current !== null) {
      setSource(current);
    } else {
      setSource(emptySource);
    }
  }, [current, open, prefilled]);

  function handleClose(event, reason) {
    if (reason && reason == "backdropClick") return;
    setOpenSource(false);
    clearCurrentSource();
    if (clearPrefilled) clearPrefilled();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current || !current.id) {
        addSource(
          { ...source, ...(syntax_created && { syntax_created: 1 }) },
          true
        );
      } else {
        updateSource(source);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setSource(emptySource);
    clearCurrentSource();
  };

  const onChange = (e, newVal = null) => {
    console.log(newVal);
    if (e === "source_status") {
      setStatusValue(newVal.name);
      setSource({ ...source, source_status_id: newVal.id });
    } else if (e === "source_type") {
      setSource({ ...source, source_type_id: newVal.id });
    } else if (e === "languages") {
      setSource({ ...source, languages: newVal });
    } else if (e === "jurisdiction") {
      setSource({ ...source, jurisdiction_id: newVal ? newVal.id : "" });
    } else if (e === "is_relevant") {
      setSource({ ...source, is_relevant: newVal.target.checked });
    } else {
      let val = e.target.value;
      if (e.target.name === "languages") {
        val = val.map((v) => {
          if (typeof v === "string")
            return {
              id: v,
              name: allLanguages.find((r) => r.id === v).name,
            };
          else return v;
        });
      } else if (
        e.target.name === "url" &&
        val.indexOf("facebook.com") === -1
      ) {
        val = val
          .replace("http://", "")
          .replace("https://", "")
          .replace("http:", "")
          .replace("https:", "")
          .split("/")[0];
      }
      setSource({ ...source, [e.target.name]: val });
    }
  };

  return (
    <Root>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("md"))
            ? { width: "60%", height: "40%", margin: "auto" }
            : {}
        }
        open={
          (current && !(!current.id && current.source_status_id === 5)) || open
        }
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Source"
            : current && !open
            ? "Edit Source"
            : "Add Source"}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="url"
                  label="URL (without http://)"
                  value={url}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="country-select-demo"
                  style={{ width: "100%" }}
                  options={source_types}
                  disableClearable={true}
                  classes={{
                    option: classes.option,
                  }}
                  defaultValue={source_types.find(
                    (st) => st.id === source_type_id
                  )}
                  autoHighlight
                  size="small"
                  onChange={(e, newVal) => onChange("source_type", newVal)}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source Type"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="country-select-demo"
                  style={{ width: "100%" }}
                  options={source_statuses}
                  clearOnBlur={true}
                  defaultValue={source_statuses.find(
                    (ss) => ss.id === source_status_id
                  )}
                  disableClearable={true}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  size="small"
                  onChange={(e, newVal) => onChange("source_status", newVal)}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_relevant"
                      checked={is_relevant ? true : false}
                      onChange={(e) => onChange("is_relevant", e)}
                    />
                  }
                  label="Relevant Source?"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {jurisdictions && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={jurisdictions}
                    disableClearable={false}
                    clearOnBlur={true}
                    classes={{
                      option: classes.option,
                    }}
                    defaultValue={jurisdictions.find(
                      (jur) => jur.id === jurisdiction_id
                    )}
                    autoHighlight
                    size="small"
                    onChange={(e, newVal) => onChange("jurisdiction", newVal)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {true && (
                          <span>
                            <img
                              width="32px"
                              src={
                                "/img/country_flags/" +
                                option.country_code +
                                "@3x.png"
                              }
                            />
                          </span>
                        )}

                        <span
                          style={{ marginLeft: "5px", marginBottom: "4px" }}
                        >
                          {option.name}
                        </span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jurisdiction"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={8}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={allLanguages}
                    disableClearable={true}
                    classes={{
                      option: classes.option,
                    }}
                    multiple
                    size="small"
                    onChange={(e, newVal) => onChange("languages", newVal)}
                    autoHighlight
                    defaultValue={languages}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>{" "}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  loading: state.sources.loading,
  error: state.sources.error,
  current: state.sources.current,
  open: state.sources.open,
  allLanguages: state.related.all_languages,
  source_types: state.related.source_types,
  source_statuses: state.related.source_statuses,
  jurisdictions: state.related.jurisdictions,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSource: (id) => dispatch(deleteSource(id)),
  getSources: () => dispatch(getSources()),
  clearErrorSources: () => dispatch(clearErrorSources()),
  setCurrentSource: (source) => dispatch(setCurrentSource(source)),
  setOpenSource: (should) => dispatch(setOpenSource(should)),
  clearCurrentSource: () => dispatch(clearCurrentSource()),
  addSource: (source, updateUsi) => dispatch(addSource(source, updateUsi)),
  updateSource: (source) => dispatch(updateSource(source)),
  getLanguages: () => dispatch(getLanguages()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getSourceStatuses: () => dispatch(getSourceStatuses()),
  getJurisdictions: () => dispatch(getJurisdictions()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SourceForm);
